import * as React from 'react';

type Props = {
  query: string,
}

type State = {
  searchedQueries: [string],
}

class SearchQueryHistoriesFields extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    const localData = localStorage.getItem('searchedQueries');
    const oldQueries = !!localData ? JSON.parse(localData) : [];
    const queries = Array.from(new Set([props.query].concat(oldQueries).filter(Boolean))).slice(0, 5);
    localStorage.setItem('searchedQueries', JSON.stringify(queries));
    this.state = { searchedQueries: queries }
  }

  render () {
    const { searchedQueries } = this.state;

    if (searchedQueries.length == 0) return '';

    return(<>
      <div className="p-univSearch_item">
        <div className="p-univSearch_item_history">
          <div className="p-univSearch_item_top">
            <label>履歴</label>
          </div>
        </div>
        <div className="p-univSearch_item_list">
          <ul>
            {searchedQueries.map((v) => {
              return <li key={v}>
                <a href={`/searches?query=${v}`}>{v}</a>
              </li>;
            })}
          </ul>
        </div>
      </div>
    </>);
  }
};

export default SearchQueryHistoriesFields;

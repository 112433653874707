import { Sortable } from "sortablejs";

document.addEventListener("turbolinks:load", () => {
  const sortableList = document.getElementById('sortable-list');
  if(!sortableList || sortableList.length === 0) return;

  new Sortable(sortableList, {
    animation: 150,
    handle: '.ordered-handle',
    onUpdate: () => { document.getElementById('reordered-sortable-list-form').submit(); }
  });
});

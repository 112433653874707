import * as React from 'react';
import { Button, Modal } from "react-bootstrap";

type State = {
  show: boolean,
}

class TimedPopupFields extends React.Component<{}, State> {
  intervalId: any;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    this.close = this.close.bind(this);
  }
  componentDidMount() {
    this.intervalId = setInterval(()=>{
      this.setState({ show: true })
    }, 600000);
  }
  componentWillUnmount(){
    clearInterval(this.intervalId);
  }
  close() {
    this.setState({ show: false });
    this.componentWillUnmount();
  }

  render () {
    const { show } = this.state;
    return (
      <Modal show={show} onHide={this.close}>
        <Modal.Header closeButton>
          <div className='fw-bold'>ご注文をお忘れですか？</div>
        </Modal.Header>
        <Modal.Body>
          <div>
            ご注文がまだ完了しておりません。必要情報をご入力の上、ご注文確定へお進みください。
            <br />
            <br />
            ご注文に際するご不明点などがある場合、下記問い合わせフォームよりお問い合わせください。
            <br />
            <a href="https://omakase-japan.zendesk.com/hc/ja/articles/360040884332-%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%81%AF%E3%81%93%E3%81%A1%E3%82%89%E3%81%8B%E3%82%89-Contact-us-here" target="_blank">こちらから</a>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default TimedPopupFields;

import * as React from 'react';
import axios from "axios";
import { Prefectures } from "../types/Prefecture";

type Props = {
  zip: string,
  prefecture: string,
  city: string,
  address1: string,
  address2: string,
  setZip: (value: string) => void,
  setPrefecture: (value: string) => void,
  setCity: (value: string) => void,
  setAddress1: (value: string) => void,
  setAddress2: (value: string) => void,
  isZipInvalid: boolean,
  isPrefectureInvalid: boolean,
  isCityInvalid: boolean,
  isAddress1Invalid: boolean,
  isAddress2Invalid: boolean,
  prefectures: Prefectures,
  holdAddressCache: (value: string) => void,
  addressCacheType: string,
};

class AddressFields extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.fillWithZip = this.fillWithZip.bind(this)
  }

  async fillWithZip(zip) {
    const { setPrefecture, setCity, setAddress1 } = this.props
    try {
      const response = await axios.get(
        '/zipcode/search', {
          params: { zip: zip }
        }
      );
      if (response.status === 200) {
        setPrefecture(response.data.prefecture);
        setCity(response.data.city);
        setAddress1(response.data.address1);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render () {
    const {
      zip, prefecture, city, address1, address2,
      setZip, setPrefecture, setCity, setAddress1, setAddress2,
      isZipInvalid, isPrefectureInvalid, isCityInvalid,
      isAddress1Invalid, isAddress2Invalid,
      prefectures,
      holdAddressCache, addressCacheType,
    } = this.props;

    return(
      <React.Fragment>
        <div className="form-group row mb-3">
          <div className="col-12 col-md-6 pr-md-2">
            <label>郵便番号(ハイフンなし)<span className="c-form_rq">必須</span></label>
            <input type="tel"
              value={zip}
              onChange={e => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                setZip(value)
                if (value.length === 7) { this.fillWithZip(value) }
              }}
              onBlur={e => holdAddressCache(addressCacheType)}
              placeholder="1234567(ハイフンなし)"
              maxLength={7}
              className={"form-control" + (isZipInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <div className="form-group row mb-3">
          <div className="col-6 pr-0 pr-md-2">
            <label>都道府県<span className="c-form_rq">必須</span></label>
            <select
              onChange={e => {setPrefecture(e.target.value)}}
              onBlur={e => holdAddressCache(addressCacheType)}
              value={!!prefecture ? prefecture : ""}
              className={"form-select" + (isPrefectureInvalid ? ' is-invalid' : '')}
            >
              <option value="" key="">選択してください</option>
              {prefectures.map(
                pref => <option value={pref[1]} key={pref[1]}>{pref[0]}</option>
              )}
            </select>
          </div>
          <div className="col-6 pl-0 pl-md-2">
            <label>市区町村<span className="c-form_rq">必須</span></label>
            <input type="text"
              value={city}
              onChange={e => {setCity(e.target.value)}}
              onBlur={e => holdAddressCache(addressCacheType)}
              placeholder="渋谷区"
              className={"form-control" + (isCityInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <div className="form-group row mb-3">
          <div className="col-6 pr-0 pr-md-2">
            <label>住所<span className="c-form_rq">必須</span></label>
            <input type="text"
              value={address1}
              onChange={e => {setAddress1(e.target.value)}}
              onBlur={e => holdAddressCache(addressCacheType)}
              placeholder="桜ヶ丘町26-1"
              className={"form-control" + (isAddress1Invalid ? ' is-invalid' : '')}
            />
          </div>
          <div className="col-6 pr-0 pr-md-2">
            <label>建物・部屋番号</label>
            <input type="text"
              value={address2}
              onChange={e => {setAddress2(e.target.value)}}
              onBlur={e => holdAddressCache(addressCacheType)}
              placeholder="渋谷タワー1710"
              className={"form-control" + (isAddress2Invalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AddressFields;

import * as React from 'react';
import Rails from 'rails-ujs';
import { Button, Modal } from "react-bootstrap";

type ShipmentItem = {
  id: number,
  productTitle: string,
};

type Props = {
  target: 'shipment_item'|'checkout_fee'|'shipping_fee',
  defaultFeeBearer: 'user'|'store'|'omakase',
  defaultGrossAmount: number,
  refundFeePct: string,
  submitPath: string,
  cardFee: number,
  sellingFee: number,
  orderOriginalChargedAmount: number,
  orderRefundedFaceAmount: number,
  orderRefundableFaceAmount: number,
  reversibleStoreTransferAmount: number,
  memo: string
  originalSaleAmount: number,
  reversedSaleAmount: number,
  reversibleSaleAmount: number,
  refundableFaceAmount: number,
  shipmentItem?: ShipmentItem,
};

type State = {
  reason: 'duplicate'|'fraudulent'|'requested_by_customer',
  feeBearer: 'user'|'store'|'omakase',
  amount: string,
  faceAmount: string,
  memo: string,
  isOpenConfirmDialog: boolean,
};

class NewOrderRefundForm extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      reason: 'requested_by_customer',
      feeBearer: props.defaultFeeBearer,
      amount: '',
      faceAmount: props.defaultGrossAmount.toString(),
      memo: props.memo,
      isOpenConfirmDialog: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openConfirmDialog = this.openConfirmDialog.bind(this);
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
    this.form = React.createRef();
  }

  handleSubmit() {
    this.form.current.submit();
    this.closeConfirmDialog()
  }

  openConfirmDialog(e) {
    e.preventDefault();
    this.setState({ isOpenConfirmDialog: true })
  }

  closeConfirmDialog() {
    this.setState({ isOpenConfirmDialog: false })
  }

  render() {
    const {
      target,
      refundFeePct,
      submitPath,
      cardFee,
      sellingFee,
      orderOriginalChargedAmount,
      orderRefundedFaceAmount,
      orderRefundableFaceAmount,
      reversibleStoreTransferAmount,
      originalSaleAmount,
      reversedSaleAmount,
      reversibleSaleAmount,
      refundableFaceAmount,
      shipmentItem
    } = this.props;

    const {
      reason, feeBearer, faceAmount, memo, isOpenConfirmDialog
    } = this.state;

    let refundFee: number;
    let amount: number; //顧客が受け取る額

    const cardFeeReversal = Math.ceil(
      Number(faceAmount) * cardFee / originalSaleAmount
    );
    const sellingFeeReversal = Math.ceil(
      Number(faceAmount) * sellingFee / originalSaleAmount
    );
    const checkoutFeeReversal = target === 'checkout_fee' ? Number(faceAmount) : 0;

    if (feeBearer === 'user') {
      refundFee = Math.floor(
        Number(faceAmount) * Number(refundFeePct) / 100
      );
      amount = Number(faceAmount) - refundFee;
    } else
    if (feeBearer === 'store') {
      refundFee = Math.floor(
        Number(faceAmount) * Number(refundFeePct) / 100
      );
      amount = Number(faceAmount);
    } else
    if (feeBearer === 'omakase') {
      refundFee = 0;
      amount = Number(faceAmount);
    }

    const netOmakaseFeeReversal = sellingFeeReversal + cardFeeReversal + checkoutFeeReversal - refundFee;
    const deductibleAmount = amount - netOmakaseFeeReversal;

    let targetLabel;
    switch(target) {
      case 'shipment_item': targetLabel = `商品（${shipmentItem.productTitle}）`; break;
      case 'shipping_fee': targetLabel = '送料'; break;
      case 'checkout_fee': targetLabel = 'チェックアウト手数料'; break;
    }

    const omakaseJournalTables = <div className="row">
      <div className="col-md-6">
        <table className="table">
          <tbody>
            <tr>
              <td>
                売上高（販売手数料）<br />
                <small>Revenueのマイナス</small>
              </td>
              <td className="text-end">{sellingFeeReversal}</td>
            </tr>
            <tr>
              <td>
                売上高（カード決済手数料）<br />
                <small>Revenueのマイナス</small>
              </td>
              <td className="text-end">{cardFeeReversal}</td>
            </tr>
            <tr>
              <td>
                売上高（チェックアウト手数料）<br />
                <small>Revenueのマイナス</small>
              </td>
              <td className="text-end">{checkoutFeeReversal}</td>
            </tr>
            {netOmakaseFeeReversal < 0 && <tr>
              <td>
                仮受金<br />
                <small>負債のマイナス（あるいは、売掛金: 資産のプラス）</small>
              </td>
              <td className="text-end">{netOmakaseFeeReversal * -1}</td>
            </tr>}
          </tbody>
        </table>
      </div>
      <div className="col-md-6">
        <table className="table">
          <tbody>
            <tr>
              <td>
                売上高（返金手数料）<br />
                <small>Revenueのプラス</small>
              </td>
              <td className="text-end">{refundFee}</td>
            </tr>
            {netOmakaseFeeReversal >= 0 && <tr>
              <td>
                仮受金<br />
                <small>負債のプラス（あるいは、売掛金: 資産のマイナス）</small>
              </td>
              <td className="text-end">{netOmakaseFeeReversal}</td>
            </tr>}
          </tbody>
        </table>
      </div>
    </div>;

    const hiddenFields = <>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      <input type="hidden" name="order_refund[shipment_item_id]" value={shipmentItem?.id} />
      <input type="hidden" name="target" value={target} />
      <input type="hidden" name="order_refund[reason]" value={reason} />
      <input type="hidden" name="order_refund[fee_bearer]" value={feeBearer} />
      <input type="hidden" name="order_refund[amount]" value={amount} />
      <input type="hidden" name="order_refund[face_amount]" value={faceAmount} />
      <input type="hidden" name="order_refund[deductible_amount]" value={deductibleAmount} />
      <input type="hidden" name="order_refund[card_fee_reversal]" value={cardFeeReversal} />
      <input type="hidden" name="order_refund[selling_fee_reversal]" value={sellingFeeReversal} />
      <input type="hidden" name="order_refund[checkout_fee_reversal]" value={checkoutFeeReversal} />
      <input type="hidden" name="order_refund[refund_fee]" value={refundFee} />
      <input type="hidden" name="order_refund[memo]" value={memo} />
    </>;

    return (
      <form ref={this.form} action={submitPath} method="post" id="refund-form" onSubmit={this.openConfirmDialog}>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">売上状態<br />（{`${targetLabel}分`}）</label>
          <div className="col-sm-9">
            <div className="row">
              <div className="col-4">
                <div className="input-group">
                  <label className="input-group-text">発生</label>
                  <input className="form-control" required type="text"
                    value={originalSaleAmount}
                    readOnly
                  />
                  <label className="input-group-text">JPY</label>
                </div>
              </div>

              <div className="col-4">
                <div className="input-group">
                  <label className="input-group-text">返金済み</label>
                  <input className="form-control" required type="text"
                    value={reversedSaleAmount}
                    readOnly
                  />
                  <label className="input-group-text">JPY</label>
                </div>
              </div>

              <div className="col-4">
                <div className="input-group">
                  <label className="input-group-text">残金 (A)</label>
                  <input className="form-control" required type="text"
                    value={reversibleSaleAmount}
                    readOnly
                  />
                  <label className="input-group-text">JPY</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">課金状態（Order全体）</label>
          <div className="col-sm-9">
            <div className="row">
              <div className="col-4">
                <div className="input-group">
                  <label className="input-group-text">課金</label>
                  <input className="form-control" required type="text"
                    value={orderOriginalChargedAmount}
                    readOnly
                  />
                  <label className="input-group-text">JPY</label>
                </div>
              </div>

              <div className="col-4">
                <div className="input-group">
                  <label className="input-group-text">返金済み</label>
                  <input className="form-control" required type="text"
                    value={orderRefundedFaceAmount}
                    readOnly
                  />
                  <label className="input-group-text">JPY</label>
                </div>
              </div>

              <div className="col-4">
                <div className="input-group">
                  <label className="input-group-text">残金 (B)</label>
                  <input className="form-control" required type="text"
                    value={orderRefundableFaceAmount}
                    readOnly
                  />
                  <label className="input-group-text">JPY</label>
                </div>
              </div>
            </div>
            <p className="mb-0">クーポン等が適用された場合、課金金額は発生した売上よりも小さな値になります。</p>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">返金根拠額
            <span className="c-form_rq">必須</span>
          </label>
          <div className="col-sm-9">
            <input className="form-control" required type="text"
              value={faceAmount}
              placeholder="手数料等を含めた金額を入れてください"
              onChange={e => this.setState({ faceAmount: e.target.value.replace(/[^0-9\+]/g, '') })}
              max={refundableFaceAmount}
              min={1}
            />
            <p className="mb-0">
              売上残金 (A) {reversibleSaleAmount}円と、Order課金残金 (B) {orderRefundableFaceAmount}円のうち、
              小さい方の<strong>{refundableFaceAmount}円</strong>が最大値です。
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">返金手数料</label>
          <div className="col-sm-9">
            <div className="input-group">
              <input className="form-control" required type="text"
                value={refundFeePct}
                readOnly
              />
              <label className="input-group-text">%</label>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">手数料負担者
            <span className="c-form_rq">必須</span>
          </label>
          <div className="col-sm-9">
            <select value={feeBearer} className="form-select"
              onChange={ e => this.setState({ feeBearer: e.target.value as typeof feeBearer }) }
            >
              {target !== 'checkout_fee' && <option value='user'>ユーザが負担</option>}
              {target !== 'checkout_fee' && <option value='store'>店舗が負担</option>}
              <option value='omakase'>Omakaseが負担</option>
            </select>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">返金理由
            <span className="c-form_rq">必須</span>
          </label>
          <div className="col-sm-9">
            <select value={reason} className="form-select"
              onChange={ e => this.setState({ reason: e.target.value as typeof reason }) }
            >
              <option value='duplicate'>重複課金（duplicate）</option>
              <option value='fraudulent'>詐欺（fraudulent）</option>
              <option value='requested_by_customer'>顧客による要求（requested_by_customer）</option>
            </select>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">ユーザが受け取る額</label>
          <div className="col-sm-9">
            <div className="input-group">
              <input className="form-control" required type="text"
                value={amount}
                readOnly
              />
              <label className="input-group-text">JPY</label>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Omakaseが支払う額</label>
          <div className="col-sm-9">
            <div className="input-group">
              <input className="form-control" required type="text"
                value={netOmakaseFeeReversal}
                readOnly
              />
              <label className="input-group-text">JPY</label>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">店舗が支払う額</label>
          <div className="col-sm-9">
            <div className="input-group">
              <input className="form-control" required type="text"
                value={deductibleAmount}
                readOnly
              />
              <label className="input-group-text">JPY</label>
            </div>
            <p>店舗が本取引に対して払える最大金額は<strong>{reversibleStoreTransferAmount}</strong>円です。</p>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">メモ</label>
          <div className="col-sm-9">
            <div className="input-group">
              <textarea rows={5} className="form-control" onKeyUp={e => this.setState({ memo: e.currentTarget.value })}>{memo}</textarea>
            </div>
          </div>
        </div>

        {hiddenFields}

        <div className="text-center">
          <input type="submit" name="commit" value="返金を実行" className="btn btn-wide btn-primary" />
        </div>

        <h3>Omakase側Journal entries</h3>
        {omakaseJournalTables}

        {<Modal
          show={isOpenConfirmDialog}
          onHide={this.closeConfirmDialog}
         >
          <Modal.Header closeButton>
            返金処理
          </Modal.Header>
          <Modal.Body>
            返金します。よろしいですか？
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeConfirmDialog}>返金しない</Button>
            <Button variant="primary" onClick={this.handleSubmit}>返金する</Button>
          </Modal.Footer>
        </Modal>}
      </form>
    );
  }
};

export default NewOrderRefundForm;

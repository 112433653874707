import * as React from "react";
import Rails from 'rails-ujs';
import { Button, Modal } from "react-bootstrap";
import axios from 'axios';

interface Props {
  ecCommissionTerms: string
}

const ShowEcCommissionTermsModal: React.VFC<Props> = ({ ecCommissionTerms }: Props) => {
  const [show, setShow] = React.useState<boolean>(true);

  const handleClose = React.useCallback(() => {
    setShow(false);
  }, [])

  const handleClickAgreement = React.useCallback(() => {
    axios
      .patch('/owner/stores/update_ec_commission_terms_agreed_at', {}, {
        headers: { 'X-CSRF-Token': Rails.csrfToken() },
      })
      .then(response => {
        location.reload()
      })
      .then(error => {
        console.error(error)
        setShow(false)
      })
  }, [])

  return (
    <Modal show={show} onHide={handleClose} scrollable={true} >
      <Modal.Header closeButton>
        <div className='modal-title'>OMAKASE利用規約の追加規約</div>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: ecCommissionTerms }} />
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>同意しない</Button> */}
        <Button variant="primary" onClick={handleClickAgreement}>同意する</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ShowEcCommissionTermsModal

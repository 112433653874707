import * as React from 'react';
import {
  ProductPreference,
  ProductPreferenceGroup,
  ProductPreferenceQuestion,
  ProductPreferenceAnswer,
  isRequiredProductPreferenceAnswerEmpty,
  buildProductPreferenceAnswer,
} from '../types/ProductPreference';
import classNames from 'classnames';

type Props = {
  productPreferenceGroup: ProductPreferenceGroup,
  productPreferenceQuestions: ProductPreferenceQuestion[],
  setProductPreference: (
    cartItemUid: string, productPreferenceIndex: number, newProductPreference: ProductPreference,
    afterSetStateCallback?: () => void,
  ) => void,
  isDevelopment: boolean,
}

type State = {
  showModal: boolean
}

class ProductPreferencesFields extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      showModal: false
    };
    this.buildQuestionField = this.buildQuestionField.bind(this);
  }

  buildQuestionField(
    ppQuestion: ProductPreferenceQuestion, preferenceIndex: number
  ): JSX.Element {
    const { productPreferenceGroup, productPreferenceQuestions, setProductPreference, isDevelopment } = this.props;
    const productPreference = productPreferenceGroup.preferences[preferenceIndex];
    const ppAnswer = productPreference.find(ppa => ppa.productPreferenceQuestionId === ppQuestion.id);
    const hasParentOption = !!ppQuestion.parentOptionId;

    const setProductPreferenceAnswer = (
      ppaAttributes: Partial<ProductPreferenceAnswer>, afterSetStateCallback?: () => void,
    ): void => {
      const newPreference = productPreference.map(ppa => {
        if (ppa.productPreferenceQuestionId === ppQuestion.id) {
          return { ...ppa, ...ppaAttributes };
        } else {
          return ppa;
        }
      });
      setProductPreference(
        productPreferenceGroup.cartItemUid, preferenceIndex, newPreference, afterSetStateCallback
      );
    }

    const tryAddProductPrefenceAnswer = (newChildPpq: ProductPreferenceQuestion) => {
      const productPreference = this.props.productPreferenceGroup.preferences[preferenceIndex];

      const isPpqIdPresent = productPreference.some(
        ppa => ppa.productPreferenceQuestionId === newChildPpq.id
      );
      if (isPpqIdPresent) { return; }

      // 一つの質問の中で値が変更されたら他の子質問は非表示にする
      const removedChildPP = productPreference.filter(
        pp => !pp.isChild || pp.parentQuestionId !== newChildPpq.parentQuestionId
      )
      const newPreference = [...removedChildPP, buildProductPreferenceAnswer(newChildPpq)];
      if (isDevelopment) {
        console.log('Appending ProductPrefenceAnswer', newPreference);
      }
      setProductPreference(productPreferenceGroup.cartItemUid, preferenceIndex, newPreference);
    }

    const tryRemoveProductPrefenceAnswer = (productPreferenceQuestionId: number) => {
      const productPreference = this.props.productPreferenceGroup.preferences[preferenceIndex];

      const newPreference = productPreference.filter(
        ppa => ppa.productPreferenceQuestionId !== productPreferenceQuestionId
      );
      setProductPreference(productPreferenceGroup.cartItemUid, preferenceIndex, newPreference);
    }

    const wrapperClassNames = classNames('mb-3', {
      "p-child_preference_question": hasParentOption
    });

    switch(ppQuestion.questionType) {
    case 'singleline':
      return(
        <div key={ppAnswer.productPreferenceQuestionId} className={wrapperClassNames}>
          <label className='mb-1'>
            {ppQuestion.question}
            {ppQuestion.isRequired && <span className="c-form_rq">必須</span>}
          </label>
          <input type="text" className={`form-control ${ppQuestion.isRequired && !ppAnswer.text ? 'is-invalid' : 'is-valid'}`}
            value={ppAnswer.text}
            onChange={e => setProductPreferenceAnswer({ text: e.target.value })}
            placeholder='入力してください'
          />
        </div>
      );
    case 'select':
      const allChildQuestions = productPreferenceQuestions.filter(
        ppq => ppQuestion.options.some(option => option.id === ppq.parentOptionId)
      );
      const activeChildQuestions = allChildQuestions.filter(childQuestion => {
        return productPreference.some(
          ppa => ppa.productPreferenceQuestionId === childQuestion.id
        )
      });
      const inactiveChildQuestions = allChildQuestions.filter(
        childQuestion => !activeChildQuestions.some(acq => acq.id === childQuestion.id)
      );

      return(
        <div key={ppAnswer.productPreferenceQuestionId} className={wrapperClassNames}>
          <label className='mb-1'>
            {ppQuestion.question}
            {ppQuestion.isRequired && <span className="c-form_rq">必須</span>}
          </label>
          <select className={`form-select ${ppQuestion.isRequired && ppAnswer.productPreferenceOptionIds.length === 0 ? 'is-invalid' : 'is-valid'}`}
            value={ppAnswer.productPreferenceOptionIds.length > 0 ? ppAnswer.productPreferenceOptionIds[0] : ''}
            onChange={e => {
              // NOTE 複数のnewProductPreferenceOptionIdsが、そのうち来ることを想定
              const newProductPreferenceOptionIds = e.target.value === '' ? [] : [Number(e.target.value)];

              setProductPreferenceAnswer({ productPreferenceOptionIds: newProductPreferenceOptionIds }, () => {
                const newSelectedOptions = ppQuestion.options.filter(
                  option => newProductPreferenceOptionIds.includes(option.id)
                );

                // OptionにChildQuestionがあれば、それをProductPreferenceに増やす
                newSelectedOptions.forEach(nso => {
                  const childQuestion = inactiveChildQuestions.find(
                    ppq => ppq.parentOptionId === nso.id
                  );
                  if (!!childQuestion) { tryAddProductPrefenceAnswer(childQuestion); }
                });

                // OptionにChildQuestionがなければ、それをProductPreferenceから減らす
                activeChildQuestions.forEach(ppq => {
                  if (!newSelectedOptions.length || newSelectedOptions.some(nso => !nso.isParent)) {
                    if (!newSelectedOptions.some(nso => nso.id === ppq.parentOptionId)) {
                      tryRemoveProductPrefenceAnswer(ppq.id);
                    }
                  }
                });
              });
            }}
          >
            <option value="">選択してください</option>
            {ppQuestion.options.map(option => <option key={option.id} value={option.id}>
              {option.optionText}
            </option>)}
          </select>
          <div className="p-child_preference_question_list">
            {activeChildQuestions.map(childQuestion => {
              return this.buildQuestionField(childQuestion, preferenceIndex);
            })}
          </div>
        </div>
      );
    }
  }

  render() {
    const { productPreferenceGroup, productPreferenceQuestions } = this.props;
    const { showModal } = this.state;

    const hasRequiredAndEmptyAnswer = productPreferenceGroup.preferences.some(preference => {
      return preference.flat().some(ppa => {
        const ppq = productPreferenceQuestions.find(ppq => ppq.id === ppa.productPreferenceQuestionId);
        return isRequiredProductPreferenceAnswerEmpty(ppa, ppq);
      });
    });

    const questionLists = productPreferenceGroup.preferences.map((preference, preferenceIndex) => {
      const questionListContent = preference.map(ppa => {
        const ppq = productPreferenceQuestions.find(
          ppq => ppq.id === ppa.productPreferenceQuestionId
        );

        if (!ppq.parentOptionId) {
          return this.buildQuestionField(ppq, preferenceIndex);
        }
      });

      return(
        <div key={preferenceIndex}>
          {productPreferenceGroup.preferences.length > 1 && <div className='fw-bold mb-2'>
            <i className="fas fa-box-open"></i>
            {preferenceIndex + 1}個目の商品
          </div>}
          {questionListContent}
        </div>
      );
    });

    return(
      <>
        {questionLists}
        {hasRequiredAndEmptyAnswer && (
          <div className='u-tx-alert mt-2'>
            <i className="fas fa-exclamation-triangle"></i>
            必須回答項目が残っています
          </div>
        )}
      </>
    );
  }
}

export default ProductPreferencesFields;

import * as React from 'react';
import axios from 'axios';
import Rails from 'rails-ujs';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';

import { Prefectures, PrefectureValue } from "./types/Prefecture";
import { UserAddress, CachedUserAddress } from "./types/UserAddress";
import NewOrderAddressFields from './checkout_fields/NewOrderAddressFields';
import TimedPopupFields from './checkout_fields/TimedPopupFields';
import CouponFields from './checkout_fields/CouponFields';
import classNames from 'classnames';
import { CartItem } from "./types/CartItem";
import { Coupon } from './types/Coupon';
import {
  ProductPreferenceGroup,
  ProductPreference,
  isRequiredProductPreferenceAnswerEmpty,
  buildProductPreferenceAnswer,
  filterChildQuestions
} from "./types/ProductPreference";
import ProductPreferencesFields from './checkout_fields/ProductPreferencesFields';
import StripeCardForm from './StripeCardForm';
import FlashMessage from './FlashMessage';

type StoreLegalNote = {
  returnPolicyJa: string,
  deliveryPolicyJa: string,
}

type Store = {
  slug: string,
  checkoutFeePerStock: number,
  omakaseRestaurantGroupId: number,
  legalNote: StoreLegalNote,
}

export type StripeSourceCard = {
  id: string,
  last4: string,
  brand: string,
  brandImagePath: string,
};

type ShippingMethod = {
  uid: string,
  isAsapOnly: boolean,
}

type Props = {
  productType: 'fresh'|'grocery'|'freshish',
  submitPath: string,
  store: Store,
  defaultShippingAddressUid: string|null,
  stripePublicKey: string,
  stripeSourceCard: StripeSourceCard|null,
  earliestShippableDate?: string, //ex. "12月16日(木)" - Only Grocery
  shippingDate?: string, //ex. "YYYY-MM-DD" - Only Fresh
  shippingMethod: ShippingMethod,
  deliveryNote: string,
  defaultDeliveryDateOptions: [string, string][],
  defaultDeliverySlotOptions: [string, string][],
  checkoutFee: number,
  userAddresses: UserAddress[],
  prefectures: Prefectures,
  shippablePrefectures: Prefectures,
  cartItems: CartItem[],
  totalCartItemStocks: number,
  isDevelopment: boolean,
  userProfileCreditCardEditUrl: string,
  addressCaches: {
    shipping_address: CachedUserAddress, billing_address: CachedUserAddress
  },
};

const defaultCoupon: Coupon = {
  code: '',
  discountAmount: 0,
  isApplicable: false,
};

type State = {
  shippingAddressUid: string,
  copyShippingAddressToBillingAddress: boolean,
  billingAddressUid: string,
  newShippingAddressRecipient: string,
  newShippingAddressZip: string,
  newShippingAddressPrefecture: PrefectureValue,
  newShippingAddressCity: string,
  newShippingAddressAddress1: string,
  newShippingAddressAddress2: string,
  newShippingAddressTel: string,
  newBillingAddressRecipient: string,
  newBillingAddressZip: string,
  newBillingAddressPrefecture: PrefectureValue,
  newBillingAddressCity: string,
  newBillingAddressAddress1: string,
  newBillingAddressAddress2: string,
  newBillingAddressTel: string,
  newStripeSourceCard: StripeSourceCard | null,
  deliveryDate: string,
  deliverySlot: string,
  deliveryDateOptions: [string, string][],
  deliverySlotOptions: [string, string][],
  shippingFee: number|null,
  totalChargeableAmount: number|null,
  possibleFeeBearer: "sender"|"recipient"|"sender_or_recipient"|null,
  shippingFeeBearer: "sender"|"recipient"|"",
  isNewShippingAddressRecipientInvalid: boolean,
  isNewShippingAddressZipInvalid: boolean,
  isNewShippingAddressPrefectureInvalid: boolean,
  isNewShippingAddressCityInvalid: boolean,
  isNewShippingAddressAddress1Invalid: boolean,
  isNewShippingAddressTelInvalid: boolean,
  isNewBillingAddressRecipientInvalid: boolean,
  isNewBillingAddressZipInvalid: boolean,
  isNewBillingAddressPrefectureInvalid: boolean,
  isNewBillingAddressCityInvalid: boolean,
  isNewBillingAddressAddress1Invalid: boolean,
  isNewBillingAddressTelInvalid: boolean,
  isDeliveryDateInvalid: boolean,
  isDeliverySlotInvalid: boolean,
  isShippingFeeBearerInvalid: boolean,
  isOpenUpdateStripe: boolean,
  isValidating: boolean,
  isLoading: boolean,
  cardUpdateSuccessMessage: string,
  specialRequest: string,
  productPreferenceGroups: ProductPreferenceGroup[],
  willSubscribeToRestaurantGroup: boolean,
  coupon: Coupon,
  couponUserMessage: string,
};

type QuoteResponse = {
  deliveryDateOptions: [string, string][],
  deliverySlotOptions: [string, string][],
  possibleFeeBearer: "sender"|"recipient"|"sender_or_recipient",
  shippingFee: number,
  coupon?: Coupon,
  couponUserMessage: string,
};

class NewOrderForm extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;
  private stripePromise: Promise<Stripe>;

  constructor (props: Props) {
    super(props);

    const shippingAddressUid = (
      props.defaultShippingAddressUid ? props.defaultShippingAddressUid : ''
    );

    this.stripePromise = loadStripe(props.stripePublicKey);

    // デフォルトで何も値が入ってない、スケルトンのproductPreferenceGroupsを作る
    const productPreferenceGroups = props.cartItems.filter(
      ci => ci.productPreferenceQuestions.length > 0
    ).map(ci => {
      const answerSet = ci.productPreferenceQuestions.filter(
        // 選択肢がひとつも設定されてない質問は含めない
        q => q.options.length).filter(q => !q.parentOptionId).map(
        q => buildProductPreferenceAnswer(q)
      )
      const answerSetSiblings = answerSet.map(
        ppa => filterChildQuestions(ppa, ci.productPreferenceQuestions)
      ).flat().map(buildProductPreferenceAnswer);

      const preferences = Array(ci.stocks).fill([...answerSet, ...answerSetSiblings]);
      return {
        cartItemUid: ci.uid, preferences: preferences
      }
    });

    const shippingAddressCache = props.addressCaches.shipping_address;
    const billingAddressCache = props.addressCaches.billing_address;

    this.state = {
      shippingAddressUid: shippingAddressUid,
      copyShippingAddressToBillingAddress: props.userAddresses.length === 0,
      billingAddressUid: shippingAddressUid,
      newShippingAddressRecipient: shippingAddressCache ? shippingAddressCache.recipient : '',
      newShippingAddressZip: shippingAddressCache ? shippingAddressCache.zip : '',
      newShippingAddressPrefecture: shippingAddressCache ? shippingAddressCache.prefecture : '',
      newShippingAddressCity: shippingAddressCache ? shippingAddressCache.city : '',
      newShippingAddressAddress1: shippingAddressCache ? shippingAddressCache.address1 : '',
      newShippingAddressAddress2: shippingAddressCache ? shippingAddressCache.address2 : '',
      newShippingAddressTel: shippingAddressCache ? shippingAddressCache.tel : '',
      newBillingAddressRecipient: billingAddressCache ? billingAddressCache.recipient : '',
      newBillingAddressZip: billingAddressCache ? billingAddressCache.zip : '',
      newBillingAddressPrefecture: billingAddressCache ? billingAddressCache.prefecture : '',
      newBillingAddressCity: billingAddressCache ? billingAddressCache.city : '',
      newBillingAddressAddress1: billingAddressCache ? billingAddressCache.address1 : '',
      newBillingAddressAddress2: billingAddressCache ? billingAddressCache.address2 : '',
      newBillingAddressTel: billingAddressCache ? billingAddressCache.tel : '',
      newStripeSourceCard: null,
      deliveryDate: props.shippingMethod.isAsapOnly ? 'asap' : '',
      deliverySlot: '',
      deliveryDateOptions: props.defaultDeliveryDateOptions,
      deliverySlotOptions: props.defaultDeliverySlotOptions,
      shippingFee: null,
      totalChargeableAmount: null,
      possibleFeeBearer: null,
      shippingFeeBearer: '',
      isNewShippingAddressRecipientInvalid: false,
      isNewShippingAddressZipInvalid: false,
      isNewShippingAddressPrefectureInvalid: false,
      isNewShippingAddressCityInvalid: false,
      isNewShippingAddressAddress1Invalid: false,
      isNewShippingAddressTelInvalid: false,
      isNewBillingAddressRecipientInvalid: false,
      isNewBillingAddressZipInvalid: false,
      isNewBillingAddressPrefectureInvalid: false,
      isNewBillingAddressCityInvalid: false,
      isNewBillingAddressAddress1Invalid: false,
      isNewBillingAddressTelInvalid: false,
      isDeliveryDateInvalid: false,
      isDeliverySlotInvalid: false,
      isShippingFeeBearerInvalid: false,
      isOpenUpdateStripe: false,
      isValidating: false,
      isLoading: false,
      cardUpdateSuccessMessage: '',
      specialRequest: '',
      productPreferenceGroups: productPreferenceGroups,
      willSubscribeToRestaurantGroup: !!props.store.omakaseRestaurantGroupId,
      coupon: defaultCoupon,
      couponUserMessage: '',
    };

    this.validate = this.validate.bind(this);
    this.toggleCopyShippingAddressToBillingAddress =
      this.toggleCopyShippingAddressToBillingAddress.bind(this);
    this.quote = this.quote.bind(this);
    this.setShippingMethodDestination = this.setShippingMethodDestination.bind(this);
    this.setDeliveryDate = this.setDeliveryDate.bind(this);
    this.setCouponCode = this.setCouponCode.bind(this);
    this.findShippingPrefectureValue = this.findShippingPrefectureValue.bind(this);
    this.setProductPreference = this.setProductPreference.bind(this);
    this.holdAddressCache = this.holdAddressCache.bind(this);
  }

  toggleCopyShippingAddressToBillingAddress() {
    const { copyShippingAddressToBillingAddress } = this.state;
    const newValue = !copyShippingAddressToBillingAddress;
    if (newValue) {
      this.setState({
        copyShippingAddressToBillingAddress: true,
        billingAddressUid: '',
        newBillingAddressRecipient: '',
        newBillingAddressZip: '',
        newBillingAddressPrefecture: '',
        newBillingAddressCity: '',
        newBillingAddressAddress1: '',
        newBillingAddressAddress2: '',
        newBillingAddressTel: '',
      });
    } else {
      this.setState({
        copyShippingAddressToBillingAddress: false,
      });
    }
  }

  async quote (
    prefectureValue: PrefectureValue, deliveryDateValue: string, couponCode: string
  ): Promise<QuoteResponse> {
    const { store, shippingMethod, productType, shippingDate, isDevelopment } = this.props;
    const url = `/stores/${store.slug}/new_${productType}_orders/quote.json`;

    try {
      const response = await axios.get(
        url, {
          params: {
            shipping_method_uid: shippingMethod.uid,
            prefecture: prefectureValue,
            shipping_date: shippingDate, //fresh only
            delivery_date: deliveryDateValue,
            coupon_code: couponCode,
          }
        }
      );

      if(isDevelopment) {
        console.log(response.data);
      }

      if (response.status === 200) {
        return(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async setShippingMethodDestination (prefectureValue: PrefectureValue) {
    const { shippingMethod } = this.props;
    const { coupon } = this.state;
    const quoteResponse: QuoteResponse = await this.quote(prefectureValue, '', coupon.code);

    // 到着希望日が１つしかない場合は選択済みにする
    let defaultDeliveryDate = '';
    if (shippingMethod.isAsapOnly) {
      defaultDeliveryDate = 'asap';
    } else if (quoteResponse.deliveryDateOptions.length === 1) {
      defaultDeliveryDate = quoteResponse.deliveryDateOptions[0][1];
    }

    this.setState({
      deliveryDateOptions: quoteResponse.deliveryDateOptions,
      deliverySlotOptions: quoteResponse.deliverySlotOptions,
      possibleFeeBearer: quoteResponse.possibleFeeBearer,
      shippingFee: quoteResponse.shippingFee,
      deliveryDate: defaultDeliveryDate,
      deliverySlot: '',
      shippingFeeBearer: quoteResponse.possibleFeeBearer === 'sender_or_recipient' ? '' : quoteResponse.possibleFeeBearer,
      coupon: !!quoteResponse.coupon ? quoteResponse.coupon : coupon,
    }, () => {
      if (!!defaultDeliveryDate && defaultDeliveryDate !== 'asap') {
        this.setDeliveryDate(defaultDeliveryDate);
      }
    });
  }

  async setDeliveryDate (deliveryDateValue: string) {
    const { coupon } = this.state;
    const quoteResponse: QuoteResponse = await this.quote(
      this.findShippingPrefectureValue(), deliveryDateValue, coupon.code
    );

    this.setState({
      deliverySlotOptions: quoteResponse.deliverySlotOptions,
      deliverySlot: '',
      deliveryDate: deliveryDateValue,
    });
  }

  async setCouponCode(couponCode: string) {
    const { deliveryDate, coupon } = this.state;
    const quoteResponse: QuoteResponse = await this.quote(
      this.findShippingPrefectureValue(), deliveryDate, couponCode
    );

    this.setState({
      coupon: !!quoteResponse.coupon ? quoteResponse.coupon : { ...coupon, isApplicable: false },
      couponUserMessage: quoteResponse.couponUserMessage,
    });
  }

  findShippingPrefectureValue(): PrefectureValue|'' {
    const { userAddresses } = this.props;
    const { shippingAddressUid, newShippingAddressPrefecture } = this.state;

    const shippingAddress = userAddresses.find(ua => ua.uid === shippingAddressUid);
    return(!!shippingAddress ? shippingAddress.prefecture : newShippingAddressPrefecture);
  }

  handleBeforeUnload(e: BeforeUnloadEvent) {
    e.preventDefault()
    e.returnValue = '一度入力情報がなくなる可能性があります。本当にページを離れますか？'
  }

  componentDidMount() {
    const { userAddresses, addressCaches } = this.props;
    const { shippingAddressUid, coupon } = this.state;

    const shippingAddress = userAddresses.find(ua => ua.uid === shippingAddressUid);
    if(shippingAddress) {
      this.setShippingMethodDestination(shippingAddress.prefecture);
    } else {
      const shippingAddressCache = addressCaches.shipping_address;
      if (!!shippingAddressCache && !!shippingAddressCache.prefecture) {
        this.setShippingMethodDestination(shippingAddressCache.prefecture);
      }
    }

    // TODO: 確定ボタンクリック時にも出てしまうため一旦コメント
    // window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  componentWillUnmount() {
    // TODO: 確定ボタンクリック時にも出てしまうため一旦コメント
    // window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  validate (renderError: boolean) {
    let isValid = true;

    const { productType, cartItems } = this.props;

    const {
      shippingAddressUid,
      billingAddressUid,
      newShippingAddressRecipient,
      newShippingAddressZip,
      newShippingAddressPrefecture,
      newShippingAddressCity,
      newShippingAddressAddress1,
      newShippingAddressTel,
      copyShippingAddressToBillingAddress,
      newBillingAddressRecipient,
      newBillingAddressZip,
      newBillingAddressPrefecture,
      newBillingAddressCity,
      newBillingAddressAddress1,
      newBillingAddressTel,
      deliveryDateOptions,
      deliverySlotOptions,
      deliveryDate,
      deliverySlot,
      shippingFeeBearer,
      productPreferenceGroups,
    } = this.state;

    if(shippingAddressUid === '') {
      if(newShippingAddressRecipient.length === 0) {
        if(renderError) this.setState({ isNewShippingAddressRecipientInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewShippingAddressRecipientInvalid: false });
      }
      if(newShippingAddressTel.length < 8 || newShippingAddressTel.length > 12) {
        if(renderError) this.setState({ isNewShippingAddressTelInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewShippingAddressTelInvalid: false });
      }
      if(newShippingAddressZip.length != 7) {
        if(renderError) this.setState({ isNewShippingAddressZipInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewShippingAddressZipInvalid: false });
      }
      if(newShippingAddressPrefecture.length === 0) {
        if(renderError) this.setState({ isNewShippingAddressPrefectureInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewShippingAddressPrefectureInvalid: false });
      }
      if(newShippingAddressCity.length === 0) {
        if(renderError) this.setState({ isNewShippingAddressCityInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewShippingAddressCityInvalid: false });
      }
      if(newShippingAddressAddress1.length === 0) {
        if(renderError) this.setState({ isNewShippingAddressAddress1Invalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewShippingAddressAddress1Invalid: false });
      }
    }

    if(billingAddressUid === '' && !copyShippingAddressToBillingAddress) {
      if(newBillingAddressRecipient.length === 0) {
        if(renderError) this.setState({ isNewBillingAddressRecipientInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewBillingAddressRecipientInvalid: false });
      }
      if(newBillingAddressTel.length < 8 || newBillingAddressTel.length > 12) {
        if(renderError) this.setState({ isNewBillingAddressTelInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewBillingAddressTelInvalid: false });
      }
      if(newBillingAddressZip.length != 7) {
        if(renderError) this.setState({ isNewBillingAddressZipInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewBillingAddressZipInvalid: false });
      }
      if(newBillingAddressPrefecture.length === 0) {
        if(renderError) this.setState({ isNewBillingAddressPrefectureInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewBillingAddressPrefectureInvalid: false });
      }
      if(newBillingAddressCity.length === 0) {
        if(renderError) this.setState({ isNewBillingAddressCityInvalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewBillingAddressCityInvalid: false });
      }
      if(newBillingAddressAddress1.length === 0) {
        if(renderError) this.setState({ isNewBillingAddressAddress1Invalid: true });
        isValid = false;
      } else {
        if(renderError) this.setState({ isNewBillingAddressAddress1Invalid: false });
      }
    }

    const deliveryDateOptionValues = deliveryDateOptions.map(ddo => ddo[1]);
    const deliverySlotOptionValues = deliverySlotOptions.map(dso => dso[1]);

    if(!deliveryDateOptionValues.includes(deliveryDate)) {
      if(renderError) this.setState({ isDeliveryDateInvalid: true });
      isValid = false;
    } else {
      if(renderError) this.setState({ isDeliveryDateInvalid: false });
    }

    if(productType !== 'freshish' && deliveryDate !== 'asap' && !deliverySlotOptionValues.includes(deliverySlot)) {
      if(renderError) this.setState({ isDeliverySlotInvalid: true });
      isValid = false;
    } else {
      if(renderError) this.setState({ isDeliverySlotInvalid: false });
    }

    if(shippingFeeBearer.length === 0) {
      if(renderError) this.setState({ isShippingFeeBearerInvalid: true });
      isValid = false;
    } else {
      if(renderError) this.setState({ isShippingFeeBearerInvalid: false });
    }

    productPreferenceGroups.forEach(ppg => {
      const cartItem = cartItems.find(ci => ci.uid === ppg.cartItemUid);
      ppg.preferences.flat().forEach((ppa) => {
        const ppq = cartItem.productPreferenceQuestions.find(
          ppq => ppq.id === ppa.productPreferenceQuestionId
        );

        if(isRequiredProductPreferenceAnswerEmpty(ppa, ppq)) {
          isValid = false;
        }
      });
    });

    return isValid;
  }

  setProductPreference(
    cartItemUid: string,
    productPreferenceIndex: number,
    newProductPreference: ProductPreference,
    afterSetStateCallback?: () => void,
  ): void {
    const { isDevelopment } = this.props;
    const { productPreferenceGroups } = this.state;

    if (isDevelopment) {
      console.log(
        `Updating ProductPreference cartItemUid ${cartItemUid} ppIndex ${productPreferenceIndex}`,
        newProductPreference
      );
    }

    const newProductPreferenceGroups = productPreferenceGroups.map(ppg => {
      if (ppg.cartItemUid === cartItemUid) {
        const newPreferences = ppg.preferences.map((pp, index) => {
          if (index === productPreferenceIndex) {
            return newProductPreference;
          } else {
            return pp;
          }
        })
        return { ...ppg, preferences: newPreferences };
      } else {
        return ppg;
      }
    });

    this.setState({ productPreferenceGroups: newProductPreferenceGroups }, afterSetStateCallback);
  }

  holdAddressCache(addressType) {
    const url = '/my/addresses/create_address_cache'
    let postParams: CachedUserAddress|null = null;
    if (addressType == 'shipping_address') {
      const {
        newShippingAddressRecipient, newShippingAddressZip, newShippingAddressPrefecture, newShippingAddressCity,
        newShippingAddressAddress1, newShippingAddressAddress2, newShippingAddressTel,
      } = this.state;
      postParams = {
        recipient: newShippingAddressRecipient,
        zip: newShippingAddressZip,
        prefecture: newShippingAddressPrefecture,
        city: newShippingAddressCity,
        address1: newShippingAddressAddress1,
        address2: newShippingAddressAddress2,
        tel: newShippingAddressTel,
      }
    } else {
      const {
        newBillingAddressRecipient, newBillingAddressZip, newBillingAddressPrefecture, newBillingAddressCity,
        newBillingAddressAddress1, newBillingAddressAddress2, newBillingAddressTel,
      } = this.state;
      postParams = {
        recipient: newBillingAddressRecipient,
        zip: newBillingAddressZip,
        prefecture: newBillingAddressPrefecture,
        city: newBillingAddressCity,
        address1: newBillingAddressAddress1,
        address2: newBillingAddressAddress2,
        tel: newBillingAddressTel,
      }
    };

    axios.post(
      url, { ...postParams, address_type: addressType },
      {
        headers: { 'X-CSRF-Token': Rails.csrfToken() },
      }
    ).catch((error) => {
      console.error(error);
    });
  }

  render () {
    const {
      productType,
      submitPath,
      userAddresses,
      prefectures,
      shippablePrefectures,
      earliestShippableDate,
      stripeSourceCard,
      shippingMethod,
      cartItems,
      totalCartItemStocks,
      checkoutFee,
      store,
      shippingDate,
      isDevelopment,
      deliveryNote,
      userProfileCreditCardEditUrl,
      addressCaches,
    } = this.props;

    const {
      shippingAddressUid,
      newShippingAddressRecipient,
      newShippingAddressZip,
      newShippingAddressPrefecture,
      newShippingAddressCity,
      newShippingAddressAddress1,
      newShippingAddressAddress2,
      newShippingAddressTel,
      copyShippingAddressToBillingAddress,
      billingAddressUid,
      newBillingAddressRecipient,
      newBillingAddressZip,
      newBillingAddressPrefecture,
      newBillingAddressCity,
      newBillingAddressAddress1,
      newBillingAddressAddress2,
      newBillingAddressTel,
      deliveryDate, deliverySlot,
      deliveryDateOptions,
      deliverySlotOptions,
      shippingFee,
      possibleFeeBearer,
      shippingFeeBearer,
      isNewShippingAddressRecipientInvalid,
      isNewShippingAddressZipInvalid,
      isNewShippingAddressPrefectureInvalid,
      isNewShippingAddressCityInvalid,
      isNewShippingAddressAddress1Invalid,
      isNewShippingAddressTelInvalid,
      isNewBillingAddressRecipientInvalid,
      isNewBillingAddressZipInvalid,
      isNewBillingAddressPrefectureInvalid,
      isNewBillingAddressCityInvalid,
      isNewBillingAddressAddress1Invalid,
      isNewBillingAddressTelInvalid,
      isDeliveryDateInvalid,
      isDeliverySlotInvalid,
      isShippingFeeBearerInvalid,
      isValidating,
      isLoading,
      specialRequest,
      productPreferenceGroups,
      willSubscribeToRestaurantGroup,
      newStripeSourceCard,
      coupon,
      couponUserMessage,
    } = this.state;

    const afterUpdate = () => isValidating && this.validate(true);
    const isValid = this.validate(false);
    const regixCRLF = /(\r?\n)/g;

    const sumCartItemSubTotal = cartItems.reduce((amount, ci) => amount + ci.subTotal, 0);
    const totalChargeableAmount = sumCartItemSubTotal + checkoutFee + (
      shippingFeeBearer === 'sender' ? shippingFee : 0
    ) - coupon.discountAmount;

    const shippingAddressFields = <NewOrderAddressFields
      userAddresses={userAddresses}
      prefectures={prefectures}
      selectablePrefectures={shippablePrefectures}
      addressUid={shippingAddressUid}
      setAddressUid={v => {
        const shippingAddress = userAddresses.find(ua => ua.uid === v);
        if(shippingAddress) {
          this.setShippingMethodDestination(shippingAddress.prefecture);
        } else if (!!addressCaches.shipping_address) {
          this.setShippingMethodDestination(addressCaches.shipping_address.prefecture);
        }
        this.setState({ shippingAddressUid: v, copyShippingAddressToBillingAddress: !shippingAddress }, afterUpdate);
      }}
      newAddressRecipient={newShippingAddressRecipient}
      newAddressZip={newShippingAddressZip}
      newAddressPrefecture={newShippingAddressPrefecture}
      newAddressCity={newShippingAddressCity}
      newAddressAddress1={newShippingAddressAddress1}
      newAddressAddress2={newShippingAddressAddress2}
      newAddressTel={newShippingAddressTel}
      setNewAddressRecipient={v => this.setState({ newShippingAddressRecipient: v }, afterUpdate)}
      setNewAddressZip={v => this.setState({ newShippingAddressZip: v }, afterUpdate)}
      setNewAddressPrefecture={v => {
        this.setShippingMethodDestination(v);
        this.setState({ newShippingAddressPrefecture: v }, afterUpdate);
      }}
      setNewAddressCity={v => this.setState({ newShippingAddressCity: v }, afterUpdate)}
      setNewAddressAddress1={v => this.setState({ newShippingAddressAddress1: v }, afterUpdate)}
      setNewAddressAddress2={v => this.setState({ newShippingAddressAddress2: v }, afterUpdate)}
      setNewAddressTel={v => this.setState({ newShippingAddressTel: v }, afterUpdate)}
      isRecipientInvalid={isNewShippingAddressRecipientInvalid}
      isZipInvalid={isNewShippingAddressZipInvalid}
      isPrefectureInvalid={isNewShippingAddressPrefectureInvalid}
      isCityInvalid={isNewShippingAddressCityInvalid}
      isAddress1Invalid={isNewShippingAddressAddress1Invalid}
      isAddress2Invalid={false}
      isTelInvalid={isNewShippingAddressTelInvalid}
      isShippingAddressEdit={false}
      holdAddressCache={this.holdAddressCache}
      addressCacheType={'shipping_address'}
    />;

    const shippingAddressSection = <div className="p-checkout_item">
      <div className="row">
        <div className="col-sm-3">
          <div className="p-checkout_label">お届け先</div>
        </div>
        <div className="col-sm-9">
          {shippingAddressFields}
        </div>
      </div>
    </div>;

    // https://github.com/omakasejapan/omakasemall/pull/3445
    // const billingAddressSection = <div className="p-checkout_item">
    //   <div className="row">
    //     <div className="col-sm-3">
    //       <div className="p-checkout_label">ご請求先</div>
    //     </div>
    //     <div className="col-sm-9">
    //       {!userAddresses.find(ua => ua.uid === shippingAddressUid) && <>
    //         <input type="checkbox"
    //           className="form-check-input"
    //           id="copy_shipping_address_to_billing_address"
    //           checked={copyShippingAddressToBillingAddress}
    //           onChange={this.toggleCopyShippingAddressToBillingAddress}
    //         />
    //         <label className="form-check-label mb-2" htmlFor="copy_shipping_address_to_billing_address">
    //           請求先住所を配送先住所と同じにする
    //         </label>
    //       </>}
    //       {!copyShippingAddressToBillingAddress && <NewOrderAddressFields
    //         userAddresses={userAddresses}
    //         prefectures={prefectures}
    //         selectablePrefectures={prefectures}
    //         addressUid={billingAddressUid}
    //         setAddressUid={v => this.setState({ billingAddressUid: v }, afterUpdate)}
    //         newAddressRecipient={newBillingAddressRecipient}
    //         newAddressZip={newBillingAddressZip}
    //         newAddressPrefecture={newBillingAddressPrefecture}
    //         newAddressCity={newBillingAddressCity}
    //         newAddressAddress1={newBillingAddressAddress1}
    //         newAddressAddress2={newBillingAddressAddress2}
    //         newAddressTel={newBillingAddressTel}
    //         setNewAddressRecipient={v => this.setState({ newBillingAddressRecipient: v }, afterUpdate)}
    //         setNewAddressZip={v => this.setState({ newBillingAddressZip: v }, afterUpdate)}
    //         setNewAddressPrefecture={v => this.setState({ newBillingAddressPrefecture: v }, afterUpdate)}
    //         setNewAddressCity={v => this.setState({ newBillingAddressCity: v }, afterUpdate)}
    //         setNewAddressAddress1={v => this.setState({ newBillingAddressAddress1: v }, afterUpdate)}
    //         setNewAddressAddress2={v => this.setState({ newBillingAddressAddress2: v }, afterUpdate)}
    //         setNewAddressTel={v => this.setState({ newBillingAddressTel: v }, afterUpdate)}
    //         isRecipientInvalid={isNewBillingAddressRecipientInvalid}
    //         isZipInvalid={isNewBillingAddressZipInvalid}
    //         isPrefectureInvalid={isNewBillingAddressPrefectureInvalid}
    //         isCityInvalid={isNewBillingAddressCityInvalid}
    //         isAddress1Invalid={isNewBillingAddressAddress1Invalid}
    //         isAddress2Invalid={false}
    //         isTelInvalid={isNewBillingAddressTelInvalid}
    //         isShippingAddressEdit={true}
    //         holdAddressCache={this.holdAddressCache}
    //         addressCacheType={'billing_address'}
    //       />}
    //     </div>
    //   </div>
    // </div>;

    const feeBearerField = <>
      {possibleFeeBearer === 'sender_or_recipient' ? <>
        <select
          className={"form-select" + (!shippingFeeBearer || isShippingFeeBearerInvalid ? ' is-invalid' : ' is-valid')}
          value={shippingFeeBearer}
          onChange={e => this.setState({
            shippingFeeBearer: e.target.value as typeof shippingFeeBearer,
          })}
        >
          <option key="" value="">選択してください</option>
          {["sender", "sender_or_recipient"].includes(possibleFeeBearer) && (
            <option value="sender">発払い</option>
          )}
          {["recipient", "sender_or_recipient"].includes(possibleFeeBearer) && (
            <option value="recipient">着払い</option>
          )}
        </select>
      </> : <>
        <div className='p-checkout_delivery_date_fixedValue'>
          <b>{{sender: '発払い', recipient: '着払い'}[possibleFeeBearer]}</b>にて発送いたします。
          <input type='hidden' value={possibleFeeBearer} />
        </div>
      </>}
      {shippingFeeBearer == 'recipient' && <div className='mt-2'><b>（到着時にご用意頂く着払送料：{shippingFee.toLocaleString()}円）</b></div>}
    </>;

    let deliveryNoteField: JSX.Element|null = null;
    if (!!deliveryNote) {
      deliveryNoteField =
        <div className='p-checkout_delivery_note'>
          {deliveryNote.split(regixCRLF).map((l, idx) => {
            return (l.match(regixCRLF)) ? (<br key={idx} />) : l;
          })}
        </div>;
    }

    const PrefectureEmptyAlert = <>
      {deliveryDateOptions.length === 0 && <div className='u-tx-alert'>
        <i className="fas fa-exclamation-triangle"></i>
        先にお届け先の都道府県を選択、または入力してください。
      </div>}
      {deliveryNoteField}
      <div className='p-checkout_delivery_caution'>※離島や一部地域では別途送料、到着予定日の調整が必要な場合があります。</div>
    </>;

    let shippableDateField: JSX.Element|null = null;
    if (isDevelopment) {
      shippableDateField = <div className="alert alert-warning"><i className="fas fa-user-secret"></i><span>ご注文の商品の発送日は<strong>{productType === 'freshish' ? deliveryDate : earliestShippableDate}</strong>です。</span></div>;
    }

    const shppingMethodSection = <div className="p-checkout_item">
      <div className="row">
        <div className="col-sm-3">
          <div className="p-checkout_label">配送方法</div>
        </div>
        <div className="col-sm-9">
          {shippableDateField}
          {shippingMethod.isAsapOnly ? <div>
            {deliveryDateOptions.length > 0 && <>
              <div className="p-checkout_delivery">
                <div className="p-checkout_delivery_date">
                  <label>{productType === 'freshish' ? '発送予定日' : '到着希望日'}</label>
                  <div className='p-checkout_delivery_date_fixedValue'>
                    <b>ご準備でき次第発送となります</b>
                  </div>
                </div>
                <div className="p-checkout_delivery_date">
                  <label>送料の支払</label>
                  <b>{feeBearerField}</b>
                </div>
              </div>
            </>}
            {PrefectureEmptyAlert}
          </div> : <div>
            {deliveryDateOptions.length > 0 && <>
              {(!deliveryDate || (productType !== 'freshish' && !deliverySlot && deliverySlotOptions.length > 0)) && <div className='u-tx-alert'>
                <i className="fas fa-exclamation-triangle"></i>
                到着予定日時を指定してください
              </div>}
              <div className="p-checkout_delivery">
                <div className="p-checkout_delivery_date">
                  <label>{productType === 'freshish' ? '発送予定日' : '到着希望日'}</label>
                  {deliveryDateOptions.length > 1 ? <select
                    className={classNames('form-select',
                      {'is-invalid': !deliveryDate || isDeliveryDateInvalid},
                      {'is-valid': deliveryDate && !isDeliveryDateInvalid},
                    )}
                    value={deliveryDate}
                    onChange={e => this.setDeliveryDate(e.target.value)}
                  >
                    {deliveryDateOptions.length > 1 && <option key="" value="">選択してください</option>}
                    {deliveryDateOptions.map(ddo => <option key={ddo[1]} value={ddo[1]}>{ddo[0]}</option>)}
                  </select> :
                  <div className='p-checkout_delivery_date_fixedValue'>
                    <b>{deliveryDateOptions[0][0]}</b>
                  </div>}
                </div>
                {productType !== 'freshish' && deliveryDate !== 'asap' && <>
                  <div className="p-checkout_delivery_date">
                    <label>お届け時間帯</label>
                    <select
                      className={classNames('form-select',
                        {'is-invalid': !deliverySlot || isDeliverySlotInvalid},
                        {'is-valid': deliverySlot && !isDeliverySlotInvalid},
                      )}
                      value={deliverySlot}
                      onChange={e => this.setState({ deliverySlot: e.target.value })}
                    >
                      <option key="" value="">選択してください</option>
                      {deliverySlotOptions.map(dso => <option key={dso[1]} value={dso[1]}>{dso[0]}</option>)}
                    </select>
                  </div>
                </>}
              </div>
              {possibleFeeBearer !== null && <>
                <div className="p-checkout_delivery_date">
                  <label>送料の支払</label>
                  {feeBearerField}
                </div>
              </>}
            </>}
            {PrefectureEmptyAlert}
          </div>}
        </div>
      </div>
    </div>;

    const paymentMethodSection = <div className="p-checkout_item">
      <div className="row">
        <div className="col-sm-3">
          <div className="p-checkout_label">お支払い方法</div>
        </div>
        <div className="col-sm-9">
          <div className="p-checkout_card">
            {(newStripeSourceCard || stripeSourceCard) ? (
              <>
                <p>注文確定と同時に以下のカードがお支払いに利用されます。</p>
                <p>支払い回数：一括払い</p>
                <div className="p-checkout_card_detail">
                  <span className="me-2">XXXX-XXXX-XXXX-{newStripeSourceCard?.last4 || stripeSourceCard.last4}</span>
                  <img src={newStripeSourceCard?.brandImagePath || stripeSourceCard.brandImagePath} />
                </div>
                <a href='#' onClick={async (e) => {
                  e.preventDefault()
                  this.setState({ isOpenUpdateStripe: true }, () => this.validate)
                  }}>お支払い情報の更新</a>
              </>
            ) : (
              <>
                <p>お支払いを行うクレジットカードを登録してください</p>
                <a href="#" className="btn btn-primary" onClick={async (e) => {
                  e.preventDefault()
                  this.setState({ isOpenUpdateStripe: true }, () => this.validate)
                }}>お支払い情報を登録</a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>;

    const cartItemsSection = <div className="p-checkout_item">
      <div className="row">
        <div className="col-sm-3">
          <div className="p-checkout_label">ご注文商品</div>
        </div>
        <div className="col-sm-9">
          <div className="c-uOrderList_itemList">
            {cartItems.map(ci => <div className="c-uOrderList_item" key={ci.uid}>
              <div className="c-uOrderList_img">
                {ci.productVarinatOrproductImageUrl && <div
                  style={{
                    background: `url(${ci.productVarinatOrproductImageUrl}) no-repeat center center`,
                    backgroundSize: "cover"
                  }}
                ></div>}
              </div>
              <div className="c-uOrderList_content">
                <div className="c-uOrderList_info">
                  <div className="c-uOrderList_title">{ci.productTitle}</div>
                  <div className="c-uOrderList_store">{ci.storeTitle}</div>
                </div>
                <div className="c-uOrderList_details">
                  <div>個数：{ci.stocks}</div>
                  <div>単価：{ci.unitPrice.toLocaleString()}円</div>
                  <div>小計：{ci.subTotal.toLocaleString()}円（税込）</div>
                </div>
                <div className="mt-3">
                  {ci.productPreferenceQuestions.length > 0 && <ProductPreferencesFields
                    productPreferenceGroup={productPreferenceGroups.find(ppg => ppg.cartItemUid === ci.uid)}
                    productPreferenceQuestions={ci.productPreferenceQuestions}
                    setProductPreference={this.setProductPreference}
                    isDevelopment={isDevelopment}
                  />}
                </div>
                <div className="mt-2 c-uOrderList_deadline">
                  <i className="fas fa-clock me-1"></i>
                  {ci.formatedExpiresAt}までに購入を完了してください
                </div>
              </div>
            </div>)}
            {/* {shippingFeeBearer === 'sender' && <>
              送料：{shippingFee}円
            </>}
            {totalChargeableAmount > 0 && <>
              お支払い金額：{totalChargeableAmount}円
            </>} */}
          </div>
        </div>
      </div>
    </div>;

    const specialRequestSection = <div className="p-checkout_item">
      <div className="row">
        <div className="col-sm-3">
          <div className="p-checkout_label">備考</div>
        </div>
        <div className="col-sm-9">
          <textarea
            className="form-control"
            placeholder="ご注文のお店へコメント、連絡事項がある場合はこちらにご記入ください。"
            rows={3}
            onChange={e => this.setState({ specialRequest: e.target.value })}
            defaultValue={specialRequest} />
          <span className="c-form-caution">※あくまで備考となっており、店舗からの返信を確約する訳ではございません。</span>
        </div>
      </div>
    </div>;

    const shopperSubscriptionSection = !!store.omakaseRestaurantGroupId ? <div className="p-checkout_item">
      <div className="row">
        <div className="col-sm-3">
          <div className="p-checkout_label">お知らせ</div>
        </div>
        <div className="col-sm-9">
          <input type="checkbox"
            className="form-check-input"
            id="shopper_subscription"
            checked={willSubscribeToRestaurantGroup}
            onChange={e => this.setState({ willSubscribeToRestaurantGroup: e.target.checked })}
          />
          <label className="form-check-label" htmlFor="shopper_subscription">
            この店舗と関連グループからのお知らせを受け取る
          </label>
        </div>
      </div>
    </div> : '';

    const returnPolicySection = <div className="p-checkout_item">
      <div className="row">
        <div className="col-sm-3">
          <div className="p-checkout_label">配送・キャンセル/返品/変更</div>
        </div>
        <div className="col-sm-9 p-checkout_item_caution">
          {store.legalNote.returnPolicyJa.split(/\r\n|\r|\n/g).map((row, idx) => {
            return [row, <br key={idx} />];
          })}
          {store.legalNote.deliveryPolicyJa.split(/\r\n|\r|\n/g).map((row, idx) => {
            return [row, <br key={idx} />];
          })}
        </div>
      </div>
    </div>;

    const hiddenFields = <>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      <input type="hidden" name="total_cart_item_stocks" value={totalCartItemStocks} />
      <input type="hidden" name="total_chargeable_amount" value={totalChargeableAmount} />
      <input type="hidden" name="displayed_stripe_source_id" value={newStripeSourceCard?.id || stripeSourceCard?.id} />

      <input type="hidden" name="shipping_address_uid" value={shippingAddressUid} />
      <input type="hidden" name="new_shipping_address_recipient" value={newShippingAddressRecipient} />
      <input type="hidden" name="new_shipping_address_zip" value={newShippingAddressZip} />
      <input type="hidden" name="new_shipping_address_prefecture" value={newShippingAddressPrefecture} />
      <input type="hidden" name="new_shipping_address_city" value={newShippingAddressCity} />
      <input type="hidden" name="new_shipping_address_address1" value={newShippingAddressAddress1} />
      <input type="hidden" name="new_shipping_address_address2" value={newShippingAddressAddress2} />
      <input type="hidden" name="new_shipping_address_tel" value={newShippingAddressTel} />

      <input type="hidden" name="copy_shipping_address_to_billing_address"
        value={copyShippingAddressToBillingAddress ? '1' : '0'}
      />
      <input type="hidden" name="billing_address_uid" value={billingAddressUid} />
      <input type="hidden" name="new_billing_address_recipient" value={newBillingAddressRecipient} />
      <input type="hidden" name="new_billing_address_zip" value={newBillingAddressZip} />
      <input type="hidden" name="new_billing_address_prefecture" value={newBillingAddressPrefecture} />
      <input type="hidden" name="new_billing_address_city" value={newBillingAddressCity} />
      <input type="hidden" name="new_billing_address_address1" value={newBillingAddressAddress1} />
      <input type="hidden" name="new_billing_address_address2" value={newBillingAddressAddress2} />
      <input type="hidden" name="new_billing_address_tel" value={newBillingAddressTel} />

      <input type="hidden" name="shipping_method_uid" value={shippingMethod.uid} />
      <input type="hidden" name="delivery_date" value={deliveryDate} />
      <input type="hidden" name="delivery_slot" value={deliverySlot} />
      <input type="hidden" name="shipping_fee_bearer" value={shippingFeeBearer} />
      <input type="hidden" name="shipping_date" value={shippingDate ? shippingDate : ''} />

      <input type="hidden" name="special_request" value={specialRequest} />
      <input type="hidden" name="product_preference_groups" value={JSON.stringify(productPreferenceGroups)} />
      <input type="hidden" name="will_subscribe_to_restaurant_group" value={willSubscribeToRestaurantGroup ? '1' : '0'} />

      <input type="hidden" name="coupon_code" value={coupon.code} />
    </>;

    let isValidPreferences = true;
    productPreferenceGroups.forEach(ppg => {
      const cartItem = cartItems.find(ci => ci.uid === ppg.cartItemUid);
      if (cartItem.productPreferenceQuestions.length > 0) {
        return ppg.preferences.flat().find((ppa) => {
          const ppq = cartItem.productPreferenceQuestions.find(
            ppq => ppq.id === ppa.productPreferenceQuestionId
          );

          if(isRequiredProductPreferenceAnswerEmpty(ppa, ppq)) {
            isValidPreferences = false
          }
        });
      }
    });
    const invalidErrorSection = ((!newStripeSourceCard && !stripeSourceCard) || !isValid) && <div className="mb-3 text-start alert alert-danger">
      {function() {
        const shippingAddressErrors = [];
        if (!shippingAddressUid) {
          if (!newShippingAddressRecipient) shippingAddressErrors.push('氏名');
          if (newShippingAddressZip.length != 7) shippingAddressErrors.push('郵便番号(ハイフンなし)');
          if (!newShippingAddressPrefecture) shippingAddressErrors.push('都道府県');
          if (!newShippingAddressCity) shippingAddressErrors.push('市区町村');
          if (!newShippingAddressAddress1) shippingAddressErrors.push('住所');
          if (!newShippingAddressTel || newShippingAddressTel.length < 8 || newShippingAddressTel.length > 12) shippingAddressErrors.push('電話番号');
        }
        const billingAddressErrors = [];
        if (!copyShippingAddressToBillingAddress && !billingAddressUid) {
          if (!newBillingAddressRecipient) billingAddressErrors.push('氏名');
          if (newBillingAddressZip.length != 7) billingAddressErrors.push('郵便番号(ハイフンなし)');
          if (!newBillingAddressPrefecture) billingAddressErrors.push('都道府県');
          if (!newBillingAddressCity) billingAddressErrors.push('市区町村');
          if (!newBillingAddressAddress1) billingAddressErrors.push('住所');
          if (!newBillingAddressTel || newBillingAddressTel.length < 8 || newBillingAddressTel.length > 12) billingAddressErrors.push('電話番号');
        }
        const shppingMethodSectionErrros = [];
        if (!deliveryDate || (!deliverySlot && deliverySlotOptions.length > 0)) shppingMethodSectionErrros.push('到着予定日時');
        if (possibleFeeBearer === 'sender_or_recipient' && (!shippingFeeBearer || isShippingFeeBearerInvalid)) shppingMethodSectionErrros.push('送料の支払');
        const stripeSourceCardErrors = [];
        if (!newStripeSourceCard && !stripeSourceCard) stripeSourceCardErrors.push('お支払い方法を設定してください');
        const preferencesErrors = [];
        if (!isValidPreferences) preferencesErrors.push('必須回答項目が残っています');

        const res = [];
        if (shippingAddressErrors.length > 0) {
          res.push(<div key="error-message-shipping-address">
            【お届け先】{shippingAddressErrors.join('、')}を入力してください
          </div>);
        }
        if (billingAddressErrors.length > 0) {
          res.push(<div key="error-message-billing-address">
            【ご請求先】{billingAddressErrors.join('、')}を入力してください
          </div>);
        }
        if (productType !== 'freshish' && shppingMethodSectionErrros.length > 0) {
          res.push(<div key="error-message-shipping-method">
            【配送方法】{shppingMethodSectionErrros.join('、')}を指定してください
          </div>);
        }
        if (stripeSourceCardErrors.length > 0) {
          res.push(<div key="error-message-stripe-source">
            【お支払い方法】{stripeSourceCardErrors.join('、')}
          </div>);
        }
        if (preferencesErrors.length > 0) {
          res.push(<div key="error-message-preferences">
            【ご注文商品】{preferencesErrors.join('、')}
          </div>);
        }
        return res;
      }()}
    </div>

    // loading処理 タグをdisabledにするとsubmit出来ないので見た目だけdisabledにしている
    let submitClasses = classNames('btn', 'btn-primary', 'btn-wide', 'btn-lg', 'fw-bold', {disabled: isLoading});

    // NOTE: ドラフト・審査中の商品がカートに入るケースがある（その場合はpostしないようにする）
    //       ref) https://github.com/omakasejapan/omakasemall/issues/2342
    const isAllApprovedByCartItems = !cartItems.find(ci => ci.productReviewStatus !== 'approved')

    return (
      <Elements stripe={this.stripePromise}>
        <form ref={this.form} action={isAllApprovedByCartItems ? submitPath : '#'} method={isAllApprovedByCartItems ? 'post' : 'get'}>
          <TimedPopupFields />
          <div className="p-checkout">
            <div className="c-section">
              {shippingAddressSection}
              {/* https://github.com/omakasejapan/omakasemall/pull/3445 */}
              {/* {billingAddressSection} */}
              {shppingMethodSection}
              {paymentMethodSection}
              {cartItemsSection}
              {hiddenFields}
              <CouponFields
                coupon={coupon}
                defaultCouponCode=''
                setCouponCode={this.setCouponCode}
                userMessage={couponUserMessage}
                isApplyButtonActive={this.findShippingPrefectureValue() !== ''}
              />
              {(totalChargeableAmount > 0 || coupon.discountAmount > 0) && <>
                <div className="p-checkout_item">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="p-checkout_label">購入明細</div>
                    </div>
                    <div className="col-sm-9">
                      <ul className="c-uOrder_details_prices">
                        <li>商品の小計： {sumCartItemSubTotal.toLocaleString()}円</li>
                        {shippingFeeBearer === 'sender' ? (
                          <li>配送料・手数料： {(shippingFee + checkoutFee).toLocaleString()}円</li>
                        ) : (
                          <li>手数料： {checkoutFee.toLocaleString()}円</li>
                        )}
                        {coupon.discountAmount > 0 && <li>
                          クーポン割引： -{coupon.discountAmount.toLocaleString()}円
                        </li>}
                        <li className="c-uOrder_details_prices_sum">
                          合計金額(税込)： {totalChargeableAmount.toLocaleString()}円
                        </li>
                        {store.checkoutFeePerStock > 0 &&
                          <li className="c-uOrder_details_prices_fee"><small>1商品あたり {store.checkoutFeePerStock} 円のチェックアウト手数料を頂いております。</small></li>}
                      </ul>
                    </div>
                  </div>
                </div>
              </>}
              {returnPolicySection}
              {specialRequestSection}
              {shopperSubscriptionSection}
              <div className="p-checkout_proceed">
                <div className="p-checkout_proceed_caution">
                  ご注文を完了することで、OMAKASEの
                  <a href='https://omakase.in/terms' target='_blank'>利用規約</a>、
                  およびプライバシーポリシー3条の内容に加え、商品発送先住所の提供に同意したものとみなします。
                </div>
                {invalidErrorSection}
                <button
                  type="submit"
                  value="注文を確定する"
                  className={submitClasses}
                  disabled={this.findShippingPrefectureValue() !== '' && (!newStripeSourceCard && !stripeSourceCard) || !isValid}
                  onClick={_e => this.setState({isLoading: true})}
                >
                  {isLoading && (<span className='spinner-border spinner-border-sm mx-3'></span>)}
                  <span className='my-3'>{isLoading ? '処理中…' : '注文を確定する'}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <Modal
          show={this.state.isOpenUpdateStripe}
          onHide={() => this.setState({ isOpenUpdateStripe: false })}
          className='p-checkout_ccModal'
        >
          <Modal.Header closeButton>
            {newStripeSourceCard || stripeSourceCard ? 'お支払い情報の更新' : 'お支払い情報を登録'}
          </Modal.Header>
          <Modal.Body>
            {newStripeSourceCard || stripeSourceCard ? (
              <p className="p-checkout_ccModal_caution">
                <i className="fas fa-exclamation-circle me-1"></i>
                クレジットカードを更新すると予約サイト側の決済クレジットカードも同時に更新されます
              </p>
            ) : null}
            <StripeCardForm
              isUpdate={!!newStripeSourceCard || !!stripeSourceCard}
              setNewStripeSourceCard={(newStripeSourceCard: StripeSourceCard) => { this.setState({ newStripeSourceCard }) }}
              setCardUpdateSuccessMessage={(message: string) => this.setState({ cardUpdateSuccessMessage: message })}
              closeModal={() => this.setState({ isOpenUpdateStripe: false })}
            />
          </Modal.Body>
        </Modal>
        {!!this.state.cardUpdateSuccessMessage && (
          <FlashMessage type="notice" message={this.state.cardUpdateSuccessMessage} />
        )}
      </Elements>
    );
  }
};

export default NewOrderForm;

import * as React from 'react'
import Rails from 'rails-ujs'
import Cropper from 'react-cropper'
import { Button, Modal } from 'react-bootstrap'
import axios from 'axios'

interface ImageData {
  name: string
  src: string
}

interface StoreImage {
  id: number
  is_cover: boolean
  is_thumb: boolean
  memo: string
  created_at: string
  updated_at: string
}

type AspectType = 'cover' | 'thumbnail'

const aspectRatio = {
  'cover': 1800 / 450,
  'thumbnail': 1000 / 700,
}

interface Props {
  storeImage: StoreImage
  imageSrc: string
}

const StoreImageForm: React.FC<Props> = ({ storeImage, imageSrc }: Props) => {
  const [imageData, setImageData] = React.useState<ImageData | null>(null)
  const [editedImage, setEditedImage] = React.useState<ImageData | null>(null)
  const [croppedCanvas, setCroppedCanvas] = React.useState<HTMLCanvasElement | null>(null)
  const [isThumb, setIsThumb] = React.useState<boolean>(false)
  const [isCover, setIsCover] = React.useState<boolean>(false)
  const [imageErrors, setImageErrors] = React.useState<string | null>(null)
  const [aspectType, setAspectType] = React.useState<AspectType>('cover')

  const imageRef: React.Ref<HTMLInputElement> = React.useRef<HTMLInputElement>(null)

  const isEdit: boolean = React.useMemo(() => !!storeImage.id, [storeImage.id])

  const cropperRef: React.Ref<HTMLImageElement> = React.useRef<HTMLImageElement>(null)
  const onCrop = React.useCallback(() => {
    const imageElement: any = cropperRef?.current
    const cropper = imageElement?.cropper;
    setCroppedCanvas(cropper.getCroppedCanvas())
  }, [])

  const handleClickEditImage = React.useCallback(() => {
    setEditedImage({
      name: imageData.name,
      src: croppedCanvas.toDataURL()
    })
    setImageData(null)
  }, [croppedCanvas])

  const handleChangeFile = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target.result as string
      const img = new Image()
      img.src = result

      img.onload = () => {
        setImageData({
          name: file.name,
          src: img.src
        })
      }
    }

    reader.readAsDataURL(file)
  }, [])

  const handleClickIsThumb = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    setIsThumb(e.currentTarget.checked)
  }, [])

  const handleClickIsCover = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    setIsCover(e.currentTarget.checked)
  }, [])

  const handleClose = React.useCallback(() => {
    imageRef.current.value = ''
    setImageData(null)
  }, [])

  const handleChangeType = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value as AspectType
    setAspectType(value);

    const imageElement: any = cropperRef?.current
    const cropper = imageElement?.cropper;
    cropper.setAspectRatio(aspectRatio[value])
  }, [])

  const handleSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('store_image[is_thumb]', isThumb ? '1' : '0')
    formData.append('store_image[is_cover]', isCover ? '1' : '0')

    if (!!editedImage) {
      const blob = await (await fetch(editedImage.src)).blob()
      formData.append('store_image[image]', blob, editedImage.name)
    }

    let url = isEdit ? `/owner/store_images/${storeImage.id}` : '/owner/store_images'

    try {
      const response = await axios({
        method: isEdit ? 'PUT' : 'POST',
        url,
        data: formData,
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
        }
      })

      if (response.status === 200) {
        window.location.href = '/owner/store_images'
      }
    } catch (e) {
      if (e.response?.data?.image_errors) {
        setImageErrors(e.response.data.image_errors.join(','))
        return
      }

      window.location.href = '/owner/store_images'
    }
  }, [editedImage, isThumb, isCover, isEdit])

  const image = React.useMemo(() => {
    if (editedImage) {
      return (
        <div className="mb-4">
          <img src={editedImage.src} alt={editedImage.name} className="w-100" />
        </div>
      )
    }

    if (imageSrc) {
      return (
        <div className="mb-4">
          <img src={imageSrc} alt="" className="w-100" />
        </div>
      )
    }
  }, [editedImage, imageSrc])

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mb-4">
        <div className="col-sm-2 col-form-label">
          <label htmlFor="store_image_image">画像を選択</label>
        </div>
        <div className="col-sm-10">
          {image}
          <input
            ref={imageRef}
            id="store_image_image"
            className="form-control"
            required={!storeImage}
            accept="image/png, image/jpg, image/jpeg"
            type="file"
            name="image"
            onChange={handleChangeFile}
          />
          <small>png, jpg, jpegファイルのみアップロード可能です</small>
          <div className="alert alert-warning mt-2">
            推奨サイズ　店舗カバー【横1800px 縦450px】　一覧表示サムネイル【横1000px 縦700px】
          </div>
          {imageErrors && (
            <div className="text-danger">
              {imageErrors}
            </div>
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-2 col-form-label" />
        <div className="col-sm-10">
          <div className="form-check">
            <input
              id="store_image_is_thumb"
              className="form-check-input"
              type="checkbox"
              value="1"
              name="is_thumb"
              defaultChecked={!!storeImage.is_thumb}
              onClick={handleClickIsThumb}
            />
            <div className="form-check-label">
              <label htmlFor="store_image_is_thumb">サムネイルに設定</label>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-2 col-form-label" />
        <div className="col-sm-10">
          <div className="form-check">
            <div className="form-check-label">
              <input
                id="store_image_is_cover"
                className="form-check-input"
                type="checkbox"
                value="1"
                name="is_cover"
                defaultChecked={!!storeImage.is_cover}
                onClick={handleClickIsCover}
              />
              <div className="form-check-label">
                <label htmlFor="store_image_is_cover">カバー画像に設定</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <button
          type="submit"
          name="commit"
          value="保存する"
          className="btn btn-primary"
        >
          保存する
        </button>
      </div>
      <Modal
        show={!!imageData}
        onHide={handleClose}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>画像編集</Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <select className="form-control" defaultValue={aspectType} onChange={handleChangeType}>
              <option value="cover">店舗カバー【横1800px 縦450px】</option>
              <option value="thumbnail">一覧表示サムネイル【横1000px 縦700px】</option>
            </select>
          </div>
          {!!imageData && (
            <Cropper
              src={imageData.src}
              style={{ height: 500, width: '100%' }}
              aspectRatio={1800 / 450}
              autoCropArea={1}
              crop={onCrop}
              viewMode={1}
              ref={cropperRef}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClickEditImage}>設定する</Button>
        </Modal.Footer>
      </Modal>
    </form>
  )
}

export default StoreImageForm

import * as React from 'react';
import Rails from 'rails-ujs';
import AddressFields from './profile_fields/AddressFields';
import TelField from './profile_fields/TelField';
import { Prefectures, PrefectureValue } from "./types/Prefecture";
import ClipLoader from "react-spinners/ClipLoader";

type Props = {
  recipient: string,
  tel: string,
  zip: string,
  prefecture: PrefectureValue,
  city: string,
  address1: string,
  address2: string,
  submitPath: string,
  submitMethod: string,
  defaultIsDefault: boolean,
  prefectures: Prefectures,
};

type State = {
  recipient: string,
  tel: string,
  zip: string,
  prefecture: PrefectureValue,
  city: string,
  address1: string,
  address2: string,
  isRecipientInvalid: boolean,
  isTelInvalid: boolean,
  isZipInvalid: boolean,
  isPrefectureInvalid: boolean,
  isCityInvalid: boolean,
  isAddress1Invalid: boolean,
  isAddress2Invalid: boolean,
  isLoading: boolean,
  isLoadingForDestory: boolean,
  isValidating: boolean,
  isDefault: boolean,
};

class UserAddressForm extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;

  constructor (props: Props) {
    super(props);

    this.state = {
      recipient: props.recipient, tel: props.tel,
      zip: props.zip, prefecture: props.prefecture, city: props.city,
      address1: props.address1, address2: props.address2,
      isRecipientInvalid: false, isTelInvalid: false,
      isZipInvalid: false, isPrefectureInvalid: false, isCityInvalid: false,
      isAddress1Invalid: false, isAddress2Invalid: false,
      isLoading: false, isLoadingForDestory: false, isValidating: false,
      isDefault: props.defaultIsDefault
    };
    this.validate = this.validate.bind(this);
    this.form = React.createRef()
    this.formForDestory = React.createRef()
  }

  validate () {
    let result = true;

    const {
      recipient, tel,
      zip, prefecture, city, address1,
    } = this.state;

    if(recipient.length === 0) {
      this.setState({isRecipientInvalid: true})
      result = false;
    } else {
      this.setState({isRecipientInvalid: false})
    }

    if(tel.length < 8 || tel.length > 12) {
      this.setState({isTelInvalid: true})
      result = false;
    } else {
      this.setState({isTelInvalid: false})
    }

    if(zip.length != 7) {
      this.setState({isZipInvalid: true})
      result = false;
    } else {
      this.setState({isZipInvalid: false})
    }
    if(prefecture.length === 0) {
      this.setState({isPrefectureInvalid: true})
      result = false;
    } else {
      this.setState({isPrefectureInvalid: false})
    }
    if(city.length === 0) {
      this.setState({isCityInvalid: true})
      result = false;
    } else {
      this.setState({isCityInvalid: false})
    }
    if(address1.length === 0) {
      this.setState({isAddress1Invalid: true})
      result = false;
    } else {
      this.setState({isAddress1Invalid: false})
    }

    return result;
  }

  render () {
    const {
      submitPath, submitMethod, prefectures
    } = this.props;

    const {
      recipient, tel,
      zip, prefecture, city, address1, address2,
      isRecipientInvalid, isTelInvalid,
      isZipInvalid, isPrefectureInvalid, isCityInvalid,
      isAddress1Invalid, isAddress2Invalid,
      isLoading, isLoadingForDestory, isValidating, isDefault,
    } = this.state;

    const afterUpdate = () => {isValidating && this.validate()}

    return (
      <>
        <form ref={this.form} action={submitPath} method="post" className="">
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />

          <div className="form-group row mb-3">
            <div className="col-sm-6">
              <label>お届け先氏名<span className="c-form_rq">必須</span></label>
              <input type="text"
                value={recipient}
                onChange={e => {this.setState({ recipient: e.target.value })}}
                placeholder="OMAKASE 太郎"
                className={"form-control" + (isRecipientInvalid ? ' is-invalid' : '')}
              />
            </div>
          </div>

          <AddressFields
            prefectures={prefectures}
            zip={zip} prefecture={prefecture} city={city}
            address1={address1} address2={address2}
            setZip={v => this.setState({ zip: v }, afterUpdate)}
            setPrefecture={v => this.setState({ prefecture: v }, afterUpdate)}
            setCity={v => this.setState({ city: v }, afterUpdate)}
            setAddress1={v => this.setState({ address1: v }, afterUpdate)}
            setAddress2={v => this.setState({ address2: v }, afterUpdate)}
            isZipInvalid={isZipInvalid}
            isPrefectureInvalid={isPrefectureInvalid}
            isCityInvalid={isCityInvalid}
            isAddress1Invalid={isAddress1Invalid}
            isAddress2Invalid={isAddress2Invalid}
          />

          <TelField
            tel={tel}
            setTel={v => this.setState({ tel: v }, afterUpdate)}
            isTelInvalid={isTelInvalid}
          />

          <input type="checkbox"
            className="form-check-input"
            id="is_default_checkbox"
            checked={isDefault}
            onChange={e => this.setState({ isDefault: !isDefault })}
          />
          <label className="form-check-label mb-2" htmlFor="is_default_checkbox">
            この住所をメインに設定する
          </label>

          <input type="hidden" name="user_address[recipient]" value={recipient} />
          <input type="hidden" name="user_address[tel]" value={tel} />
          <input type="hidden" name="user_address[zip]" value={zip} />
          <input type="hidden" name="user_address[prefecture]" value={prefecture} />
          <input type="hidden" name="user_address[city]" value={city} />
          <input type="hidden" name="user_address[address1]" value={address1} />
          <input type="hidden" name="user_address[address2]" value={address2} />
          <input type="hidden" name="user_address[is_default]" value={isDefault} />
          {submitMethod !== 'post' && <input type="hidden" name="_method" value={submitMethod} />}

          <div className="pt-3">
            <button className="btn btn-primary fw-bold" disabled={isLoading || isLoadingForDestory} onClick={(e) => {
              e.preventDefault()
              this.setState({ isLoading: true, isValidating: true }, () => {
                if(this.validate()) {
                  this.form.current.submit()
                } else {
                  this.setState({ isLoading: false })
                }
              })
            }}>
              {isLoading ? '処理中です' : '配送先を登録する'}
            </button>
          </div>
        </form>
        {submitMethod == 'patch' && <form action={submitPath} method="post" id="formForDestory" onSubmit={(e) => {
          e.preventDefault();
          if (window.confirm('削除してよろしいですか？')) {
            this.setState({ isLoadingForDestory: true });
            document.getElementById('formForDestory').submit();
          }
        }}>
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <input type="hidden" name="_method" value="delete" />
          <button className="btn btn-outline-secondary fw-bold mt-3" disabled={isLoading || isLoadingForDestory}>
            {isLoadingForDestory ? '処理中です' : '配送先を削除する'}
          </button>
        </form>}
      </>
    );
  }
}

export default UserAddressForm;

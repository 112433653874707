import * as React from "react";
import FlashMessage from "../FlashMessage";
import UploadedImage from "./UploadedImage";

export type RegisteredImage = {
  id: Readonly<number>
  image: Readonly<string>
  is_thumb?: Readonly<boolean>
  sort?: Readonly<number>
}

export type UploadedImageData = {
  id?: number
  file?: File
  src: string
  isThumb: boolean
}

interface Props {
  accept: string
  isEditable: boolean
  isDisabled: boolean
  registeredImages: RegisteredImage[]
  maxFileSize?: number
  maxFileNum?: number
  hasDeleteAll?: boolean
  isSingleMode?: boolean
  inputName?: string
  deletionComponent?: React.ReactElement
  onClick?: (uploadedImages: UploadedImageData[], deletedImageIds: number[]) => void
}

const DEFAULT_MAX_FILE_SIZE = 10485760
const DEFAULT_MAX_FILE_NUM = 20

const ImageUpdater: React.FC<Props> = ({
  accept,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  maxFileNum = DEFAULT_MAX_FILE_NUM,
  isEditable,
  isDisabled,
  isSingleMode,
  inputName,
  deletionComponent,
  registeredImages,
  hasDeleteAll = false,
  onClick
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false)
  const [files, setFiles] = React.useState<(File | RegisteredImage)[]>([])
  const [uploadedImages, setUploadedImages] = React.useState<UploadedImageData[]>([])
  const [deleteImageIds, setDeleteImageIds] = React.useState<number[]>([])
  const [isReset, setIsReset] = React.useState<boolean>(false)
  const [isEdited, setIsEdited] = React.useState<boolean>(false)
  const [isChangeImage, setIsChangeImage] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  const fileRef: React.Ref<HTMLInputElement> = React.useRef<HTMLInputElement>(null)
  const buttonRef: React.Ref<HTMLButtonElement> = React.useRef<HTMLButtonElement>(null)

  const isSingleModeDeleted = React.useMemo(() =>
    isSingleMode && registeredImages.length > 0 && deleteImageIds.includes(registeredImages[0].id),
    [isSingleMode, registeredImages, deleteImageIds]
  )

  const clearImages = React.useCallback(() => {
    setUploadedImages([])
    setFiles([])
  }, [])

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!isEdited || isSingleMode) return

    event.preventDefault()
    event.returnValue = 'check'
  }

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isEdited])

  React.useEffect(() => {
    if (isSingleMode || !isEdited) return

    buttonRef.current.addEventListener('click', () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    })
  }, [isEdited])

  React.useEffect(() => {
    if (isEdited || registeredImages.length === 0 || isSingleModeDeleted) return

    // 既に登録済みの画像を入れておく
    setFiles(registeredImages.map((img) => img))
    setUploadedImages(
      registeredImages.map((img) => ({
        id: img.id,
        src: img.image,
        isThumb: img.is_thumb,
      }))
    )
  }, [registeredImages, isSingleModeDeleted, isEdited])

  React.useEffect(() => {
    if (!errorMessage) return

    setTimeout(() => {
      setErrorMessage(null)
    }, 5000)
  }, [errorMessage])

  React.useEffect(() => {
    if (!isReset) return
    // リセットフラグが立った場合ファイルをクリア
    clearImages()
  }, [isReset])

  const filteredFiles = React.useCallback((targetFiles: FileList) => {
    let isValid = true
    const validFiles = Array.from(targetFiles).filter((file) => {
      if (file.size > maxFileSize) {
        isValid = false
        return false
      }
      return true
    })

    if (!isValid) {
      const maxByteToMB = maxFileSize / 1024 / 1024
      setErrorMessage(`アップロードできる画像サイズは${maxByteToMB}MBまでです`)
    }

    return validFiles
  }, [maxFileSize])

  const isValidFileNum = React.useCallback((targetFiles: FileList) => {
    if (files.length + targetFiles.length > maxFileNum) {
      setErrorMessage('アップロードできる画像数は20枚までです')
      return false
    }
    return true
  }, [files, maxFileNum])

  const handleDragOver = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsHovering(true)
    e.dataTransfer.dropEffect = 'copy'
  }, [])

  const handleDragLeave = React.useCallback(() => {
    setIsHovering(false)
  }, [])

  const handleDrop = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    setIsHovering(false)

    if (!isValidFileNum(e.dataTransfer.files)) return

    setFiles(files.concat(filteredFiles(e.dataTransfer.files)))
  }, [files])

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length === 0) return

    if (!isValidFileNum(e.target.files)) return

    setIsEdited(true)

    if (isChangeImage) {
      setIsChangeImage(false)
      setFiles(filteredFiles(e.target.files))
      return
    }

    if (isSingleMode) {
      setDeleteImageIds([])
    }

    setFiles(files.concat(filteredFiles(e.target.files)))
  }, [files, isChangeImage, isSingleMode])

  const handleClickDeleteImage = React.useCallback((index: number) => {
    const deleteFile = files.find((_, i) => i === index)

    // 登録済画像が削除された場合、削除されたidをdeletedImageIdsに追加
    if ('id' in deleteFile) {
      setDeleteImageIds(deleteImageIds.concat(deleteFile.id))
    }

    setIsEdited(true)
    setUploadedImages(uploadedImages.filter((_, i) => i !== index))
    setFiles(files.filter((_, i) => i !== index))
    setIsChangeImage(false)
  }, [files, uploadedImages, deleteImageIds])

  const handleClickChangeImage = React.useCallback(() => {
    setIsChangeImage(true)
    fileRef.current.click()
  }, [])

  const handleClickDeleteAllImage = React.useCallback(() => {
    clearImages()
  }, [clearImages])

  const handleClickSetThumbnail = React.useCallback((index: number) => {
    const clonedUploadedImages = [...uploadedImages]
    clonedUploadedImages.forEach((uploadedImage, idx) => {
      if (idx === index) {
        uploadedImage.isThumb = true
      } else {
        uploadedImage.isThumb = false
      }
    })

    setUploadedImages(clonedUploadedImages)
  }, [uploadedImages])

  const images = React.useMemo(() => {
    if (files.length === 0) return

    return Array.from(files).map((file, index) => (
      <UploadedImage
        key={`file-${index}`}
        file={file}
        files={files}
        index={index}
        image={uploadedImages[index]}
        uploadedImages={uploadedImages}
        isSingleMode={isSingleMode}
        isEditable={isEditable}
        isEdited={isEdited}
        setFiles={setFiles}
        setUploadedImages={setUploadedImages}
        setErrorMessage={setErrorMessage}
        setIsReset={setIsReset}
        setIsEdited={setIsEdited}
        onSetThumbnail={() => handleClickSetThumbnail(index)}
        onChangeImage={handleClickChangeImage}
        onDelete={() => handleClickDeleteImage(index)}
      />
    ))
  }, [uploadedImages, files, isSingleMode])

  const submitButton = React.useMemo(() => {
    if (!isEditable || isSingleMode || !isEdited) return

    const buttonText = registeredImages.length === 0 ? '画像を登録する' : '画像を更新する'

    return (
      <div className="c-imageUpdater_submit">
        <button
          ref={buttonRef}
          className="btn btn-large btn-primary btn-wide"
          disabled={files.length === 0 || isDisabled}
          onClick={() => {
            onClick(uploadedImages, deleteImageIds)
          }}
        >
          {buttonText}
        </button>
      </div>
    )
  }, [isEdited, isDisabled, files, uploadedImages, deleteImageIds])

  return (
    <div className="c-imageUpdater">
      <div className="c-imageUpdater_uploadedImages">
        {images}
        {isEditable && <div
          className={`c-imageUpdater_uploadedImage c-imageUpdater_dropzone ${isSingleMode ? 'single' : ''} ${isSingleMode && files.length > 0 ? 'hidden' : ''} ${isHovering && 'hover'}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label className="c-imageUpdater_inputLabel">
            <input
              ref={fileRef}
              type="file"
              className="c-imageUpdater_inputFile"
              name={inputName}
              accept={accept}
              multiple
              onChange={handleChange}
            />
            <div className="c-imageUpdater_addImage">
              <i className="far fa-image" />
              <p>画像を追加</p>
              {!isSingleMode && (
                <>
                  <div>横1800 x 縦1200px</div>
                  <div>(推奨)</div>
                </>
              )}
            </div>
          </label>
        </div>}
      </div>
      {hasDeleteAll && (
        <div className="c-imageUpdater_deleteAllImage" onClick={handleClickDeleteAllImage}>
          <i className="far fa-trash-alt" />
          すべて削除
        </div>
      )}
      {submitButton}
      {!!errorMessage && (
        <FlashMessage type="alert" message={errorMessage} />
      )}
      {isSingleModeDeleted && deletionComponent}
    </div>
  )
}

export default ImageUpdater

import * as React from "react";

type Props = {
  type: 'notice' | 'alert'
  message: string
}

const FlashMessage: React.VFC<Props> = ({ type, message }) => {
  const className = type === 'alert' ? 'c-flash c-flash-alert' : 'c-flash'

  return (
    <div className={className} role="alert">
      {message}
    </div>
  )
}

export default FlashMessage

import * as React from "react";
import axios, { AxiosResponse } from 'axios';
import Rails from 'rails-ujs';
import I18n from '../src/i18n-js/index.js.erb';

type Props = {
  orderUid: string
  isReceiptSent: boolean
  defaultEmail: string
}

const SendReceiptModalForm: React.VFC<Props> = ({ orderUid, isReceiptSent, defaultEmail }: Props) => {
  const [email, setEmail] = React.useState<string>(defaultEmail);
  const [isValid, setIsValid] = React.useState<boolean>(!!defaultEmail);
  const [isReceiptSendable, setIsReceiptSendable] = React.useState<boolean>(!isReceiptSent);
  const [isSent, setIsSent] = React.useState<boolean>(false)

  I18n.locale = 'ja';

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    let hasError = false;

    if (e.target.value === '' || !e.target.value.match(/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/)) {
      hasError = true;
    }

    setIsValid(!hasError);
  }, [])

  const handleClick = React.useCallback(async() => {
    setIsSent(true)

    const url = `/my/orders/${orderUid}/send_receipt`;

    try {

      const response: AxiosResponse = await axios.post(
        url, {
          receipt_email: email,
        },
        {
          headers: { 'X-CSRF-Token': Rails.csrfToken() },
        }
      );

      if (response.status === 200) {
        setIsReceiptSendable(false)
        location.reload()
      }
    } catch (_error) {
      location.reload()
    }
  }, [email])

  return (
    <>
      <button
        className="btn btn-primary btn-sm"
        disabled={!isReceiptSendable}
        data-bs-toggle="modal"
        data-bs-target="#send-receipt-modal"
      >
        {I18n.t('users.orders.show.issue_receipt')}
      </button>
      <div id="send-receipt-modal" aria-hidden role="dialog" className="modal fade" tabIndex={-1}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title fw-bold">{I18n.t('users.orders.show.send_receipt_title')}</div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                data-bs-dismiss="modal"
              />
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <small className="mb-2">領収書を送信するメールアドレスを入力してください。決済サービスの仕様上、宛名の入力が出来かねます。予めご了承くださいませ。</small>
                <input
                  type="text"
                  value={email}
                  className="form-control"
                  placeholder={I18n.t('users.orders.show.email')}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                disabled={!isValid || !isReceiptSendable || isSent}
                onClick={handleClick}
              >
                {I18n.t('users.orders.show.send_receipt')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SendReceiptModalForm;

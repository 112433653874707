import * as React from 'react';
import Rails from 'rails-ujs';
import { Prefectures } from './types/Prefecture';

class ShippingMethodDestinationTransition {
  sourcePrice: number
  destinationPrice: number
  constructor(price){
    this.destinationPrice = price;
  }
}

type ShippingMethodDestination = {
  prefectureValue: string,
  prefectureLabel: string,
  price: string,
  destinationPrice: string,
  isSupported: boolean,
  minShippingSpeedDays: string,
  minShippingSpeedSlot: string,
  maxShippingSpeedDays: string,
  transition: ShippingMethodDestinationTransition,
}

type PriceList = {
  key: number
}

type ShippingMethodDestinationSource = {
  zipShippingFrom: string,
  zipShippingTo: string,
  prefectureFrom: string,
  prefectureTo: string,
  groceryMinShippingSpeedDays: number,
  groceryMinShippingSpeedSlot: string,
  freshMinShippingSpeedDays: number,
  freshMinShippingSpeedSlot: string,
  groceryPriceList: PriceList[],
  freshPriceList: PriceList[],
  transitionStartsAt: Date|null,
}

interface Props {
  prefectures: Prefectures,
  shippingMethodDestinations: ShippingMethodDestination[],
  productType: 'fresh'|'grocery'|'freshish',
  minShippingSpeedSlotOptions: [string, string][],
  defaultMinShippingSpeedSlot: string,
  submitPath: string,
  destinationSources: ShippingMethodDestinationSource[],
  newlyDestinationSources: ShippingMethodDestinationSource[],
  sourceSetTitle: string,
  temperature: string,
  transitionStartsAt: Date|null,
  isTransitional: boolean,
  storeSlug: string,
}

interface State {
  shippingMethodDestinations: ShippingMethodDestination[],
  prefecturesAllChecked: boolean,
  bulkInputValue: string,
  bulkInputColumn: string,
}

class ShippingMethodDestinationsForm extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;

  constructor (props: Props) {
    super(props);

    const prefecturesAllChecked = this.props.shippingMethodDestinations.length == props.prefectures.length;

    const destinations: ShippingMethodDestination[] = props.prefectures.map(prefecture => {
      const dmd = this.props.shippingMethodDestinations.find(
        dmd => dmd.prefecture === prefecture[1]
      );

      if (dmd) {
        return({
          prefectureValue: prefecture[1],
          prefectureLabel: prefecture[0],
          price: dmd.price >= 0 ? dmd.price.toString() : '',
          isSupported: true,
          minShippingSpeedDays: dmd.minShippingSpeedDays ? dmd.minShippingSpeedDays.toString() : '', //maxと同じ値なのでminだけ使う
          maxShippingSpeedDays: dmd.maxShippingSpeedDays ? dmd.maxShippingSpeedDays.toString() : '',
          minShippingSpeedSlot: dmd.minShippingSpeedSlot,
          transition: dmd.transition,
        });
      } else {
        return({
          prefectureValue: prefecture[1],
          prefectureLabel: prefecture[0],
          price: '',
          isSupported: false,
          minShippingSpeedDays: '',
          maxShippingSpeedDays: '',
          minShippingSpeedSlot: props.defaultMinShippingSpeedSlot,
          transition: null,
        });
      }
    });

    this.state = {
      shippingMethodDestinations: destinations,
      prefecturesAllChecked: prefecturesAllChecked,
      bulkInputValue: '',
      bulkInputColumn: 'price',
    }

    //this.validate = this.validate.bind(this)
    this.form = React.createRef();
    this.handleStringInputChange = this.handleStringInputChange.bind(this);
    this.handleStringInputChangeTransition = this.handleStringInputChangeTransition.bind(this);
    this.handleSetDestinationSources = this.handleSetDestinationSources.bind(this);
    this.handleBulkInputValue = this.handleBulkInputValue.bind(this);
    this.timeString = this.timeString.bind(this);
  }

  handleIsSupportedChangeAll(e) {
    this.setState({
      prefecturesAllChecked: e.target.checked,
      shippingMethodDestinations: this.state.shippingMethodDestinations.map(dmd => {
        return({...dmd, isSupported: e.target.checked});
      })
    });
  }

  handleIsSupportedChange (
    prefectureValue: string,
    newValue: boolean
  ) {
    const {
      shippingMethodDestinations
    } = this.state;

    this.setState({
      shippingMethodDestinations: shippingMethodDestinations.map(dmd => {
        if(dmd.prefectureValue === prefectureValue) {
          return({...dmd, isSupported: newValue});
        } else {
          return(dmd);
        }
      })
    });
  }

  handleStringInputChange (
    prefectureValue: string,
    key: 'price'|'minShippingSpeedDays'|'maxShippingSpeedDays'|'minShippingSpeedSlot',
    newValue: string
  ) {
    const {
      shippingMethodDestinations
    } = this.state;

    this.setState({
      shippingMethodDestinations: shippingMethodDestinations.map(dmd => {
        if(dmd.prefectureValue === prefectureValue) {
          let newDmd = { ...dmd };
          newDmd[key] = newValue;
          return(newDmd);
        } else {
          return(dmd);
        }
      })
    });
  }

  handleStringInputChangeTransition (
    prefectureValue: string,
    key: 'sourcePrice'|'destinationPrice',
    newValue: string
  ) {
    const {
      shippingMethodDestinations
    } = this.state;

    this.setState({
      shippingMethodDestinations: shippingMethodDestinations.map(dmd => {
        if(dmd.prefectureValue === prefectureValue) {
          let newDmd = { ...dmd };
          newDmd.transition[key] = newValue;
          return(newDmd);
        } else {
          return(dmd);
        }
      })
    });
  }

  handleSetDestinationSources(size, destinationSources, isTransitional) {
    const isCool = ['chilled', 'frozen'].some(te => te === this.props.temperature);
    const {shippingMethodDestinations} = this.state;

    this.setState({
      shippingMethodDestinations: shippingMethodDestinations.map(smd => {
        let destinationSource = destinationSources.find(s => s.prefectureTo === smd.prefectureValue);
        if(isTransitional) {
          let newSmd = {...smd};
          // 選択地域の一括設定データがセットされてたらクリア
          newSmd.destinationPrice = '';
          if(isCool){
            newSmd.transition = new ShippingMethodDestinationTransition(destinationSource.freshPriceList ? destinationSource.freshPriceList[size].toString() : '');
          } else {
            newSmd.transition = new ShippingMethodDestinationTransition(destinationSource.groceryPriceList ? destinationSource.groceryPriceList[size].toString() : '');
          }
          smd = newSmd;
        } else {
          if(isCool){
            if(destinationSource.freshPriceList) {
              smd.price = destinationSource.freshPriceList[size].toString();
            } else {
              smd.price = ''
            }
            smd.minShippingSpeedDays = destinationSource.freshMinShippingSpeedDays.toString();
            smd.minShippingSpeedSlot = destinationSource.freshMinShippingSpeedSlot;
          } else {
            if(destinationSource.groceryPriceList) {
              smd.price = destinationSource.groceryPriceList[size].toString();
            } else {
              smd.price = ''
            }
            smd.minShippingSpeedDays = destinationSource.groceryMinShippingSpeedDays.toString();
            smd.minShippingSpeedSlot = destinationSource.groceryMinShippingSpeedSlot;
          }
        }
        return smd;
      })
    });

    return;
  }

  handleBulkInputValue() {
    const { shippingMethodDestinations, bulkInputValue, bulkInputColumn } = this.state;
    if (!bulkInputValue) return;
    const data = shippingMethodDestinations.map(smd => {
      if(!!smd.isSupported) {
        let newDmd = { ...smd };
        newDmd[bulkInputColumn] = bulkInputValue;
        return(newDmd);
      } else {
        return(smd);
      }
    });
    this.setState({ shippingMethodDestinations: data });
  }

  timeString(timeSource) {
    return timeSource.toString().replace('T', ' ').replace(/\-/g, '/').substr(0, 16);
  }

  render () {
    const {
      productType, submitPath, minShippingSpeedSlotOptions,
      prefectures, destinationSources, newlyDestinationSources, sourceSetTitle, temperature,
      transitionStartsAt, isTransitional, storeSlug
    } = this.props;

    const {
      shippingMethodDestinations, prefecturesAllChecked, bulkInputValue, bulkInputColumn
    } = this.state;

    const tableLines = shippingMethodDestinations.map(dmd => {
      const shippingSpeedDaysColumn = productType !== 'freshish' && <td>
        {productType === 'fresh' && <div className="input-group">
          <input type="number"
            value={dmd.minShippingSpeedDays}
            className="form-control"
            disabled={!dmd.isSupported}
            required={dmd.isSupported}
            onChange={e => this.handleStringInputChange(
              dmd.prefectureValue, 'minShippingSpeedDays', e.target.value
            )}
            min={1}
          />
          <span className="input-group-text">日の</span>
          <select className="form-control"
            value={dmd.minShippingSpeedSlot}
            disabled={!dmd.isSupported}
            required={dmd.isSupported}
            onChange={e => this.handleStringInputChange(
              dmd.prefectureValue, 'minShippingSpeedSlot', e.target.value
            )}
          > {minShippingSpeedSlotOptions.map(sssOption => <option
              key={sssOption[1]} value={sssOption[1]}
            >{sssOption[0]}</option>)}
          </select>
          <span className="input-group-text">以降</span>
        </div>}

        {productType === 'grocery' && <div className="input-group">
          <input type="number"
            value={dmd.minShippingSpeedDays}
            className="form-control"
            disabled={!dmd.isSupported}
            required={dmd.isSupported}
            onChange={e => this.handleStringInputChange(
              dmd.prefectureValue, 'minShippingSpeedDays', e.target.value
            )}
            min={1}
          />
          <span className="input-group-text">日の</span>
          <select className="form-control"
            value={dmd.minShippingSpeedSlot}
            disabled={!dmd.isSupported}
            required={dmd.isSupported}
            onChange={e => this.handleStringInputChange(
              dmd.prefectureValue, 'minShippingSpeedSlot', e.target.value
            )}
          > {minShippingSpeedSlotOptions.map(sssOption => <option
              key={sssOption[1]} value={sssOption[1]}
            >{sssOption[0]}</option>)}
          </select>
          <span className="input-group-text">〜</span>
          <input type="number"
            value={dmd.maxShippingSpeedDays}
            className="form-control"
            disabled={!dmd.isSupported}
            required={dmd.isSupported}
            onChange={e => this.handleStringInputChange(
              dmd.prefectureValue, 'maxShippingSpeedDays', e.target.value
            )}
            min={1}
          />
          <span className="input-group-text">日</span>
        </div>}
      </td>;

      return(
        <tr key={dmd.prefectureValue}>
          <th>
            <input className="form-check-input" type="checkbox"
              checked={dmd.isSupported}
              onChange={e => this.handleIsSupportedChange(
                dmd.prefectureValue, !dmd.isSupported
              )}
            />
          </th>
          <th>{dmd.prefectureLabel}</th>
          <td>
            <input type="number"
              value={dmd.price}
              className="form-control"
              disabled={!dmd.isSupported}
              required={dmd.isSupported}
              onChange={e => this.handleStringInputChange(
                dmd.prefectureValue, 'price', e.target.value
              )}
              min={0}
            />
          </td>
          {isTransitional &&
            <td>
              <input type="number"
                value={dmd.transition ? (dmd.destinationPrice || dmd.transition.destinationPrice) : ''}
                className="form-control"
                onChange={e => this.handleStringInputChangeTransition(
                  dmd.prefectureValue, 'destinationPrice', e.target.value
                )}
                min={0}
              />
            </td>
          }
          {shippingSpeedDaysColumn}
        </tr>
      );
    });

    let deliverySizeList = [];

    if(destinationSources.length) {
      deliverySizeList = Object.keys(['chilled', 'frozen'].some(te => te === temperature) ? destinationSources[0].freshPriceList : destinationSources[0].groceryPriceList);
    } else {
      deliverySizeList = [];
    }

    let bulkInputColumns = [['配送料', 'price']];
    if (isTransitional) {
      bulkInputColumns.push([`${this.timeString(transitionStartsAt)}からの配送料`, 'destinationPrice']);
    }
    bulkInputColumns.push(['配送日数(min)', 'minShippingSpeedDays'], ['時間帯', 'minShippingSpeedSlot'], ['配送日数(max)', 'maxShippingSpeedDays']);

    if (productType == 'fresh') bulkInputColumns.splice(2, 1);

    const isBulkInput = ['price', 'minShippingSpeedDays', 'maxShippingSpeedDays', 'destinationPrice'].includes(bulkInputColumn)
    return(
      <>
        {destinationSources.length > 0 &&
          <div className='container'>
            <div className='row'>
              <div className='alert alert-warning'>
                <h6>
                  <i className="fas fa-exclamation-circle"></i>
                  <b>配送会社公表の情報に基づいて一括設定</b>
                </h6>
                <p>{sourceSetTitle} - {prefectures.find(p => p[1] === destinationSources[0].prefectureFrom)[0]}より発送</p>
                <p>※配送方法に発送地が設定されていればそれを、なければ店舗の都道府県設定を使用します</p>

                <div className="container">
                  {deliverySizeList.map(size =>
                    <button key={size} className="btn btn-secondary btn-sm" onClick={() => {
                      this.handleSetDestinationSources(size, destinationSources, false);
                    }}>{size}サイズ</button>
                  )}
                </div>

                {isTransitional &&
                  <>
                    <hr />
                    <div className="container">
                      <div>
                        <i className="fas fa-exclamation-circle"></i>
                        <b>{this.timeString(transitionStartsAt)}注文分からの新送料に設定</b>
                      </div>
                      {deliverySizeList.map(size =>
                        <button key={size} className="btn btn-outline-secondary btn-sm" onClick={() => {
                          this.handleSetDestinationSources(size, newlyDestinationSources, true);
                        }}>{size}サイズ</button>
                      )}
                      <div style={{marginTop: '8px'}}>
                        ※お客様が注文する時刻が{this.timeString(transitionStartsAt)}を過ぎた時点からこちらの新料金が適用されます。
                        <button
                          className="btn btn-secondary btn-sm"
                          data-bs-toggle='modal'
                          data-bs-target='#update-transition-starts-at-modal'
                        >
                          適用時刻変更
                        </button>
                      </div>
                    </div>
                    <hr />
                  </>
                }

                <div>※登録ボタンを押すまでデータは更新されません。</div>
              </div>
            </div>

            <div className='row'>
              <div className='alert alert-warning'>
                <h6>
                  <i className="fas fa-exclamation-circle"></i>
                  <b>選択地域の一括設定</b>
                </h6>
                <div className='input-group'>
                  <select className="form-control"
                    onChange={(e) => this.setState({ bulkInputValue: (e.target.value == 'minShippingSpeedSlot' ? minShippingSpeedSlotOptions[0][1] : ''), bulkInputColumn: e.target.value })}
                  >
                    {bulkInputColumns.map(data => <option key={data[1]} value={data[1]}>{data[0]}</option>)}
                  </select>

                  <input type="number" className="form-control"
                    value={bulkInputValue}
                    onChange={(e) => this.setState({ bulkInputValue: e.target.value })}
                    style={isBulkInput ? {} : { display: 'none' }}
                  />

                  <select className="form-control"
                    value={bulkInputValue}
                    onChange={(e) => this.setState({ bulkInputValue: e.target.value })}
                    style={!isBulkInput ? {} : { display: 'none' }}
                  > {minShippingSpeedSlotOptions.map(sssOption => <option
                      key={sssOption[1]} value={sssOption[1]}
                    >{sssOption[0]}</option>)}
                  </select>
                  <button className="btn btn-secondary btn-sm"
                    disabled={(bulkInputColumn != 'minShippingSpeedSlot' && (!bulkInputValue || bulkInputValue < 0)) || (bulkInputColumn == 'minShippingSpeedSlot' && !bulkInputValue)}
                    onClick={() => this.handleBulkInputValue()}
                  >
                    {bulkInputColumn == 'minShippingSpeedSlot' ? '一括選択' : '一括入力'}
                  </button>

                </div>
                <div>※登録ボタンを押すまでデータは更新されません。</div>
              </div>
            </div>
          </div>
        }

        <form method="post" action={submitPath}>
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <input type="hidden" name="_method" value="patch" />

          <table className="table">
            <thead>
              <tr>
                <th>
                  <input className="form-check-input" type="checkbox"
                    checked={prefecturesAllChecked}
                    onChange={e => this.handleIsSupportedChangeAll(e)}
                  />
                  <span className="ms-2">対応</span>
                </th>
                <th>都道府県</th>
                <th>配送料</th>
                {isTransitional &&
                  <th>{this.timeString(transitionStartsAt)}からの配送料</th>
                }
                {productType !== 'freshish' && <th>配送日数</th>}
              </tr>
            </thead>
            <tbody>
              {tableLines}
            </tbody>
          </table>

          {shippingMethodDestinations.filter(dmd => dmd.isSupported).map(dmd => {
            const keyPrefix = `shipping_method_destinations[${dmd.prefectureValue}]`;
            return(<div key={dmd.prefectureValue}>
              <input type="hidden" name={`${keyPrefix}[destinations][price]`} value={dmd.price} />
              {isTransitional &&
                <input type="hidden" name={`${keyPrefix}[transition][destination_price]`} value={dmd.transition ? dmd.transition.destinationPrice : ''} />
              }
              <input type="hidden" name={`${keyPrefix}[destinations][prefecture]`} value={dmd.prefectureValue} />
              {productType === 'fresh' && <>
                <input type="hidden" name={`${keyPrefix}[destinations][min_shipping_speed_days]`} value={dmd.minShippingSpeedDays} />
                <input type="hidden" name={`${keyPrefix}[destinations][max_shipping_speed_days]`} value={dmd.minShippingSpeedDays} />
              </>}
              {productType === 'grocery' && <>
                <input type="hidden" name={`${keyPrefix}[destinations][min_shipping_speed_days]`} value={dmd.minShippingSpeedDays} />
                <input type="hidden" name={`${keyPrefix}[destinations][max_shipping_speed_days]`} value={dmd.maxShippingSpeedDays} />
              </>}
              <input type="hidden" name={`${keyPrefix}[destinations][min_shipping_speed_slot]`} value={dmd.minShippingSpeedSlot} />
            </div>);
          })}

          <button className="btn btn-lg btn-primary" onClick={() => {
            this.form.current.submit();
          }}>登録</button>
        </form>
      </>
    );
  }
}

export default ShippingMethodDestinationsForm;

type ProductPreferenceQuestion = {
  id: number,
  question: string,
  questionType: 'singleline'|'select'
  isRequired: boolean,
  parentOptionId: null|number,
  parentQuestionId: null|number,
  options: {
    id: number,
    optionText: string,
    isDefault: boolean,
    isParent: boolean,
  }[],
};

type ProductPreferenceAnswer = {
  productPreferenceQuestionId: number,
  productPreferenceOptionIds: number[],
  text: string,
  isChild: boolean,
  parentQuestionId: number | null,
};

type ProductPreference = ProductPreferenceAnswer[];

// CartItemごとにグルーピング
type ProductPreferenceGroup = {
  cartItemUid: string,
  preferences: ProductPreference[]
};

const isProductPreferenceAnswerEmpty = (
  ppa: ProductPreferenceAnswer, ppq: ProductPreferenceQuestion
): boolean => {
  switch(ppq.questionType){
  case 'singleline':
    return ppa.text.length === 0;
  case 'select':
    return ppa.productPreferenceOptionIds.length === 0;
  }
}

const isRequiredProductPreferenceAnswerEmpty = (
  ppa: ProductPreferenceAnswer, ppq: ProductPreferenceQuestion
): boolean => {
  return ppq.isRequired && isProductPreferenceAnswerEmpty(ppa, ppq);
}

const buildProductPreferenceAnswer = (
  ppq: ProductPreferenceQuestion
): ProductPreferenceAnswer => {
  let productPreferenceOptionIds = [];
  if (ppq.questionType === 'select') {
    productPreferenceOptionIds = ppq.options.filter(ppqo => ppqo.isDefault).map(ppqo => ppqo.id);
  }

  return {
    productPreferenceQuestionId: ppq.id,
    productPreferenceOptionIds: productPreferenceOptionIds,
    text: '',
    isChild: !!ppq.parentOptionId,
    parentQuestionId: ppq?.parentQuestionId || null,
  }
}

const filterChildQuestions = (
  ppa: ProductPreferenceAnswer, ppqList: ProductPreferenceQuestion[]
): ProductPreferenceQuestion[] => {
  return ppqList.filter(
    ppq => ppa.productPreferenceOptionIds.includes(ppq.parentOptionId)
  );
}

export type {
  ProductPreference, ProductPreferenceGroup,
  ProductPreferenceQuestion, ProductPreferenceAnswer
};
export {
  isProductPreferenceAnswerEmpty, isRequiredProductPreferenceAnswerEmpty,
  buildProductPreferenceAnswer, filterChildQuestions
};
import * as React from 'react';

type Props = {
  productVariantUid: string,
  defaultStocks: string,
  defaultIsStockInfinite: boolean,
  isStockInfiniteLabel: string,
};

type State = {
  stocks: string,
  isStockInfinite: boolean,
  stocksInCart: number,
};

class ProductVariantStockFields extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      stocks: props.defaultStocks,
      isStockInfinite: props.defaultIsStockInfinite,
    };
  }

  render () {
    const { productVariantUid, defaultStocks, isStockInfiniteLabel, stocksInCart } = this.props;
    const { stocks, isStockInfinite } = this.state;
    const isInfiniteFieldId = `product_variant_${productVariantUid}_is_stock_infinite`;

    return(<>
      <div className="form-group">
        <label>残り在庫</label>
        <input type="number"
          required
          min={stocksInCart}
          className="form-control"
          value={stocks}
          onChange={e => this.setState({ stocks: e.target.value })}
          disabled={isStockInfinite}
        />
      </div>
      <div className="form-check mt-2">
        <input type="checkbox"
          className="form-check-input"
          id={isInfiniteFieldId}
          checked={isStockInfinite}
          onChange={() => {
            const newIsStockInfinite = !isStockInfinite;
            if (newIsStockInfinite) {
              this.setState({ stocks: '0', isStockInfinite: newIsStockInfinite });
            } else {
              this.setState({ isStockInfinite: newIsStockInfinite });
            }
          }}
        />
        <label className="form-check-label" htmlFor={isInfiniteFieldId}>
          {isStockInfiniteLabel}
        </label>
      </div>

      <input type="hidden" name="product_variant[stocks]" value={stocks} min='0' />
      <input type="hidden" name="product_variant[is_stock_infinite]" value={isStockInfinite ? '1' : '0'} />
      <input type="hidden" name="stocks_was" value={defaultStocks} />
    </>);
  }
};

export default ProductVariantStockFields;

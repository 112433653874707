import * as React from 'react';
import Rails from 'rails-ujs';
import { Prefectures } from '../types/Prefecture';
import { DateTime } from "luxon";

type Props = {
  productType: string,
  prefectures: Prefectures,
  productVariant: {
    uid: string,
    title: string,
    description: string,
    unitPrice: number,
    availableStocks: number,
    stocksInCart: number,
    isStockInfinite: boolean,
    imagePath: string,
    isFavorited: boolean,
    shippableDates: {
      date: string,
      availableStocks: number
      stocks: number,
      stocksInCart: number,
    }[]
  },
  shippingMethod: {
    destinations: {
      prefectureValue: string,
      price: number,
      minShippingSpeedDays: number,
      maxShippingSpeedDays: number, // minShippingSpeedDaysとおなじ
    }[]
  },
  defaultPrefectureValue: string,
  isStoreOrderable: boolean,
  isProductOrderable: boolean,
  isProductListable: boolean,
  isPriorityOrderable: boolean,
  isPremiumGreenUser: boolean,
  productUnshippablePrefectureValues: string[],
};

type State = {
  prefectureValue: string|null,
  shippingDate: string|null,
  showShippingMethodOptions: boolean,
  showPrefectureOptions: boolean,
  isLoading: boolean,
  hasTriedToCart: string,
}

class AddCartFreshForm extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);

    const isDefaultPrefectureShippable = props.shippingMethod.destinations.some(
      d => d.prefectureValue === props.defaultPrefectureValue
    ) && !props.productUnshippablePrefectureValues.includes(props.defaultPrefectureValue);

    this.state = {
      prefectureValue: isDefaultPrefectureShippable ? props.defaultPrefectureValue : '',
      shippingDate: null,
      showShippingMethodOptions: false,
      showPrefectureOptions: !isDefaultPrefectureShippable,
      isLoading: false,
      hasTriedToCart: false,
    }

    this.form = React.createRef();
  }

  render() {
    const {
      productType, prefectures, productVariant, shippingMethod, isStoreOrderable, isProductOrderable, isProductListable,
      productUnshippablePrefectureValues, isPriorityOrderable, isPremiumGreenUser,
    } = this.props;

    const {
      prefectureValue, shippingDate, showPrefectureOptions, isLoading, hasTriedToCart
    } = this.state;

    const selectablePrefectures = prefectures.filter(
      prefecture => shippingMethod.destinations.some(d => d.prefectureValue === prefecture[1])
    ).filter(
      prefecture => !productUnshippablePrefectureValues.includes(prefecture[1])
    );
    const prefecture = selectablePrefectures.find(prefecture => prefecture[1] === prefectureValue);

    const shippingMethodDestination = !!prefecture ? shippingMethod.destinations.find(
      d => d.prefectureValue === prefecture[1]
    ) : null;
    const submitPath = `/product_variants/${productVariant.uid}/cart_items`;

    let hasStocks = false
    let hasStocksInCart = false;
    // 日別在庫ありの日付取得
    const selectableShippableDates = productVariant.shippableDates.filter((sd) => {
      return sd.availableStocks > 0;
    });
    const hasShippableDates = selectableShippableDates.length > 0;
    let convertedStocksCount = '';

    // 日別在庫のみ
    if (productVariant.isStockInfinite) {
      let stockCount = 0;
      productVariant.shippableDates.forEach((sd) => { return stockCount += sd.stocks; });
      // 在庫ありの日付がある場合
      if (hasShippableDates) {
        hasStocks = true;
        if (productVariant.availableStocks <= 0 && stockCount < 10) {
          convertedStocksCount = `（残り${stockCount}）`;
        }
      } else {
        hasStocksInCart = productVariant.stocksInCart > 0;
        if (!hasStocksInCart) {
          convertedStocksCount = !productVariant.title ? '在庫なし' : '（在庫なし）';
        } else if (productVariant.availableStocks <= 0 && stockCount < 10) {
          convertedStocksCount = `（残り${stockCount}）`;
        }
      }
    }
    // 全体を通した在庫あり
    else {
      let stockCount = 0;
      productVariant.shippableDates.forEach((sd) => { return stockCount += sd.stocks; });
      if (productVariant.availableStocks > 0 && hasShippableDates) {
        hasStocks = true;
        if (stockCount < 10) {
          convertedStocksCount = `（残り${stockCount}）`;
        }
      } else {
        hasStocksInCart = productVariant.stocksInCart > 0;
        if (productVariant.availableStocks == 0 && !hasStocksInCart) {
          convertedStocksCount = !productVariant.title ? '在庫なし' : '（在庫なし）';
        } else if (stockCount < 10 || variant.stocks < 10) {
          convertedStocksCount = `（残り${stockCount}）`;
        }
      }
    }

    const isOrderable = isStoreOrderable && isProductOrderable;
    let isCautionOfPrefecture, isCautionOfShippingDate = false;
    if (hasTriedToCart) {
      if (!prefectureValue) isCautionOfPrefecture = true;
      if (!shippingDate) isCautionOfShippingDate = true;
    }

    let addCartButton = '';
    const isPriorityOrderingAvailable = (isPriorityOrderable && !isPremiumGreenUser) ? false : true;
    if (isOrderable) {
      if (hasStocks && isPriorityOrderingAvailable) {
        addCartButton = <button
          className="btn btn-primary"
          type="button"
          disabled={isLoading}
          onClick={e => {
            if (!shippingDate || !prefectureValue) {
              this.setState({ hasTriedToCart: true });
            } else {
              e.preventDefault();
              this.setState({isLoading: true});
              this.form.current.submit();
            }
          }}
        >
          {isLoading ? <div>
            <span className='spinner-border spinner-border-sm mx-3'></span>
          </div> : <div>
            <i className="fas fa-shopping-cart"></i>
            <span className='my-3 ms-1'>カートに追加</span>
          </div>}
        </button>;
      } else {
        let btnMsg = hasStocksInCart ? "他のお客様がカートに入れています" : "在庫がありません";
        if (!isPriorityOrderingAvailable) {
          btnMsg = 'グリーン会員優先販売期間'
        }
        addCartButton = <button
          type="button"
          className="btn btn-primary disabled"
          disabled={true}
        >
          <i className="fas fa-shopping-cart"></i>
          {btnMsg}
        </button>;
      }
    } else {
      addCartButton = <button
        type="button"
        className="btn btn-primary disabled"
        disabled={true}
      >
        <i className="fas fa-shopping-cart"></i>
        販売開始をお待ち下さい
      </button>;
    }

    const caution_text = productType === 'freshish' ? '発送予定日を選択してください' : '到着予定日を選択してください';

    return (
      <>
        <form method="post" acceptCharset="UTF-8" action={submitPath} ref={this.form}>
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <div className='p-productVariant_addCart_item'>
            {productVariant.imagePath && (
              <div className="p-productVariant_addCart_img">
                <div style={{
                  backgroundImage: `url(${productVariant.imagePath})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}></div>
              </div>
            )}

            <div className='p-productVariant_addCart_content'>

              <div className='p-productVariant_addCart_info'>

                <div className='p-productVariant_addCart_details'>
                  <div className='p-productVariant_addCart_title'>
                    {productVariant.title}
                    {convertedStocksCount}
                  </div>
                  <div className='p-productVariant_addCart_varDesc'>{productVariant.description &&
                    productVariant.description.split(/\r\n|\r|\n/g).map((row, idx) => {
                      return [row, <br key={idx} />];
                    })
                  }</div>
                  <div className='p-productVariant_addCart_price'>
                    <div>{productVariant.unitPrice.toLocaleString()} 円</div>
                    <small>(税込)</small>
                  </div>
                </div>

                <div className='p-productVariant_addCart_delivery'>
                  <div>お届け先</div>
                  <div className="d-flex justify-content-between mb-1">
                    {!showPrefectureOptions && <>
                      <div><strong>{prefecture[0]}</strong>に配送の場合</div>
                      <a href="" onClick={e => {
                        e.preventDefault();
                        this.setState({ showShippingMethodOptions: true, showPrefectureOptions: true });
                      }}>変更</a>
                    </>}
                    {showPrefectureOptions && <>
                      <select value={prefectureValue ? prefectureValue : ''} className={ isCautionOfPrefecture ? 'form-select border border-danger' : 'form-select' }
                        onChange={ e => this.setState({ prefectureValue: e.target.value, shippingDate: null, hasTriedToCart: true }) }
                      >
                        <option key="" value="">都道府県を選択してください</option>
                        {selectablePrefectures.map(prefecture => <option key={prefecture[1]} value={prefecture[1]}>
                          {prefecture[0]}
                        </option>)}
                      </select>
                    </>}
                  </div>
                  {showPrefectureOptions && isCautionOfPrefecture && <div className="form text-danger">都道府県を選択してください</div>}
                  {isOrderable && hasStocks && !!shippingMethodDestination && isPriorityOrderingAvailable && <div>
                    <select value={shippingDate ? shippingDate : ''} className={ isCautionOfShippingDate ? 'form-select border border-danger' : 'form-select' }
                      name="shipping_date"
                      onChange={e => this.setState({ shippingDate: e.target.value }) }
                    >
                      <option key="" value="">{caution_text}</option>
                      {selectableShippableDates.map(sd => {
                        const shippingDate = DateTime.fromISO(sd.date);
                        const deliveryDate = productType == 'freshish' ? shippingDate : shippingDate.plus({ days: shippingMethodDestination.minShippingSpeedDays });
                        return (
                          <option key={sd.date} value={sd.date}>
                            {deliveryDate.setLocale('ja').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}{productType == 'freshish' ? '発送予定' : '到着'}
                          </option>
                        );
                      })}
                    </select>
                    {isCautionOfShippingDate && <div className="form-content text-danger">{caution_text}</div>}
                  </div>}
                </div>

              </div>

              <div className='p-productVariant_addCart_actions'>
                {addCartButton}
                {isProductListable && <div className='p-product_order_favorite'>
                  {productVariant.isFavorited ?
                    <a href={`/my/favorite_product_variants/${productVariant.uid}`} className="added" data-method="delete" rel="nofollow"><i className="fas fa-heart"></i></a> :
                    <a href={`/my/favorite_product_variants/add?uid=${productVariant.uid}`} className="yet"><i className="far fa-heart"></i></a>
                  }
                </div>}
              </div>

            </div>
          </div>
        </form>
      </>
    );
  }
}

export default AddCartFreshForm;

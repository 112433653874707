import * as React from 'react';
import Rails from 'rails-ujs';

type Props = {
  tel: string,
  setTel: (value: string) => void,
  isTelInvalid: boolean,
  holdAddressCache: (value: string) => void,
  addressCacheType: string,
};

class TelField extends React.Component<Props> {
  render () {
    const {
      tel, setTel, isTelInvalid,
      holdAddressCache, addressCacheType
    } = this.props

    return(
      <div className="form-group row">
        <div className="col-sm-6">
          <label>電話番号<span className="c-form_rq">必須</span></label>
          <input type="tel"
            value={tel}
            onChange={e => {setTel(e.target.value.replace(/[^0-9\+]/g, ''))}}
            onBlur={e => holdAddressCache(addressCacheType)}
            placeholder="09012345678"
            className={"form-control" + (isTelInvalid ? ' is-invalid' : '')}
          />
          <p className="c-form-caution">半角数字のみで入力してください。</p>
        </div>
      </div>
    )
  }
}

export default TelField;

import * as React from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { RegisteredImage, UploadedImageData } from "./ImageUpdater";

interface Props {
  file: File | RegisteredImage
  files: (File | RegisteredImage)[]
  index: number
  image: UploadedImageData
  uploadedImages: UploadedImageData[]
  isSingleMode?: boolean
  isEditable: boolean
  isEdited: boolean
  setFiles: (files: (File | RegisteredImage)[]) => void
  setUploadedImages: (uploadedImages: UploadedImageData[]) => void
  setErrorMessage: (errorMessage: string | null) => void
  setIsReset: (isReset: boolean) => void
  setIsEdited: (isEdited: boolean) => void
  onSetThumbnail: () => void
  onChangeImage: () => void
  onDelete: () => void
}

interface DragItem {
  uploadedImages: UploadedImageData[]
  files: (File | RegisteredImage)[]
  image: UploadedImageData
  file: File | RegisteredImage
  index: number
}

const draggableType = 'IMAGE'

const UploadedImage: React.FC<Props> = ({
  file,
  files,
  index,
  image,
  uploadedImages,
  isSingleMode = false,
  isEditable,
  isEdited,
  setUploadedImages,
  setFiles,
  setErrorMessage,
  setIsReset,
  setIsEdited,
  onSetThumbnail,
  onChangeImage,
  onDelete
}) => {
  const [progress, setProgress] = React.useState<number>(0)
  const [uploadedImage, setUploadedImage] = React.useState<UploadedImageData>(null)
  const [isUploaded, setIsUploaded] = React.useState<boolean>(false)
  const [isOptionMenuOpened, setIsOptionMenuOpened] = React.useState<boolean>(false)

  const imageRef: React.Ref<HTMLDivElement> = React.useRef<HTMLDivElement>(null)
  const optionRef: React.Ref<HTMLUListElement> = React.useRef<HTMLUListElement>(null)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: draggableType,
    item: () => {
      setIsEdited(true)
      return { uploadedImages, files, image, file, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () =>isEditable || !isSingleMode,
  }), [uploadedImage, files, uploadedImages])

  React.useEffect(() => {
    setIsUploaded(false)
  }, [file])

  const [, drop] = useDrop(() => ({
    accept: draggableType,
    hover: (item: DragItem) => {
      const dragImage = item.image
      const dragFile = item.file
      const dragIndex = item.index

      const dropIndex = index

      if (dragIndex === dropIndex) return
      let clonedUploadedImages = [...item.uploadedImages]
      let clonedFiles = [...item.files]

      clonedUploadedImages.splice(dragIndex, 1)
      clonedUploadedImages.splice(dropIndex, 0, dragImage)
      clonedFiles.splice(dragIndex, 1)
      clonedFiles.splice(dropIndex, 0, dragFile)

      setUploadedImages(clonedUploadedImages)
      setFiles(clonedFiles)
      item.index = dropIndex
      item.files = clonedFiles
      item.uploadedImages = clonedUploadedImages
    },
  }), [image])

  drag(drop(imageRef))

  React.useEffect(() => {
    if (!isUploaded) return
    setUploadedImage(uploadedImages[index])
  }, [uploadedImages])

  React.useEffect(() => {
    if (isUploaded || !uploadedImage) return
    const clonedUploadedImages = [...uploadedImages]
    clonedUploadedImages[index] = uploadedImage

    setIsUploaded(true)
    setUploadedImages(clonedUploadedImages)
  }, [isUploaded, uploadedImage])

  React.useEffect(() => {
    if ('image' in file) {
      setIsUploaded(true)
      return
    }

    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target.result as string
      const img = new Image()
      img.src = result

      img.onload = () => {
        const loadedUploadedImage = {
          file,
          src: result,
          isThumb: false,
        }
        if (isSingleMode) {
          setUploadedImages([loadedUploadedImage])
        } else {
          setUploadedImage(loadedUploadedImage)
        }
      }
    }

    reader.onprogress = (e: ProgressEvent<FileReader>) => {
      if (e.lengthComputable) {
        const imgProgress = (e.loaded / e.total) * 100
        setProgress(imgProgress)
      }
    }

    reader.onerror = () => {
      setErrorMessage('画像の読み込みに失敗しました')
      setIsReset(true)
    }

    reader.readAsDataURL(file)
  }, [file, isSingleMode])

  React.useEffect(() => {
    if (!isEditable || !isOptionMenuOpened) return
    optionRef.current.focus()
  }, [isOptionMenuOpened])

  const handleBlur = React.useCallback(() => {
    setIsOptionMenuOpened(false)
  }, [])

  const handleClickSetThumbnail = React.useCallback(() => {
    setIsEdited(true)

    onSetThumbnail()
    setIsOptionMenuOpened(false)
  }, [image, onSetThumbnail])

  const handleClickDelete = React.useCallback(() => {
    setIsEdited(true)

    onDelete()
    setIsOptionMenuOpened(false)
  }, [onDelete])

  const optionMenu = React.useMemo(() => {
    if (!isEditable || !isOptionMenuOpened) return

    if (isSingleMode) {
      return (
        <ul
          ref={optionRef}
          className="c-imageUpdater_optionMenu"
          tabIndex={0}
          onBlur={handleBlur}
        >
          <li onClick={handleClickDelete}>削除する</li>
          <li onClick={onChangeImage}>他の画像をアップ</li>
        </ul>
      )
    }

    return (
      <ul
        ref={optionRef}
        className="c-imageUpdater_optionMenu"
        tabIndex={0}
        onBlur={handleBlur}
      >
        <li onClick={handleClickSetThumbnail}>サムネイルに設定</li>
        <li onClick={handleClickDelete}>削除する</li>
      </ul>
    )
  }, [isOptionMenuOpened, isSingleMode, onDelete])

  const renderImage = React.useMemo(() => {
    if (!image) {
      return (
        <div className={`c-imageUpdater_uploadedImage ${isSingleMode && 'single'} loading`}>
          <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${progress}%` }} />
          </div>
        </div>
      )
    }

    return (
      <div ref={imageRef} className={`c-imageUpdater_uploadedImage ${isSingleMode && 'single'} ${isDragging ? 'dragging' : ''}`}>
        <img src={image.src} />
        {isEditable && <div
          className="c-imageUpdater_optionMenuButton"
          onClick={() => setIsOptionMenuOpened(true)}
        >
          <span />
        </div>}
        {image.isThumb && (
          <span className="badge bg-light text-dark c-imageUpdater_labelThumbnail">サムネイル</span>
        )}
        {optionMenu}
      </div>
    )
  }, [image?.isThumb, image?.src, progress, optionMenu, isDragging, isSingleMode])

  return renderImage
}

export default UploadedImage

import * as React from 'react';
import axios from 'axios';
import Rails from 'rails-ujs';
import { DateTime } from "luxon";

type DefaultProductVariantShippableDate = {
  dateValue: string,
  dateLabel: string,
  stocks: string,
  stocksWas: string,
  isPersisted: boolean,
};

type ProductVariantShippableDate = DefaultProductVariantShippableDate & {
  hasError: boolean,
  hasChange: boolean,
  hasNewUpdates: boolean,
  isSaving: boolean,
};

type Props = {
  productVariantUid: string,
  defaultProductVariantShippableDates: DefaultProductVariantShippableDate[],
  closedDates: string[]
};

type State = {
  productVariantShippableDates: ProductVariantShippableDate[],
  batchStocksValue: string,
  isSaving: boolean,
};

class ProductVariantShippableDatesForm extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;

  constructor (props: Props) {
    super(props);

    this.state = {
      productVariantShippableDates: props.defaultProductVariantShippableDates.map(pvsd => {
        return { ...pvsd, hasError: false, hasChange: false, hasNewUpdates: false, isSaving: false };
      }),
      batchStocksValue: '',
      isSaving: false
    };

    this.setPvsdState = this.setPvsdState.bind(this);
    this.savePvsd = this.savePvsd.bind(this);
    this.postPvsd = this.postPvsd.bind(this);
    this.patchPvsd = this.patchPvsd.bind(this);
    this.batchSavePvsd = this.batchSavePvsd.bind(this);
    this.batchApplyStocksValue = this.batchApplyStocksValue.bind(this);
  }

  setPvsdState(dateValue: string, newValues: Partial<ProductVariantShippableDate>) {
    const { productVariantShippableDates } = this.state;
    const newProductVariantShippableDates = productVariantShippableDates.map(pvsd => {
      if (pvsd.dateValue === dateValue) {
        return({ ...pvsd, ...newValues });
      } else {
        return(pvsd);
      }
    });
    this.setState({ productVariantShippableDates: newProductVariantShippableDates });
  }

  // NOTE Make sure to call this function AFTER the state is updated.
  // i.e. this.setState({ ... }, this.savePvsd(dateValue))
  async savePvsd(dateValue: string) {
    const { productVariantShippableDates } = this.state;
    const productVariantShippableDate = productVariantShippableDates.find(
      pvsd => pvsd.dateValue === dateValue
    );

    let isSuccess;
    if (productVariantShippableDate.isPersisted) {
      isSuccess = await this.patchPvsd(productVariantShippableDate);
    } else {
      isSuccess = await this.postPvsd(productVariantShippableDate);
    }

    if (isSuccess) {
      setTimeout(() => {
        this.setPvsdState(dateValue, { hasNewUpdates: false });
      }, 500);
    } else {
      this.setPvsdState(dateValue, { hasError: true });
    }
  }

  async postPvsd(productVariantShippableDate: ProductVariantShippableDate): Promise<boolean> {
    const { productVariantUid } = this.props;
    const url = `/owner/product_variants/${productVariantUid}/shippable_dates.json`;

    try {
      const response = await axios.post(
        url, {
          date: productVariantShippableDate.dateValue,
          product_variant_shippable_date: {
            stocks: productVariantShippableDate.stocks
          },
          authenticity_token: Rails.csrfToken()
        }
      );
      if (response.status === 201) {
        this.setPvsdState(productVariantShippableDate.dateValue, {
          stocks: response.data.stocks, stocksWas: response.data.stocks,
          hasNewUpdates: true, isSaving: false, hasChange: false, isPersisted: true,
        });
        return(true);
      }
      return(false);

    } catch (error) {
      console.error(error);
      return(false);
    }
  }

  async patchPvsd(productVariantShippableDate: ProductVariantShippableDate): Promise<boolean> {
    const { productVariantUid } = this.props;
    const dv = productVariantShippableDate.dateValue;
    const url = `/owner/product_variants/${productVariantUid}/shippable_dates/${dv}.json`;

    try {
      const response = await axios.patch(
        url, {
          date: productVariantShippableDate.dateValue,
          stocks_was: productVariantShippableDate.stocksWas,
          product_variant_shippable_date: {
            stocks: productVariantShippableDate.stocks
          },
          authenticity_token: Rails.csrfToken()
        }
      );
      if (response.status === 200) {
        this.setPvsdState(productVariantShippableDate.dateValue, {
          stocks: response.data.stocks, stocksWas: response.data.stocks,
          hasNewUpdates: true, isSaving: false, hasChange: false, isPersisted: true,
        });
        return(true);
      }
      return(false);

    } catch (error) {
      console.error(error);
      return(false);
    }
  }

  batchSavePvsd() {
    const { productVariantShippableDates } = this.state;
    this.setState({isSaving: true});

    const changedPvsdList = productVariantShippableDates.filter(pvsd => pvsd.hasChange);
    const changedPvsdDateValues = changedPvsdList.map(pvsd => pvsd.dateValue);
    const newProductVariantShippableDates = productVariantShippableDates.map(pvsd => {
      if (changedPvsdDateValues.includes(pvsd.dateValue)) {
        return({ ...pvsd, isSaving: true });
      } else {
        return(pvsd);
      }
    });

    this.setState({
      productVariantShippableDates: newProductVariantShippableDates
    }, async () => {
      const savePvsdPromises = changedPvsdList.map(
        pvsd => this.savePvsd(pvsd.dateValue)
      );
      await Promise.all(savePvsdPromises);
      this.setState({isSaving: false});
    })
  }

  batchApplyStocksValue() {
    const { productVariantShippableDates, batchStocksValue } = this.state;
    const newProductVariantShippableDates = productVariantShippableDates.map(pvsd => {
      return ({ ...pvsd, stocks: batchStocksValue, hasChange: true });
    });
    this.setState({
      productVariantShippableDates: newProductVariantShippableDates,
      batchStocksValue: '',
    });
  }

  render () {
    const { closedDates } = this.props
    const { productVariantShippableDates, batchStocksValue, isSaving } = this.state;
    const hasAnyError = productVariantShippableDates.some(pvsd => pvsd.hasError);

    const pvsdTableLines = productVariantShippableDates.map(pvsd => {
      let fieldClass = "form-control";
      if (pvsd.hasError) {
        fieldClass += " is-invalid";
      } else if (pvsd.hasNewUpdates) {
        fieldClass += " is-valid";
      }

      return (
        <tr key={pvsd.dateValue}>
          <td className="align-middle">
            <input type="checkbox" checked={pvsd.hasChange}
              onChange={e => this.setPvsdState(pvsd.dateValue, { hasChange: !pvsd.hasChange })}
            />
          </td>
          <td>
            <form>
              <div className="input-group">
                <label className={['input-group-text', closedDates.includes(pvsd.dateValue) ? 'holiday' : ''].join(' ')}>
                  {pvsd.dateLabel}発送
                  {closedDates.includes(pvsd.dateValue) && <span className="badge rounded-pill bg-danger">休業日</span>}
                </label>
                <input type="number" value={pvsd.stocks} className={fieldClass}
                  readOnly={pvsd.isSaving}
                  onChange={e => {
                    if (validateStocksValue(e.target.value)) {
                      this.setPvsdState(pvsd.dateValue, { stocks: e.target.value, hasChange: true });
                    } else {
                      this.setPvsdState(pvsd.dateValue, { stocks: e.target.value, hasChange: false });
                    }
                  }}
                />
                <button className="btn btn-outline-primary"
                  disabled={pvsd.isSaving || !pvsd.hasChange}
                  onClick={() => {
                    this.setPvsdState(pvsd.dateValue, { isSaving: true });
                    this.savePvsd(pvsd.dateValue);
                  }}
                >更新</button>
              </div>
              {pvsd.hasError && <span>
                在庫数を更新できませんでした。他の操作により、在庫数が更新された可能性があります。
                ページを更新して、再度操作をやり直してください。
              </span>}
            </form>
          </td>
        </tr>
      );
    });

    return(<>
      <div className="row">
        <div className="col">
          <button
            className="btn btn-outline-primary"
            disabled={isSaving || !productVariantShippableDates.some(pvsd => pvsd.hasChange)}
            onClick={this.batchSavePvsd}
          > まとめて更新</button>
        </div>
        <div className="col">
          <div className="input-group">
            <label className="input-group-text">全日在庫一括設定</label>
            <input type="number" value={batchStocksValue} className="form-control"
              onChange={e => this.setState({ batchStocksValue: e.target.value })}
            />
            <button
              className="btn btn-outline-primary"
              disabled={!validateStocksValue(batchStocksValue)}
              onClick={this.batchApplyStocksValue}
            > 実行</button>
          </div>
        </div>
      </div>
      <hr />

      {hasAnyError && <div className="alert alert-danger">
        在庫の更新に失敗した日付があります。ご確認ください。
      </div>}

      <table className="table">
        <tbody>
          {pvsdTableLines}
        </tbody>
      </table>
    </>);
  }
}

function validateStocksValue (value: string): boolean {
  return(value.toString().search(/^[0-9]+$/) === 0);
}

export default ProductVariantShippableDatesForm;

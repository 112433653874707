import * as React from "react";
import Rails from 'rails-ujs';
import axios from 'axios';

const EditStripeAccountForm: React.VFC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const handleClick = React.useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await axios.post(
        '/owner/account/edit_stripe_account',
        {},
        {
          headers: { 'X-CSRF-Token': Rails.csrfToken() }
        }
      )

      if (response.status === 200) {
        setIsLoading(false)
        window.open(response.data.stripe_login_link, '_blank', 'noreferrer')
      }
    } catch (_e) {
      location.reload()
    }
  }, [])

  return (
    <div>
      <button type="button" className="btn btn-primary" disabled={isLoading} onClick={handleClick}>
        Stripe管理画面にログイン
      </button>
    </div>
  )
}

export default EditStripeAccountForm

import * as React from 'react';
import Rails from 'rails-ujs';
import { Prefectures } from './types/Prefecture';
import { DateTime } from "luxon";
import AddCartFreshForm from './product_variants/AddCartFreshForm';

type Props = {
  productType: string,
  shippingMethod: {
    destinations: {
      prefectureValue: string,
      price: number,
      minShippingSpeedDays: number,
      maxShippingSpeedDays: number, // minShippingSpeedDaysとおなじ
    }[]
  },
  prefectures: Prefectures,
  productVariants: {
    uid: string,
    title: string,
    description: string,
    unitPrice: number,
    availableStocks: number,
    isStockInfinite: boolean,
    imagePath: string,
    isFavorited: boolean,
    shippableDates: {
      date: string,
      availableStocks: number,
      stocks: number,
      stocksInCart: number,
    }[]
  }[],
  defaultShippingMethodUid: string,
  defaultPrefectureValue: string,
  containsAlcohol: boolean,
  isStoreOrderable: boolean,
  isProductOrderable: boolean,
  isProductListable: boolean,
  isPriorityOrderable: boolean,
  isPremiumGreenUser: boolean,
  productUnshippablePrefectureValues: string[],
  alcoholAlertContent: string,
  priorityOrderableAlertContent: string,
};

class AddCartFreshProductModalContent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      productType, shippingMethod, prefectures, productVariants,
      defaultShippingMethodUid, defaultPrefectureValue, containsAlcohol,
      isStoreOrderable, isProductOrderable, isProductListable, productUnshippablePrefectureValues,
      alcoholAlertContent, priorityOrderableAlertContent, isPriorityOrderable, isPremiumGreenUser,
    } = this.props;

    return(
      <>
        { priorityOrderableAlertContent && (
          <div dangerouslySetInnerHTML={{ __html: priorityOrderableAlertContent }} />
        )}
        { containsAlcohol && (
          <div dangerouslySetInnerHTML={{ __html: alcoholAlertContent }} />
        )}
        <div className="p-productVariant_addCart_list">
          { productVariants.map((variant) => {
            return (
              <AddCartFreshForm
                key={variant.uid}
                productType={productType}
                prefectures={prefectures}
                productVariant={variant}
                shippingMethod={shippingMethod}
                defaultPrefectureValue={defaultPrefectureValue}
                isStoreOrderable={isStoreOrderable}
                isProductOrderable={isProductOrderable}
                isProductListable={isProductListable}
                isPriorityOrderable={isPriorityOrderable}
                isPremiumGreenUser={isPremiumGreenUser}
                productUnshippablePrefectureValues={productUnshippablePrefectureValues}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default AddCartFreshProductModalContent;

import * as React from "react";
import Rails from 'rails-ujs';
import { Button, Modal } from "react-bootstrap";

type Product = {
  uid: string,
  titleJa: string,
}

type ProductVariant = {
  uid: string,
  titleJa: string,
}

type Props = {
  product: Product,
  productVariant: ProductVariant,
  hasManyProductVariants: boolean,
  defaultUnitCardFee: number,
  defaultCardFeePct: number,
  defaultSellingFeePct: number,
  submitPath: string,
}

type State = {
  show: boolean,
  unitPrice: number,
  unitSellingFee: number,
  unitCardFee: number,
  isUnitSellingFeeFixed: boolean,
  isUnitCardFeeFixed: boolean,
  isSellingFeeDiscounted: boolean,
}

class ProductVariantPricesModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const unitPrice = props.productVariant.unitPrice;
    const unitSellingFee = props.productVariant.unitSellingFee;
    const unitCardFee = props.productVariant.unitCardFee;
    const calculatedUnitSellingFee = this._calcCalcUnitSellingFee(unitPrice, props.defaultSellingFeePct);
    const calculatedUnitCardFee = unitCardFee || this._calcUnitCardFee(unitPrice, unitSellingFee || calculatedUnitSellingFee, props.defaultCardFeePct);
    this.state = {
      show: false,
      unitPrice: unitPrice,
      unitSellingFee: unitSellingFee,
      unitCardFee: unitCardFee,
      calculatedUnitSellingFee: calculatedUnitSellingFee,
      calculatedUnitCardFee: calculatedUnitCardFee,
      isUnitSellingFeeFixed: !!props.productVariant.unitSellingFee || props.productVariant.unitSellingFee === 0,
      isUnitCardFeeFixed: !!props.productVariant.unitCardFee,
      isSellingFeeDiscounted: props.productVariant.isSellingFeeDiscounted,
    };

    this.close = this.close.bind(this);
    this.setCalcUnitSellingFee = this.setCalcUnitSellingFee.bind(this);
    this.setCalcUnitCardFee = this.setCalcUnitCardFee.bind(this);
  }

  close() {
    this.setState({ show: false });
  }

  _calcCalcUnitSellingFee(unitPrice, defaultSellingFeePct) {
    return parseInt(unitPrice * defaultSellingFeePct / 100.0);
  }

  _calcUnitCardFee(unitPrice, enabledUnitSellingFee, defaultCardFeePct) {
    return parseInt((unitPrice - enabledUnitSellingFee) * defaultCardFeePct / 100.0);
  }

  setCalcUnitSellingFee() {
    const { defaultSellingFeePct } = this.props;
    const { unitPrice } = this.state;
    const enabledUnitSellingFee = this._calcCalcUnitSellingFee(unitPrice, defaultSellingFeePct);
    this.setState({ calculatedUnitSellingFee: enabledUnitSellingFee }, () => { this.setCalcUnitCardFee(); });
  };

  setCalcUnitCardFee() {
    const { defaultCardFeePct } = this.props;
    const { unitPrice, unitSellingFee, calculatedUnitSellingFee, isUnitSellingFeeFixed } = this.state;
    const enabledUnitSellingFee = isUnitSellingFeeFixed ? unitSellingFee : calculatedUnitSellingFee;
    const enabledUnitCardFee = this._calcUnitCardFee(unitPrice, enabledUnitSellingFee, defaultCardFeePct);
    this.setState({ calculatedUnitCardFee: enabledUnitCardFee });
  };

  render () {
    const { hasManyProductVariants, productVariant, product, submitPath } = this.props;
    const { show, unitPrice, unitSellingFee, unitCardFee , calculatedUnitSellingFee, calculatedUnitCardFee, isUnitSellingFeeFixed, isUnitCardFeeFixed, isSellingFeeDiscounted } = this.state;

    return (
      <div>
        <Button onClick={() => this.setState({ show: true })} className="float-end btn btn-sm btn-primary">金額編集</Button>

        <Modal show={show} onHide={this.close}>
          <Modal.Header closeButton>
            <div className='fw-bold'>{hasManyProductVariants ? productVariant.titleJa : product.titleJa }</div>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning">
              あなた今、大変なことをやろうとしていますよ？本当に大丈夫ですか？
            </div>
            <form method="post" action={submitPath}>
              <input type="hidden" name="_method" value="patch" />
              <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
              <input type="hidden" value={productVariant.uid} name="product_variant[uid]" />
              <input type="hidden" value={unitPrice} name="product_variant[unit_price]" />
              <input type="hidden" value={isUnitSellingFeeFixed && (!!unitSellingFee || unitSellingFee === 0) ? unitSellingFee : ''} name="product_variant[unit_selling_fee]" />
              <input type="hidden" value={isUnitCardFeeFixed && !!unitCardFee ? unitCardFee : ''} name="product_variant[unit_card_fee]" />
              <input type="hidden" value={isSellingFeeDiscounted ? '1' : '0'} name="product_variant[is_selling_fee_discounted]" />
              <div className="mb-3">
                <div className="form-label">
                  <label htmlFor="admin_unit_price_71"><b>販売単価</b></label>
                </div>
                <input
                  className="form-control"
                  required="required"
                  type="number"
                  value={unitPrice}
                  onChange={(e) => { this.setState({ unitPrice: e.target.value }, () => { this.setCalcUnitSellingFee(); }); }}
                />
              </div>
              <div className="mb-3">
                <div className="form-label">
                  <label htmlFor="product_variant_unit_selling_fee">
                    <b>販売手数料</b>
                  </label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={isUnitSellingFeeFixed}
                        onChange={() => { this.setState({ isUnitSellingFeeFixed: !isUnitSellingFeeFixed }, () => { this.setCalcUnitSellingFee(); }); }}
                      />
                      店舗ごとに設定している販売手数料%を無視して、絶対値を指定する
                    </label>
                  </div>
                  <div>
                    <label htmlFor="">
                      <b>例外手数料価格設定</b>
                    </label>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={isSellingFeeDiscounted}
                          onChange={() => { this.setState({ isSellingFeeDiscounted: !isSellingFeeDiscounted }); }}
                        />
                        例外的に手数料を割引中である
                      </label>
                    </div>
                  </div>
                </div>
                <input
                  type="number"
                  required={isUnitSellingFeeFixed}
                  readOnly={!isUnitSellingFeeFixed}
                  value={isUnitSellingFeeFixed && (!!unitSellingFee || unitSellingFee === 0) ? unitSellingFee : ''}
                  placeholder={calculatedUnitSellingFee}
                  className={`form-control ${!!isUnitSellingFeeFixed && (!unitSellingFee && unitSellingFee !== 0) ? 'is-invalid' : ''}`}
                  onChange={(e) => { this.setState({ unitSellingFee: e.target.value }, () => { this.setCalcUnitCardFee(); }); }}
                />
              </div>
              {false && <div className="mb-3"> /* TODO: この機能は別issueを反映する時に有効にする( https://github.com/omakasejapan/omakasemall/issues/3308 ) */
                <div className="form-label">
                  <label htmlFor="admin_unit_card_fee_71">
                    <b>カード決済手数料</b>
                  </label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={isUnitCardFeeFixed}
                        onChange={() => this.setState({ isUnitCardFeeFixed: !isUnitCardFeeFixed }, () => { this.setCalcUnitCardFee(); })}
                      />
                      自動計算されるカード手数料を無視して、絶対値を指定する
                    </label>
                  </div>
                </div>
                <input
                  type="number"
                  required={isUnitCardFeeFixed}
                  readOnly={!isUnitCardFeeFixed}
                  value={isUnitCardFeeFixed && !!unitCardFee ? unitCardFee : ''}
                  placeholder={(!isUnitSellingFeeFixed || (isUnitSellingFeeFixed && (!!unitSellingFee || unitSellingFee === 0))) ? calculatedUnitCardFee : '販売手数料を入力してください'}
                  className={`form-control ${!!isUnitCardFeeFixed && !unitCardFee ? 'is-invalid' : ''}`}
                  onChange={(e) => { this.setState({ unitCardFee: e.target.value }); }}
                />
              </div>}
              <input
                type="submit"
                name="commit"
                value="保存"
                className="btn btn-primary"
                data-disable-with="保存"
              />
            </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default ProductVariantPricesModal;

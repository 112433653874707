import * as React from "react";
import Rails from 'rails-ujs';

type ListingStatus = 'draft' | 'listed' | 'unlisted' | 'secret'

type Props = {
  submitPath: string
  listingStatusKeys: string[]
  customTemplateFiles: string[]
  titleJa?: string
  descriptionJa?: string
  slug?: string
  bannerImgPc?: string
  bannerImgSp?: string
  order?: number
  bannerOrder?: number
  bannerListedFrom?: string
  bannerListedTill?: string
  listingStatus?: ListingStatus
  customTemplate?: string
}

type PromotionFormData = {
  title_ja: string
  description_ja: string
  order: number
  banner_order: number
  banner_listed_from: string
  banner_listed_till: string
  listing_status: ListingStatus
  custom_template: string
}

const MAX_NUM = 2147483647

const PromotionForm: React.FC<Props> = ({
  submitPath,
  listingStatusKeys,
  titleJa,
  descriptionJa,
  slug,
  bannerImgPc,
  bannerImgSp,
  order,
  bannerOrder,
  bannerListedFrom,
  bannerListedTill,
  listingStatus,
  customTemplate,
  customTemplateFiles,
}) => {
  const [promotionFormData, setPromotionFormData] = React.useState<PromotionFormData | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isValid, setIsValid] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!slug) return
    setPromotionFormData({
      title_ja: titleJa,
      description_ja: descriptionJa,
      banner_order: bannerOrder,
      banner_listed_from: bannerListedFrom,
      banner_listed_till: bannerListedTill,
      listing_status: listingStatus,
      custom_template: customTemplate,
      order,
    })
  }, [])

  React.useEffect(() => {
    if (!promotionFormData) return
    const bannerListedFrom = Date.parse(promotionFormData.banner_listed_from)
    const bannerListedTill = Date.parse(promotionFormData.banner_listed_till)

    if (
      !promotionFormData.title_ja ||
      !promotionFormData.description_ja ||
      (promotionFormData.order && (promotionFormData.order <= 0 || promotionFormData.order > MAX_NUM)) ||
      (promotionFormData.banner_order && (promotionFormData.banner_order <= 0 || promotionFormData.banner_order > MAX_NUM)) ||
      (!isNaN(bannerListedFrom) && !isNaN(bannerListedTill) && bannerListedFrom > bannerListedTill)
    ) {
      setIsValid(false)
      return
    }

    setIsValid(true)
  }, [promotionFormData])

  const handleInput = React.useCallback((e: React.KeyboardEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    const match = e.currentTarget.name.match(/^promotion\[(\w+)\]$/)

    setPromotionFormData({
      ...promotionFormData,
      [match[1]]: e.currentTarget.value,
    })
  }, [promotionFormData])

  const handleSubmit = React.useCallback(() => {
    setIsLoading(true)
  }, [])

  return (
    <form method="post" action={submitPath} encType="multipart/form-data" onSubmit={handleSubmit}>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      {!!slug && <input type="hidden" name="_method" value="put" />}
      <div className="c-section">
        <div className="row mb-4">
          <label htmlFor="title_ja" className="col-sm-3 col-form-label">
            タイトル
            <span className="c-form_rq">必須</span>
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              name="promotion[title_ja]"
              className="form-control"
              required
              placeholder="タイトルを入力してください"
              defaultValue={titleJa}
              onInput={handleInput}
            />
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="description_ja" className="col-sm-3 col-form-label">
            説明
            <span className="c-form_rq">必須</span>
          </label>
          <div className="col-sm-9">
            <textarea
              className="form-control"
              name="promotion[description_ja]"
              rows={10}
              defaultValue={descriptionJa}
              onInput={handleInput}
            />
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="has_custom_template" className="col-sm-3 col-form-label">
            カスタムテンプレート
          </label>
          <div className="col-sm-9">
            <select
              name="promotion[custom_template]"
              className="form-select"
              defaultValue={customTemplate}
            >
              <option value=""></option>
              {customTemplateFiles.map((file) => (
                <option key={file} value={file}>{file}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="banner_img_pc" className="col-sm-3 col-form-label">
            PC用バナー
          </label>
          <div className="col-sm-9">
            <div className="mb-2">
              <input
                type="file"
                name="promotion[banner_img_pc]"
                className="form-control"
              />
            </div>
            {bannerImgPc && (
              <div>
                <img src={bannerImgPc} alt="" width="400px" />
              </div>
            )}
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="banner_img_sp" className="col-sm-3 col-form-label">
            SP用バナー
          </label>
          <div className="col-sm-9">
            <div className="mb-2">
              <input
                type="file"
                name="promotion[banner_img_sp]"
                className="form-control"
              />
            </div>
            {bannerImgSp && (
              <div>
                <img src={bannerImgSp} alt="" width="300px" />
              </div>
            )}
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="order" className="col-sm-3 col-form-label">
            表示順
          </label>
          <div className="col-sm-9">
            <input
              type="number"
              name="promotion[order]"
              className="form-control"
              placeholder="表示順を入力してください"
              max={MAX_NUM}
              defaultValue={order}
              onInput={handleInput}
            />
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="banner_order" className="col-sm-3 col-form-label">
            バナー表示順
          </label>
          <div className="col-sm-9">
            <input
              type="number"
              name="promotion[banner_order]"
              className="form-control"
              placeholder="トップページバナー表示順を入力してください"
              max={MAX_NUM}
              defaultValue={bannerOrder}
              onInput={handleInput}
            />
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="listing_status" className="col-sm-3 col-form-label">
            ステータス
            <span className="c-form_rq">必須</span>
          </label>
          <div className="col-sm-9">
            <select
              name="promotion[listing_status]"
              className="form-select"
              required
              defaultValue={listingStatus}
            >
              {listingStatusKeys.map((key) => (
                <option key={key} value={key}>{key}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="banner_listed_from" className="col-sm-3 col-form-label">
            バナー表示開始日時
          </label>
          <div className="col-sm-9">
            <input
              type="datetime-local"
              name="promotion[banner_listed_from]"
              className="form-control"
              defaultValue={bannerListedFrom}
              onInput={handleInput}
            />
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="banner_listed_till" className="col-sm-3 col-form-label">
            バナー表示終了日時
          </label>
          <div className="col-sm-9">
            <input
              type="datetime-local"
              name="promotion[banner_listed_till]"
              className="form-control"
              defaultValue={bannerListedTill}
              onInput={handleInput}
            />
          </div>
        </div>
        <div className="mb-4">
          <button
            type="submit"
            disabled={!isValid || isLoading}
            className="btn btn-primary btn-wide fw-bold"
          >
            {isLoading && (<span className='spinner-border spinner-border-sm mx-3'></span>)}
            {isLoading ? (
              <span className='my-3'>処理中…</span>
            ) : (
              <span className="my-3">{!!slug ? '更新する' : '登録する'}</span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default PromotionForm

import * as React from 'react';
import classNames from 'classnames';
import { Coupon } from '../types/Coupon';

type Props = {
  // coupon: Coupon,
  coupon: Coupon,
  defaultCouponCode: string,
  setCouponCode: (couponCode: string) => void,
  userMessage: string,
  isApplyButtonActive: boolean,
};

type State = {
//  message: string,
  couponCode: string
}

class CouponFields extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: props.defaultCouponCode,
    };
  }

  render () {
    const { coupon, setCouponCode, userMessage, isApplyButtonActive } = this.props;
    const { couponCode } = this.state;

    const hasError = userMessage.length > 0 && !coupon.isApplicable;

    return (
      <div className="p-checkout_item">
        <div className="row">
          <div className="col-sm-3">
            <div className="p-checkout_label">クーポン</div>
          </div>
          <div className="col-sm-9">
            <div className="col-sm-4">
              <div className="input-group">
                <input type="text"
                  className={classNames({
                    "form-control": true,
                    "is-valid": coupon.isApplicable,
                    "is-invalid": hasError,
                  })}
                  id="coupon"
                  value={couponCode}
                  onChange={(e) => this.setState({ couponCode: e.target.value })}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setCouponCode(couponCode)}
                  disabled={!isApplyButtonActive}
                >
                  <span className='my-3'>適用</span>
                </button>
              </div>
            </div>
            <div className={hasError ? 'u-tx-alert' : 'u-tx-success'}>
              {userMessage}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CouponFields;

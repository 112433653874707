import * as React from 'react';
import Rails from 'rails-ujs';

type Props = {
  stores: {
    slug: string,
    title: string,
  }[],
  storeSlug: string,
  omakaseOwnerDashboardUrl: string,
};

class OwnerStoreSelector extends React.Component<Props> {
  render() {
    const { stores, storeSlug, omakaseOwnerDashboardUrl } = this.props;

    return (<>
      <select
        className="form-select"
        defaultValue={storeSlug}
        onChange={(e) => {
          if (e.target.value == 'oldEc') {
            window.location.href = omakaseOwnerDashboardUrl;
          }
          else if (storeSlug !== e.target.value) {
            window.location.href = `/owner/select_store/${e.target.value}`
          }
        }}
      >
        {stores.map(store => <option key={store.slug} value={store.slug}>
          {store.title}
        </option>)}
        <option key="oldEc" value="oldEc">旧ご自宅配送管理画面へ</option>
      </select>
    </>);
  }
};

export default OwnerStoreSelector;

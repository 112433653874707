import * as React from 'react';
import Rails from 'rails-ujs';
import I18n from '../../src/i18n-js/index.js.erb';
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import { OperatableDataByAdmin } from "./types/OperatableDataByAdmin";

type Props = {
  operatableDataByAdmin: OperatableDataByAdmin,
  validForAdminFunc: (res: boolean) => void,
  orderableFrom,
  orderableTill,
}

type State = {
  selectedCategories: string[],
  searchKeywords: string,
  v2RedirectLink: string,
  movieUrl: string,
  selectedLabels: string[],
  minRetentionTimeInCart: number|null,
  maxRetentionTimeInCart: number|null,
  priorityOrderableFrom: Date|null,
  priorityOrderableTill: Date|null,
  invalidPriorityOrderableFromMsg: string,
  invalidPriorityOrderableTillMsg: string,
}

class OperableFieldsByAdmin extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    I18n.locale = 'ja';

    if (!!props.operatableDataByAdmin) {
      this.state = {
        selectedCategories: props.operatableDataByAdmin.defaultCategories || [],
        searchKeywords: props.operatableDataByAdmin.defaultSearchKeywords || '',
        v2RedirectLink: props.operatableDataByAdmin.defaultV2RedirectLink || '',
        movieUrl: props.operatableDataByAdmin.defaultMovieUrl || '',
        selectedLabels: props.operatableDataByAdmin.defaultProductLabels || [],
        minRetentionTimeInCart: props.operatableDataByAdmin.defaultMinRetentionTimeInCart || '',
        maxRetentionTimeInCart: props.operatableDataByAdmin.defaultMaxRetentionTimeInCart || '',
        priorityOrderableFrom: props.operatableDataByAdmin.defaultPriorityOrderableFrom ? DateTime.fromISO(props.operatableDataByAdmin.defaultPriorityOrderableFrom).toJSDate() : '',
        priorityOrderableTill: props.operatableDataByAdmin.defaultPriorityOrderableTill ? DateTime.fromISO(props.operatableDataByAdmin.defaultPriorityOrderableTill).toJSDate() : '',
        invalidPriorityOrderableFromMsg: '',
        invalidPriorityOrderableTillMsg: '',
      };
    } else {
      this.state = {
        selectedCategories: [],
        searchKeywords: '',
        v2RedirectLink: '',
        movieUrl: '',
        selectedLabels: [],
        minRetentionTimeInCart: '',
        maxRetentionTimeInCart: '',
        priorityOrderableFrom: '',
        priorityOrderableTill: '',
        invalidPriorityOrderableFromMsg: '',
        invalidPriorityOrderableTillMsg: '',
      };
    }

    this.toggleCategory = this.toggleCategory.bind(this);
  }

  toggleCategory(category) {
    const { selectedCategories } = this.state;

    if (selectedCategories.includes(category)) {
      this.setState({ selectedCategories: selectedCategories.filter(c => c != category) });
    } else {
      this.setState({ selectedCategories: [...selectedCategories, category] });
    }
  }

  toggleLabel(label) {
    const { selectedLabels } = this.state;

    if (selectedLabels.includes(label)) {
      this.setState({ selectedLabels: selectedLabels.filter(c => c != label) });
    } else {
      this.setState({ selectedLabels: [...selectedLabels, label] });
    }
  }

  isValid() {
    const { operatableDataByAdmin, validForAdminFunc, orderableFrom, orderableTill } = this.props;
    const { v2RedirectLink, minRetentionTimeInCart, maxRetentionTimeInCart, priorityOrderableFrom, priorityOrderableTill } = this.state;

    let isValidV2RedirectLink = true;
    if (v2RedirectLink.length > 0) {
      const reg = new RegExp("^" + operatableDataByAdmin.omakaseUrl);
      isValidV2RedirectLink = !!v2RedirectLink.match(reg)
    }

    let isValidMinRetentionTimeInCart = true;
    let isValidMaxRetentionTimeInCart = true;
    if (!!minRetentionTimeInCart) {
      isValidMinRetentionTimeInCart = minRetentionTimeInCart > 0;
      isValidMaxRetentionTimeInCart = !!maxRetentionTimeInCart;
    }
    if (!!maxRetentionTimeInCart) {
      isValidMaxRetentionTimeInCart = maxRetentionTimeInCart > 0;
      isValidMinRetentionTimeInCart = !!minRetentionTimeInCart;
    }
    if (!!minRetentionTimeInCart && !!maxRetentionTimeInCart && isValidMinRetentionTimeInCart && isValidMaxRetentionTimeInCart) {
      isValidMaxRetentionTimeInCart = minRetentionTimeInCart <= maxRetentionTimeInCart;
    }

    let isValidPriorityOrderableFrom = true;
    let isValidPriorityOrderableTill = true;
    if (!!priorityOrderableFrom) {
      if (!!orderableFrom) {
        isValidPriorityOrderableFrom = orderableFrom <= priorityOrderableFrom
        if (!isValidPriorityOrderableFrom) this.setState({ invalidPriorityOrderableFromMsg: '注文開始日時以上の日時を入力してください' });
      }
      if (isValidPriorityOrderableFrom && !!orderableTill) {
        isValidPriorityOrderableFrom = orderableTill > priorityOrderableFrom
        if (!isValidPriorityOrderableFrom) this.setState({ invalidPriorityOrderableFromMsg: '注文終了日時未満の日時を入力してください' });
      }
      if (isValidPriorityOrderableFrom && !!priorityOrderableTill) {
        isValidPriorityOrderableFrom = priorityOrderableFrom < priorityOrderableTill
        if (!isValidPriorityOrderableFrom) this.setState({ invalidPriorityOrderableFromMsg: 'Green優先購入終了日時未満の日時を入力してください' });
      }
      if (isValidPriorityOrderableFrom) {
        this.setState({ invalidPriorityOrderableFromMsg: '' });
      }
    }
    if (!!priorityOrderableTill) {
      if (!!orderableFrom) {
        isValidPriorityOrderableTill = orderableFrom < priorityOrderableTill
        if (!isValidPriorityOrderableTill) this.setState({ invalidPriorityOrderableTillMsg: '注文開始日時より未来の日時を入力してください' });
      }
      if (isValidPriorityOrderableTill && !!orderableTill) {
        isValidPriorityOrderableTill = orderableTill >= priorityOrderableTill
        if (!isValidPriorityOrderableTill) this.setState({ invalidPriorityOrderableTillMsg: '注文終了日時以下の日時を入力してください' });
      }
      if (!!isValidPriorityOrderableTill) {
        this.setState({ invalidPriorityOrderableTillMsg: '' });
      }
    }

    validForAdminFunc(
      isValidV2RedirectLink && isValidMinRetentionTimeInCart && isValidMaxRetentionTimeInCart &&
        isValidPriorityOrderableFrom && isValidPriorityOrderableTill
    );
  }

  render () {
    const { operatableDataByAdmin } = this.props;
    const {
      selectedCategories, searchKeywords, v2RedirectLink, movieUrl, selectedLabels,
      minRetentionTimeInCart, maxRetentionTimeInCart, priorityOrderableFrom, priorityOrderableTill,
      invalidPriorityOrderableFromMsg, invalidPriorityOrderableTillMsg,
    } = this.state;

    if (!operatableDataByAdmin) return null;

    return (<>
      <div className="c-section bg-light">
        <div className="c-section_title"><i className="fas fa-user-secret"></i>Admin用</div>
        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">カテゴリー</label>
          <div className="col-sm-10">
            {Object.keys(operatableDataByAdmin.allProductCategories).map((category, i) => {
              return (<div key={i} className="form-check form-check-inline">
                <input
                  type="checkbox"
                  value={category}
                  className='form-check-input'
                  id={`category-${category}`}
                  checked={selectedCategories.includes(category)}
                  onChange={e => this.toggleCategory(e.target.value) }
                />
                <label className="form-check-label" htmlFor={`category-${category}`}>
                  {I18n.t(`enums.product_category.category.${category}.title`)}
                </label>
              </div>);
            })}
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">検索用キーワード</label>
          <div className="col-sm-10">
            <input className="form-control" type="text"
              value={searchKeywords}
              placeholder="検索用キーワードを入力してください"
              onChange={e => this.setState({ searchKeywords: e.target.value })}
            />
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">一時的な本体へのリダイレクトURL</label>
          <div className="col-sm-10">
            <input className="form-control" type="text"
              value={v2RedirectLink}
              placeholder="本体の商品URLを入力してください"
              onChange={e => { this.setState({ v2RedirectLink: e.target.value }, () => this.isValid()) }}
              pattern={`^${operatableDataByAdmin.omakaseUrl}.*$`}
              title="OMAKASE本体のURLを入力してください"
            />
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">動画URL</label>
          <div className="col-sm-10">
            <input className="form-control" type="text"
              value={movieUrl}
              placeholder="動画URLを入力してください"
              onChange={e => { this.setState({ movieUrl: e.target.value }) }}
              pattern={`^https.*$`}
              title="動画URLを入力してください"
            />
            <small className="bg-seco">youtubeの場合は共有用のiframeタグから、埋め込み用のURLを取得し設定してください</small>
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">カート保有可能時間</label>
          <div className="col-sm-10">
            <div className="input-group">
              <input className="form-control" type="number"
                value={minRetentionTimeInCart}
                placeholder="最小時間（分）"
                onChange={e => { this.setState({ minRetentionTimeInCart: e.target.value }, () => this.isValid()) }}
                title="最小時間（分）を入力してください"
                required={!!maxRetentionTimeInCart}
              />
              〜
              <input className="form-control" type="number"
                value={maxRetentionTimeInCart}
                placeholder="最大時間（分）"
                onChange={e => { this.setState({ maxRetentionTimeInCart: e.target.value }, () => this.isValid()) }}
                title="最大時間（分）を入力してください"
                required={!!minRetentionTimeInCart}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">Green優先購入開始日時</label>
          <div className="col-sm-10">
            <DatePicker locale="ja" showTimeSelect selected={priorityOrderableFrom} dateFormat="Pp" className="form-control"
              onChange={date => this.setState({ priorityOrderableFrom: date || '' }, () => this.isValid())}
            />
            { !!invalidPriorityOrderableFromMsg ? <span className="text-danger">{invalidPriorityOrderableFromMsg}</span> : '' }
          </div>
        </div>
        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">Green優先購入終了日時</label>
          <div className="col-sm-10">
            <DatePicker locale="ja" showTimeSelect selected={priorityOrderableTill} dateFormat="Pp" className="form-control"
              onChange={date => this.setState({ priorityOrderableTill: date || '' }, () => this.isValid())}
            />
            { !!invalidPriorityOrderableTillMsg ? <span className="text-danger">{invalidPriorityOrderableTillMsg}</span> : '' }
          </div>
        </div>
      </div>
      {selectedCategories.map((c, index) => <input key={index} type="hidden" name={`product_categories[${c}]`} value={c} />)}
      {selectedLabels.map((l, index) => <input key={index} type="hidden" name={`product_labels[${l}]`} value={l} />)}
      <input type="hidden" name="product[search_keywords]" value={searchKeywords} />
      <input type="hidden" name="product[v2_redirect_link]" value={v2RedirectLink} />
      <input type="hidden" name="product[movie_url]" value={movieUrl} />
      <input type="hidden" name="product[min_retention_time_in_cart]" value={minRetentionTimeInCart} />
      <input type="hidden" name="product[max_retention_time_in_cart]" value={maxRetentionTimeInCart} />
      <input type="hidden" name="product[priority_orderable_from]" value={priorityOrderableFrom} />
      <input type="hidden" name="product[priority_orderable_till]" value={priorityOrderableTill} />
    </>);
  }
}

export default OperableFieldsByAdmin;

import * as React from 'react';
import Rails from 'rails-ujs';
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import I18n from '../src/i18n-js/index.js.erb';
import { OperatableDataByAdmin } from "./types/OperatableDataByAdmin";
import OperableFieldsByAdmin from './products/OperableFieldsByAdmin';
import ImageUpdater from './image_updater/ImageUpdater';
import classNames from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
registerLocale('ja', ja);

type Props = {
  productType: 'fresh'|'grocery',
  isOwnerEditable: boolean,
  defaultTitleJa: string,
  defaultDisplayTitleJa: string,
  defaultCtaxPct: '8.0'|'10.0',
  defaultShippingMethodUid: string,
  defaultUnshippablePrefectures: string[],
  defaultCutoffDaysBefore: string,
  defaultCutoffTimeHour: string,
  defaultCutoffTimeMinute: string,
  defaultMaxOrderToShipDays: string,
  defaultOrderableFrom: number|null,
  defaultOrderableTill: number|null,
  defaultShippableFrom: number|null,
  defaultDescriptionJa: string|null,
  defaultExpiryNoteJa: string|null,
  defaultPackageNoteJa: string|null,
  defaultSizePerUnitJa: string|null,
  defaultStorageNoteJa: string|null,
  defaultIngredientsNoteJa: string|null,
  defaultAllergenNoteJa: string|null,
  defaultProducerNoteJa: string|null,
  defaultExtraInfoNoteJa: string|null,
  defaultDeliveryNoteJa: string|null,
  defaultContainsAlcohol: boolean,
  defaultIsArchived: boolean,
  defaultProductVariants: ProductVariant[],
  defaultNewProductVariants: ProductVariant[],
  defaultGiftLabel: string|null,
  defaultGiftLabelDescriptionJa: string|null,
  shippingMethods: {
    label: string,
    uid: string,
    productType: string,
    prefectureValues: string[],
    isAsapOnly: boolean
  }[],
  prefectures: [string, string][],
  submitPath: string,
  isProductPersisted: boolean,
  prefecturesAreaMap: PrefecturesAreaMap,
  hasLiquorSalesLicense: boolean,
  isSelfServeStore: boolean,
  operatableDataByAdmin: OperatableDataByAdmin,
};

type Prefecture = {
  code: number,
  name: string,
  name_e: string,
}

type Area = {
  areaPrefectureCodes: number[],
  prefectures: Prefecture[],
}

interface PrefecturesAreaMap {
  [key: string]: Area;
}

type ProductVariant = {
  id: number|null,
  titleJa: string,
  descriptionJa: string,
  unitPrice: string,
  imagePath: string,
  isShowAll: boolean,
  imageDeletingFlag: boolean|null,
};


type State = {
  titleJa: string,
  ctaxPct: '8.0'|'10.0',
  unshippablePrefectures: string[],
  cutoffDaysBefore: string,
  cutoffTimeHour: string,
  cutoffTimeMinute: string,
  maxOrderToShipDays: string,
  shippingMethodUid: string,
  orderableFrom: Date|null, // DateTime
  orderableTill: Date|null, // DateTime
  shippableFrom: Date|null, // Date
  productVariants: ProductVariant[],
  newProductVariants: ProductVariant[],
  descriptionJa: string,
  expiryNoteJa: string,
  packageNoteJa: string,
  sizePerUnitJa: string,
  storageNoteJa: string,
  ingredientsNoteJa: string,
  allergenNoteJa: string,
  producerNoteJa: string,
  extraInfoNoteJa: string,
  deliveryNoteJa: string,
  containsAlcohol: boolean,
  isArchived: boolean,
  isLoading: boolean,
  isValidForAdmin: boolean,
  giftIsChecked: boolean,
  giftDescriptionJa: string,
};

class ProductForm extends React.Component<Props, State> {
  private form: React.RefObject<HTMLFormElement>;

  constructor (props: Props) {
    super(props);

    I18n.locale = 'ja';

    this.state = {
      titleJa: props.defaultTitleJa,
      displayTitleJa: props.defaultDisplayTitleJa ? props.defaultDisplayTitleJa : '',
      ctaxPct: props.defaultCtaxPct,
      unshippablePrefectures: props.defaultUnshippablePrefectures,
      cutoffDaysBefore: props.defaultCutoffDaysBefore,
      cutoffTimeHour: props.defaultCutoffTimeHour,
      cutoffTimeMinute: props.defaultCutoffTimeMinute,
      maxOrderToShipDays: props.defaultMaxOrderToShipDays,
      shippingMethodUid: props.defaultShippingMethodUid,
      orderableFrom: props.defaultOrderableFrom ? DateTime.fromISO(props.defaultOrderableFrom).toJSDate() : null,
      orderableTill: props.defaultOrderableTill ? DateTime.fromISO(props.defaultOrderableTill).toJSDate() : null,
      shippableFrom: props.defaultShippableFrom ? DateTime.fromISO(props.defaultShippableFrom).toJSDate() : null,
      descriptionJa: props.defaultDescriptionJa ? props.defaultDescriptionJa : '',
      expiryNoteJa: props.defaultExpiryNoteJa ? props.defaultExpiryNoteJa : '',
      packageNoteJa: props.defaultPackageNoteJa ? props.defaultPackageNoteJa : '',
      sizePerUnitJa: props.defaultSizePerUnitJa ? props.defaultSizePerUnitJa : '',
      storageNoteJa: props.defaultStorageNoteJa ? props.defaultStorageNoteJa : '',
      ingredientsNoteJa: props.defaultIngredientsNoteJa ? props.defaultIngredientsNoteJa : '',
      allergenNoteJa: props.defaultAllergenNoteJa ? props.defaultAllergenNoteJa : '',
      producerNoteJa: props.defaultProducerNoteJa ? props.defaultProducerNoteJa : '',
      extraInfoNoteJa: props.defaultExtraInfoNoteJa ? props.defaultExtraInfoNoteJa : '',
      deliveryNoteJa: props.defaultDeliveryNoteJa ? props.defaultDeliveryNoteJa : '',
      containsAlcohol: props.defaultContainsAlcohol,
      isArchived: props.defaultIsArchived,
      isLoading: false,
      giftIsChecked: !!props.defaultGiftLabel,
      giftDescriptionJa: props.defaultGiftLabelDescriptionJa,
      isValidForAdmin: !!props.operatableDataByAdmin,
      productVariants: props.isProductPersisted ? props.defaultProductVariants : [],
      newProductVariants: props.isProductPersisted ? props.defaultNewProductVariants : [{
        id: '',
        titleJa: '',
        descriptionJa: '',
        unitPrice: '',
        imagePath: '',
        isShowAll: false,
        imageDeletingFlag: false,
      }],
    };
    if (!!props.operatableDataByAdmin) {
      this.adminFieldsRef = React.createRef();
    }

    this.setProductVariantState = this.setProductVariantState.bind(this);
    this.setNewProductVariantState = this.setNewProductVariantState.bind(this);
    // this.toggleProductVariantIsStockInfinite = this.toggleProductVariantIsStockInfinite.bind(this);
    this.addNewProductVariant = this.addNewProductVariant.bind(this);
    this.removeProductVariant = this.removeProductVariant.bind(this);
    this.removeNewProductVariant = this.removeNewProductVariant.bind(this);
    this.isAreaCheck = this.isAreaCheck.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  setProductVariantState(index: number, newValues: Partial<ProductVariant>) {
    const { productVariants } = this.state;
    const tmpProductVariants = productVariants.map((pv, i) => {
      if (i === index) {
        return { ...pv, ...newValues }
      } else {
        return pv;
      }
    });
    this.setState({ productVariants: tmpProductVariants });
  }

  setNewProductVariantState(index: number, newValues: Partial<ProductVariant>) {
    const { newProductVariants } = this.state;
    const tmpNewProductVariants = newProductVariants.map((pv, i) => {
      if (i === index) {
        return { ...pv, ...newValues }
      } else {
        return pv;
      }
    });
    this.setState({ newProductVariants: tmpNewProductVariants });
  }

  addNewProductVariant() {
    const { newProductVariants } = this.state;
    this.setState({
      newProductVariants: [...newProductVariants, {
        id: '',
        titleJa: '',
        descriptionJa: '',
        unitPrice: '',
        imagePath: '',
        isShowAll: false,
        imageDeletingFlag: false,
      }]
    });
  }

  removeProductVariant(index: number) {
    const { productVariants, newProductVariants } = this.state;
    let tmpProductVariants = productVariants.filter((_pv, i) => i !== index);
    if ((tmpProductVariants.length + newProductVariants.length) === 1) {
      if (newProductVariants.length === 1) {
        newProductVariants[0].titleJa = '';
        newProductVariants[0].descriptionJa = '';
        newProductVariants[0].isShowAll = false;
        newProductVariants[0].imageDeletingFlag = false;
      } else {
        tmpProductVariants[0].titleJa = '';
        tmpProductVariants[0].descriptionJa = '';
        tmpProductVariants[0].isShowAll = false;
        tmpProductVariants[0].imageDeletingFlag = false;
      }
    }
    this.setState({
      productVariants: tmpProductVariants
    });
  }

  removeNewProductVariant(index: number) {
    const { productVariants, newProductVariants } = this.state;
    let tmpNewProductVariants = newProductVariants.filter((_pv, i) => i !== index);
    if ((productVariants.length + tmpNewProductVariants.length) === 1) {
      if (tmpNewProductVariants.length === 1) {
        tmpNewProductVariants[0].titleJa = '';
        tmpNewProductVariants[0].descriptionJa = '';
        tmpNewProductVariants[0].isShowAll = false;
        tmpNewProductVariants[0].imageDeletingFlag = false;
      } else {
        productVariants[0].titleJa = '';
        productVariants[0].descriptionJa = '';
        productVariants[0].isShowAll = false;
        productVariants[0].imageDeletingFlag = false;
      }
    }
    this.setState({
      newProductVariants: tmpNewProductVariants
    });
  }

  isAreaCheck(area: string) {
    const name_es = this.props.prefecturesAreaMap[area]['prefectures'].map(item => item['name_e']);
    const selected_name_es = this.state.unshippablePrefectures.filter(item => name_es.includes(item));
    return name_es.length == selected_name_es.length;
  }

  isValid() {
    const { isOwnerEditable, isProductPersisted, operatableDataByAdmin, shippingMethods } = this.props;
    const {
      titleJa, cutoffDaysBefore, cutoffTimeHour, cutoffTimeMinute,
      shippingMethodUid, productVariants, newProductVariants, isValidForAdmin, orderableFrom, orderableTill,
    } = this.state;

    // 単独valiantの価格入力済みか、全valiant入力済み
    let isProductVariantListValid: boolean;
    let isNewProductVariantListValid: boolean;

    if (isProductPersisted && !isOwnerEditable) {
      isProductVariantListValid = true;
      isNewProductVariantListValid = true;
    } else {
      if (productVariants.length === 1) {
        isProductVariantListValid = !!productVariants[0].unitPrice.toString().length;
      } else if (productVariants.length > 1) {
        isProductVariantListValid = productVariants.every(pv => pv.titleJa.length && pv.unitPrice.toString().length);
      } else {
        isProductVariantListValid = true;
      }
      if (newProductVariants.length === 1) {
        isNewProductVariantListValid = !!newProductVariants[0].unitPrice.toString().length;
      } else if (newProductVariants.length > 1) {
        isNewProductVariantListValid = newProductVariants.every(pv => pv.titleJa.length && pv.unitPrice.toString().length);
      } else {
        isNewProductVariantListValid = true;
      }
    }

    return (
      titleJa.length &&
      cutoffDaysBefore.length &&
      cutoffTimeHour.length &&
      cutoffTimeMinute.length &&
      shippingMethodUid.length && shippingMethods.find(sm => sm.uid === shippingMethodUid) &&
      isProductVariantListValid &&
      isNewProductVariantListValid &&
      (!operatableDataByAdmin || isValidForAdmin)
    );
  }

  isValidByAdmin() {
    if (!!this.adminFieldsRef) {
      this.adminFieldsRef.current.isValid();
    }
  }

  validForAdminFunc(validResult: boolean) {
    this.setState({ isValidForAdmin: validResult });
  }

  render () {
    const {
      productType, shippingMethods, prefectures, submitPath, isProductPersisted,
      prefecturesAreaMap, defaultUnshippablePrefectures, hasLiquorSalesLicense,
      operatableDataByAdmin, isOwnerEditable, isSelfServeStore,
    } = this.props;
    const {
      titleJa, displayTitleJa, productVariants, newProductVariants,
      ctaxPct, unshippablePrefectures,
      shippingMethodUid,
      orderableFrom, orderableTill, shippableFrom,
      cutoffDaysBefore, cutoffTimeHour, cutoffTimeMinute, maxOrderToShipDays,
      descriptionJa, expiryNoteJa, packageNoteJa, sizePerUnitJa, storageNoteJa, ingredientsNoteJa, containsAlcohol,
      allergenNoteJa, producerNoteJa, extraInfoNoteJa, isArchived, giftIsChecked, giftDescriptionJa,
      deliveryNoteJa, isLoading,
    } = this.state;

    const hasManyVariants = (productVariants.length + newProductVariants.length) > 1;
    const hasShippingMethods = shippingMethods.length > 0
    const shippingMethod = shippingMethods.find(sm => sm.uid === shippingMethodUid);
    const cutoffTime = DateTime.utc().set({ hour: cutoffTimeHour, minute: cutoffTimeMinute});
    const isEditable = !!operatableDataByAdmin || isOwnerEditable;
    const isEditablePrice = !!operatableDataByAdmin || (isOwnerEditable && isSelfServeStore) || !isProductPersisted;

    const buildSingleProductVariantFields = () => {
      let pv = productVariants[0] || newProductVariants[0];
      return(
        <>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">
              価格
              <span className="c-form_rq">必須</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-4">
                  <div className="input-group">
                    <input type="number" required
                      value={pv.unitPrice}
                      className={pv.unitPrice ? "form-control" : "form-control is-invalid"}
                      readOnly={!isEditablePrice && !!pv.id}
                      onChange={e => { productVariants[0] ? this.setProductVariantState(0, { unitPrice: e.target.value }) : this.setNewProductVariantState(0, { unitPrice: e.target.value }) }}
                    />
                    <label className="input-group-text">円(税込)</label>
                  </div>
                  {(!!operatableDataByAdmin || (isOwnerEditable && isSelfServeStore)) && <div className="mt-3">
                    <button type="button" className="btn btn-secondary"
                      onClick={_e => this.addNewProductVariant()}
                    >
                      <i className="far fa-clone me-2"></i>
                      バリエーションを複数設定する
                    </button>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    const buildMultipleProductVariantFields = () => {
      const generateVariantList = (generateType, variants) => {
        let setter;
        let remover;
        let fileFieldName;
        if (generateType == 'new') {
          setter = (self, index, values) => { self.setNewProductVariantState(index, values); };
          remover = (self, index) => { self.removeNewProductVariant(index); };
          fileFieldName = 'new_product_variants';
        } else {
          setter = (self, index, values) => { self.setProductVariantState(index, values); };
          remover = (self, index) => { self.removeProductVariant(index); };
          fileFieldName = 'product_variants';
        }
        return variants.map((pv, index) => <div key={`tbd-${index}`}>
          <div className="p-productVariantEdit">
            <div className="row">
              <div className="col-sm-6">
                <input type="text" value={pv.titleJa} className={pv.titleJa ? "form-control" : "form-control is-invalid"} readOnly={!isEditable}
                  placeholder="(例) 10個入りセット"
                  onChange={e => setter(this, index, { titleJa: e.target.value })}
                />
              </div>
              <div className="col-sm-5">
                <div className="input-group">
                  <input type="number" required value={pv.unitPrice} className={pv.unitPrice ? "form-control" : "form-control is-invalid"} readOnly={!isEditablePrice && !!pv.id}
                    onChange={e => setter(this, index, { unitPrice: e.target.value })}
                  />
                  <label className="input-group-text">円(税込)</label>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              {(isEditable || pv.imagePath) && (
                <div className={`col-sm-3 p-productVariantEdit_img ${pv.isShowAll ? '' : 'hidden'}`}>
                  <ImageUpdater
                    accept=".jpg,.jpeg,.gif,.png"
                    isEditable={isEditable}
                    isDisabled={false}
                    isSingleMode
                    inputName={`${fileFieldName}[${generateType == 'new' ? index : pv.id}][image]`}
                    registeredImages={pv.imagePath ? [{
                      id: pv.id,
                      image: pv.imagePath,
                    }] : []}
                    deletionComponent={
                      <input
                        type="hidden"
                        name={`product_variant_ids_of_images_for_deletion[]`}
                        value={pv.id}
                      />
                    }
                  />
                </div>
              )}

              <div className={`col-sm-9 p-productVariantEdit_description ${pv.isShowAll ? '' : 'hidden'}`}>
                <textarea value={pv.descriptionJa} className="form-control" readOnly={!isEditable}
                  onChange={e => setter(this, index, { descriptionJa: e.target.value })}
                  placeholder="1行程度のバリエーションの説明を追加してください"
                />
              </div>
            </div>

            {(!!operatableDataByAdmin || (isOwnerEditable && isSelfServeStore)) && (
              <div className="p-productVariantEdit_toggle">
                <button type="button" className="btn btn-outline-danger btn-sm" onClick={_e => remover(this, index)} >
                  <i className="fas fa-times"></i>
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm ms-2"
                  onClick={_e => setter(this, index, { isShowAll: !pv.isShowAll })}
                >
                  {pv.isShowAll ? <i className="fas fa-angle-up"></i> : <i className="fas fa-angle-down"></i>}
                </button>
              </div>
            )}
          </div>

        </div>)
      };

      return(
        <>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">
              バリエーション
              <span className="c-form_rq">必須</span>
            </label>
            <div className="col-sm-10">
              {generateVariantList('exists', productVariants)}
              {generateVariantList('new', newProductVariants)}
              {(!!operatableDataByAdmin || (isOwnerEditable && isSelfServeStore)) && <button type="button" className="btn btn-secondary"
                onClick={_e => this.addNewProductVariant()}
              >
                バリエーションの追加
              </button>}
            </div>
          </div>
        </>
      );
    }

    // loading処理 タグをdisabledにするとsubmit出来ないので見た目だけdisabledにしている
    let submitClasses = classNames('btn', 'btn-primary', 'btn-wide', 'fw-bold', {disabled: isLoading || !this.isValid()});

    return(
      <form method="post" action={submitPath} encType="multipart/form-data">
        {!hasShippingMethods && <div className="alert alert-warning">
          <i className="fas fa-exclamation-triangle"></i>
          <span>有効な配送方法が設定されていません。 </span>
          <a href="/owner/shipping_methods">こちらから</a>
          <span>設定してください。</span>
        </div> }
        <div className="c-section c-section-spNoPadding">
          <div className="c-section_title mb-3">基本情報</div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label" htmlFor="product_title_ja">商品名
              <span className="c-form_rq">必須</span>
            </label>
            <div className="col-sm-10">
              <input className={titleJa ? "form-control" : "form-control is-invalid"} required type="text"
                value={titleJa}
                placeholder="商品名を入力してください"
                onChange={e => this.setState({ titleJa: e.target.value })}
                readOnly={!isEditable}
              />
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-md-2 col-form-label">配送方法<span className="c-form_rq">必須</span></label>
            <div className="col-md-4">
              <select value={shippingMethodUid} className={shippingMethods.find(sm => sm.uid === shippingMethodUid) ? "form-select" : "form-select is-invalid"} required
                onChange={ e => this.setState({ shippingMethodUid: e.target.value }) }
              >
                <option key='' value='' disabled={!isEditable}>選択してください</option>
                {shippingMethods.filter(sm => sm.productType === productType).map(sm =>
                  <option key={sm.uid} value={sm.uid} disabled={!isEditable}>{sm.label}</option>
                )}
              </select>
            </div>
          </div>

          {(!shippingMethod || !shippingMethod.isAsapOnly) && <div className="row mb-4">
            <label className="col-sm-2 col-form-label">注文期限
              <span className="c-form_rq">必須</span>
            </label>
            <div className="col-sm-10">
              <div className="input-group">
                <label className="input-group-text">発送予定日</label>
                <input type="number" className={cutoffDaysBefore ? "form-control" : "form-control is-invalid"} value={cutoffDaysBefore} min="0" required
                  onChange={e => this.setState({ cutoffDaysBefore: e.target.value })}
                  readOnly={!isEditable}
                />
                <label className="input-group-text">日前の</label>
                <select value={cutoffTimeHour} className={cutoffTimeHour ? "form-control" : "form-control is-invalid"}
                  onChange={ e => this.setState({ cutoffTimeHour: e.target.value }) }
                >
                  {Array.from(Array(24).keys()).map(hour => <option
                    key={hour}
                    value={hour}
                    disabled={!isEditable}
                  >{hour}</option>)}
                </select>
                <label className="input-group-text">時</label>
                <select value={cutoffTimeMinute} className={cutoffTimeMinute ? "form-control" : "form-control is-invalid"}
                  onChange={ e => this.setState({ cutoffTimeMinute: e.target.value }) }
                >
                  {Array.from(Array(12).keys()).map(i => <option
                    key={i}
                    value={i*5}
                    disabled={!isEditable}
                  >{i*5}</option>)}
                </select>
                <label className="input-group-text">分まで注文受付</label>
              </div>
              <div className="c-form-caution">お客様がカートに保持できる最大時間が30分間のため、厳密に注文を締め切りたい場合は30分前の時間を設定してください。</div>
            </div>
          </div>}

          {productType === 'grocery' && (!shippingMethod || !shippingMethod.isAsapOnly) && <div className="row mb-4">
            <label className="col-sm-2 col-form-label">発送までの最大日数
              <span className="c-form_rq">必須</span>
            </label>
            <div className="col-sm-10">
              <div className="input-group">
                <label className="input-group-text">注文日から</label>
                <input type="number" className="form-control" value={maxOrderToShipDays} min="0" max="30" required
                  onChange={e => this.setState({ maxOrderToShipDays: e.target.value })}
                  readOnly={!isEditable}
                />
                <label className="input-group-text">日後まで</label>
              </div>
              <div className="c-form-caution">
                お客様側で配送日時指定ができる範囲を、注文日から最大30日の間で指定します。最短の到着日時指定しか許可しない場合は0をいれてください。
                この日数が「注文期限」より短い場合は「発送までの最大日数」は無視され、強制的に最短で発送することを前提にお届け日を選択いただきます。
              </div>
            </div>
          </div>}

          <div className="row mb-4">
            <label className="col-md-2 col-form-label">消費税率
              <span className="c-form_rq">必須</span>
            </label>
            <div className="col-md-4">
              <select value={ctaxPct} className={ctaxPct ? "form-select" : "form-select is-invalid"}
                onChange={e => this.setState({ ctaxPct: e.target.value as typeof ctaxPct })}
              >
                <option value='8.0' disabled={!isEditable}>8%（軽減税率）</option>
                <option value='10.0' disabled={!isEditable}>10%</option>
              </select>
            </div>
          </div>

          {hasManyVariants ? (
            <DndProvider backend={HTML5Backend}>
              {buildMultipleProductVariantFields()}
            </DndProvider>
          ) : buildSingleProductVariantFields()}

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">商品説明
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ descriptionJa: e.target.value })}
                    rows={8}
                    placeholder="大将のあれもこれも食べたいをわがままいっぱい詰め込んだ商品にしました。&#13;&#10;※全て手作りなので、一日限定5台で売り切れ次第終了です。"
                    value={descriptionJa}
                    readOnly={!isEditable}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="c-section c-section-spNoPadding">
          <div className="c-section_title mb-3">商品の詳細情報</div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">消費期限
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ expiryNoteJa: e.target.value })}
                    rows={4}
                    placeholder="到着日含め5日間&#13;&#10;開封後はお早めにお召し上がりくださいください。"
                    value={expiryNoteJa}
                    readOnly={!isEditable}
                  />
                  <div className="c-form-caution">商品バリエーションが複数ある場合も、全てこの欄に記載します。</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">内容量
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ packageNoteJa: e.target.value })}
                    rows={4}
                    placeholder="[Sサイズ]100ml&#13;&#10;[Mサイズ]200ml&#13;&#10;[Lサイズ]300ml"
                    value={packageNoteJa}
                    readOnly={!isEditable}
                    />
                  <div className="c-form-caution">商品バリエーションが複数ある場合も、全てこの欄に記載します。</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">保存方法
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ storageNoteJa: e.target.value })}
                    rows={4}
                    placeholder="10℃以下で要冷蔵"
                    value={storageNoteJa}
                    readOnly={!isEditable}
                    />
                  <div className="c-form-caution">商品バリエーションが複数ある場合も、全てこの欄に記載します。</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">原材料
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ ingredientsNoteJa: e.target.value })}
                    rows={4}
                    value={ingredientsNoteJa}
                    readOnly={!isEditable}
                    />
                  <div className="c-form-caution">商品バリエーションが複数ある場合も、全てこの欄に記載します。</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">アレルギー情報
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ allergenNoteJa: e.target.value })}
                    rows={4}
                    value={allergenNoteJa}
                    readOnly={!isEditable}
                    />
                  <div className="c-form-caution">商品バリエーションが複数ある場合も、全てこの欄に記載します。</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">製造業者情報
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ producerNoteJa: e.target.value })}
                    rows={4}
                    value={producerNoteJa}
                    readOnly={!isEditable}
                  />
                  <div className="c-form-caution">製造業者が通常と異なる場合のみ、こちらに記載します。</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">その他
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ extraInfoNoteJa: e.target.value })}
                    rows={4}
                    value={extraInfoNoteJa}
                    readOnly={!isEditable}
                  />
                  <div className="c-form-caution">商品バリエーションが複数ある場合も、全てこの欄に記載します。</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">アルコール
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className='form-check-input'
                      id="containsAlchohol"
                      checked={containsAlcohol}
                      disabled={!hasLiquorSalesLicense}
                      onChange={e => this.setState({ containsAlcohol: !containsAlcohol })}
                      disabled={!isEditable}
                    />
                    <label className="form-check-label" htmlFor="containsAlchohol">
                      この商品はアルコールです
                    </label>
                  </div>
                  <div className="c-form-caution">チェックを入れることで商品ページに注意文が表示されます。</div>
                  {!hasLiquorSalesLicense && <div className="c-form-caution">
                    <a href="/owner/account/legal_note/edit" data-turbolinks="false">酒販免許を登録してください</a>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">1つあたりのおおよそのサイズ
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ sizePerUnitJa: e.target.value })}
                    rows={4}
                    placeholder="幅2cm x 高さ2cm x 奥行き2cm の立方体になっています。"
                    value={sizePerUnitJa}
                    readOnly={!isEditable}
                  />
                  <div className="c-form-caution">お客様のお手元に届く際の実際のサイズ感がわかるとより注文が入りやすくなります</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">
              ギフト対応
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  value="gift"
                  className="form-check-input"
                  id="label-gift"
                  disabled={!!giftDescriptionJa}
                  checked={giftIsChecked}
                  onChange={() => this.setState({ giftIsChecked: !giftIsChecked })}
                  disabled={!isEditable}
                />
                <label className="form-check-label" htmlFor="label-gift">
                  {I18n.t("enums.product_label.label.gift")}
                </label>
              </div>
            </div>
          </div>
          {giftIsChecked && (
            <div className="row mb-4">
              <label className="col-sm-2 col-form-label">ギフト対応の詳細</label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  onChange={e => this.setState({ giftDescriptionJa: e.target.value })}
                  rows={4}
                  value={giftDescriptionJa}
                  placeholder='のし対応可能（お中元・お歳暮）、簡易包装、ギフトラッピング可能、など'
                  readOnly={!isEditable}
                />
                <div className="c-form-caution">どのようなギフト対応が可能かを記載してください。</div>
              </div>
            </div>
          )}

        </div>

        <div className="c-section c-section-spNoPadding">
          <div className="c-section_title mb-3">注文と配送</div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">注文開始日時
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <DatePicker locale="ja" showTimeSelect selected={orderableFrom} dateFormat="Pp" className="form-control"
                onChange={date => this.setState({ orderableFrom: date }, () => this.isValidByAdmin())}
                readOnly={!isEditable}
              />
              <div className="c-form-caution">お客様が商品をカートに入れられるようになる日時です。特に設定しなければ掲載開始と同時に販売開始となります。</div>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">注文終了日時
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <DatePicker locale="ja" showTimeSelect selected={orderableTill} dateFormat="Pp" className="form-control"
                onChange={date => this.setState({ orderableTill: date }, () => this.isValidByAdmin())}
                readOnly={!isEditable}
              />
              <div className="c-form-caution">注文終了日時を設定すると在庫が余っていてもお客様が購入できなくなります。</div>
            </div>
          </div>

          {productType === "grocery" && <div className="row mb-4">
            <label className="col-sm-2 col-form-label">発送開始日
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-4">
                  <DatePicker locale="ja" selected={shippableFrom} dateFormat="P" className="form-control"
                    onChange={date => this.setState({ shippableFrom: date })}
                    readOnly={!isEditable}
                  />
                </div>
                <div className="c-form-caution">特定の季節の商品など、少し先の日程の注文を予め受けたい場合などに利用します。</div>
              </div>
            </div>
          </div>}

          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">送料・配送詳細
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ deliveryNoteJa: e.target.value })}
                    rows={4}
                    value={deliveryNoteJa}
                    readOnly={!isEditable}
                  />
                  <div className="c-form-caution">商品バリエーションが複数ある場合も、全てこの欄に記載します。</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">配送不可地域
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              {shippingMethod ? Object.keys(prefecturesAreaMap).map((area, i) => (
                <div className='input-group mb-3' key={i}>
                  <div className="input-group-text">
                    <label key={area}>
                      <input type="checkbox" className='form-check-input mt-0'
                        checked={this.isAreaCheck(area)}
                        onChange={e => {
                          const checked = e.target.checked;
                          const name_es = prefecturesAreaMap[area]['prefectures'].map(item => item['name_e']);
                          const newUnshippablePrefectures = checked ?
                            Array.from(new Set([...unshippablePrefectures, ...name_es])) :
                            unshippablePrefectures.filter(item => !name_es.includes(item));
                          this.setState({ unshippablePrefectures: newUnshippablePrefectures });
                        }}
                        disabled={!isEditable}
                      />
                      {I18n.t(`prefectures.areas.${area}`)}
                    </label>
                  </div>
                  <div>
                    {prefecturesAreaMap[area]['prefectures'].map(prefecture => (
                      shippingMethod['prefectureValues'].some((item: string) => item == prefecture['name_e']) && (
                        <div key={prefecture['code']} className='form-check form-check-inline'>
                          <label className='form-check-label'>
                            <input type="checkbox" className='form-check-input'
                              checked={unshippablePrefectures.includes(prefecture['name_e'])}
                              onChange={e => {
                                const checked = e.target.checked;
                                const newUnshippablePrefectures = checked ?
                                  [...unshippablePrefectures, prefecture['name_e']] :
                                  unshippablePrefectures.filter(item => item != prefecture['name_e']);
                                this.setState({ unshippablePrefectures: newUnshippablePrefectures });
                              }}
                              disabled={!isEditable}
                            />
                            {prefecture['name']}
                          </label>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              )) : (
                <div className='alert alert-warning'>
                  <i className="fas fa-exclamation-circle me-1"></i>
                  まずは配送方法を選択してください
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="c-section c-section-spNoPadding">
          <div className="c-section_title mb-3">その他の設定</div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label" htmlFor="product_title_ja">表示用商品名
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <input className="form-control" type="text" maxLength="255"
                value={displayTitleJa}
                placeholder="表示用商品名を入力してください"
                onChange={e => this.setState({ displayTitleJa: e.target.value })}
                readOnly={!isEditable}
              />
            </div>
          </div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">アーカイブ
              <span className='c-form_op'>任意</span>
            </label>
            <div className="col-sm-10">
              <div className="form-check">
                <input
                  type="checkbox"
                  className='form-check-input'
                  id="isArchived"
                  checked={isArchived}
                  onChange={e => this.setState({ isArchived: !isArchived })}
                  disabled={!isEditable}
                />
                <label className="form-check-label" htmlFor="isArchived">
                  アーカイブする
                </label>
              </div>
              <div>
                ※アーカイブするとお客様側の購入前の全ページから商品が非表示になります。（処理時点でカートに入っている商品は購入されることがあります）
              </div>
            </div>
          </div>
        </div>

        <input type="hidden" name="product[product_type]" value={productType} />
        <input type="hidden" name="product[title_ja]" value={titleJa} />
        <input type="hidden" name="product[display_title_ja]" value={displayTitleJa} />
        <input type="hidden" name="product[ctax_pct]" value={ctaxPct} />
        <input type="hidden" name="product[shipping_method_uid]" value={shippingMethodUid} />
        <input type="hidden" name="product[unshippable_prefectures][]" value='' />
        {unshippablePrefectures.map(pv => <input type="hidden" name="product[unshippable_prefectures][]" value={pv} key={pv} />)}

        <input type="hidden" name="product[orderable_from]" value={formatJsDate(orderableFrom)} />
        <input type="hidden" name="product[orderable_till]" value={formatJsDate(orderableTill)} />
        {productType === "fresh" && <input type="hidden" name="product[shippable_from]" value="" />}
        {productType === "grocery" && <input type="hidden" name="product[shippable_from]" value={formatJsDate(shippableFrom)} />}
        {productVariants.map((pv, index) => <div key={pv.id}>
          <input type="hidden" name={`product_variants[${pv.id}][title_ja]`} value={pv.titleJa} />
          <input type="hidden" name={`product_variants[${pv.id}][unit_price]`} value={pv.unitPrice} />
          <input type="hidden" name={`product_variants[${pv.id}][description_ja]`} value={pv.descriptionJa} />
        </div>)}
        {newProductVariants.map((pv, index) => <div key={index}>
          <input type="hidden" name={`new_product_variants[${index}][title_ja]`} value={pv.titleJa} />
          <input type="hidden" name={`new_product_variants[${index}][unit_price]`} value={pv.unitPrice} />
          <input type="hidden" name={`new_product_variants[${index}][description_ja]`} value={pv.descriptionJa} />
        </div>)}
        <input type="hidden" name="product[cutoff_days_before]" value={cutoffDaysBefore} />
        <input type="hidden" name="product[cutoff_time]" value={cutoffTime.toFormat('HH:mm:ss')} />
        <input type="hidden" name="product[max_order_to_ship_days]" value={maxOrderToShipDays} />
        {isProductPersisted && <input type="hidden" name="_method" value="patch" />}
        <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
        <input type="hidden" name="product[description_ja]" value={descriptionJa} />
        <input type="hidden" name="product[expiry_note_ja]" value={expiryNoteJa} />
        <input type="hidden" name="product[package_note_ja]" value={packageNoteJa} />
        <input type="hidden" name="product[size_per_unit_ja]" value={sizePerUnitJa} />
        <input type="hidden" name="product[storage_note_ja]" value={storageNoteJa} />
        <input type="hidden" name="product[ingredients_note_ja]" value={ingredientsNoteJa} />
        <input type="hidden" name="product[allergen_note_ja]" value={allergenNoteJa} />
        <input type="hidden" name="product[producer_note_ja]" value={producerNoteJa} />
        <input type="hidden" name="product[extra_info_note_ja]" value={extraInfoNoteJa} />
        <input type="hidden" name="product[delivery_note_ja]" value={deliveryNoteJa} />
        <input type="hidden" name="product[contains_alcohol]" value={containsAlcohol.toString()} />
        <input type="hidden" name="product[is_archived]" value={isArchived.toString()} />
        <input type="hidden" name="product_gift_label[label]" value={giftIsChecked ? 'gift' : ''} />
        {giftIsChecked && (
          <input type="hidden" name="product_gift_label[description_ja]" value={giftDescriptionJa} />
        )}

        {!!operatableDataByAdmin ? <OperableFieldsByAdmin ref={this.adminFieldsRef} validForAdminFunc={(res: boolean) => { this.validForAdminFunc(res) }} operatableDataByAdmin={operatableDataByAdmin} orderableFrom={orderableFrom} orderableTill={orderableTill} /> : null}

        <div className="mb-4">
          {/* <input type="submit" name="commit" value="商品を保存する" className="btn btn-wide btn-primary" /> */}


          {isEditable && <button
            type="submit"
            className={submitClasses}
            onClick={_e => this.setState({isLoading: true})}
          >
            {isLoading && (<span className='spinner-border spinner-border-sm mx-3'></span>)}
            <span className='my-3'>{isLoading ? '処理中…' : '商品を保存する'}</span>
          </button>}

        </div>
      </form>
    );
  };
}

function formatJsDate(date: Date|null|undefined) {
  if (date === null || date === undefined) {
    return '';
  } else {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}

export default ProductForm;

document.addEventListener("turbolinks:load", () => {
  const fixedElement = document.querySelector('.p-product_order_fixed');
  const boundaryElement = document.querySelector('.p-product_order_sec');
  if (!!fixedElement && !!boundaryElement) {
    const displayFixedElement = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fixedElement.style.display = 'none';
        } else {
          fixedElement.style.display = 'block';
        }
      });
    };
    const observer = new IntersectionObserver(displayFixedElement);
    observer.observe(boundaryElement);
  }
});

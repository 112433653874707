import * as React from 'react';
import Rails from 'rails-ujs';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import axios from 'axios';

import ImageUpdater, { RegisteredImage, UploadedImageData } from './image_updater/ImageUpdater';

type Props = {
  productUid: string
  productImages: RegisteredImage[]
  isEditable: boolean
}

const ProductImageBulkUpdate: React.FC<Props> = ({ productUid, productImages, isEditable }) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const handleClick = React.useCallback(async (uploadedImages: UploadedImageData[], deleteImageIds: number[]) => {
    setIsSubmitting(true)

    const formData = new FormData()
    let newImageIndex = 0
    uploadedImages.forEach((uploadedImage: UploadedImageData, index: number) => {
      if (uploadedImage.id) {
        formData.append(`product_image[order][][id]`, uploadedImage.id.toString())
        formData.append(`product_image[order][][sort]`, index.toString())
      }

      if (!!uploadedImage.file) {
        formData.append('product_image[images][][image]', uploadedImage.file)
        formData.append('product_image[images][][sort]', index.toString())

        if (uploadedImage.isThumb) {
          formData.append('product_image[thumbnail_index]', newImageIndex.toString())
        }
      } else if (uploadedImage.isThumb) {
        formData.append('product_image[thumbnail_image_id]', uploadedImage.id.toString())
      }

      if (!uploadedImage.id) {
        newImageIndex++
      }
    })

    deleteImageIds.forEach((id: number) => {
      formData.append('product_image[delete_image_ids][]', id.toString())
    })

    await axios.post(
      `/owner/products/${productUid}/images/bulk_update`,
      formData,
      {
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
        }
      }
    )

    window.location.href = `/owner/products/${productUid}/images`
  }, [productUid])

  return (
    <DndProvider backend={HTML5Backend}>
      <ImageUpdater
        accept=".jpg,.jpeg,.gif,.png"
        isEditable={isEditable}
        isDisabled={isSubmitting}
        registeredImages={productImages}
        onClick={handleClick}
      />
    </DndProvider>
  )
}

export default ProductImageBulkUpdate

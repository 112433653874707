import { Sortable } from "sortablejs";

document.addEventListener("turbolinks:load", () => {
  const productList = document.getElementById('productListAdmin');
  if(!productList || productList.length < 1) return;
  const productUid = document.getElementById('product_uids').value.split(' ');

  new Sortable(productList, {
    animation: 150,
    handle: '.ordered-handle',
    onUpdate: (evt) => {
      const form = document.getElementById('reordered_admin_featured_products_form');

      if (evt.newIndex > evt.oldIndex) {
        productUid.splice(evt.newIndex + 1, 0, productUid[evt.oldIndex]);
        productUid.splice(evt.oldIndex, 1);
      } else {
        productUid.splice(evt.newIndex, 0, productUid[evt.oldIndex]);
        productUid.splice(evt.oldIndex + 1, 1);
      }
      document.getElementById('product_uids').value = productUid.join(',');
    }
  });
});

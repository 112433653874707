import { Sortable } from "sortablejs";

document.addEventListener("turbolinks:load", () => {
  const adminSortableList = document.getElementById('admin-sortable-list');
  if(!adminSortableList || adminSortableList.length < 1) return;
  const sortableUniqKeys = document.getElementById('admin-sortable-uniq-keys').value.split(',');

  new Sortable(adminSortableList, {
    animation: 150,
    handle: '.ordered-handle',
    onUpdate: (evt) => {
      const form = document.getElementById('admin-reordered-form');

      if (evt.newIndex > evt.oldIndex) {
        sortableUniqKeys.splice(evt.newIndex + 1, 0, sortableUniqKeys[evt.oldIndex]);
        sortableUniqKeys.splice(evt.oldIndex, 1);
      } else {
        sortableUniqKeys.splice(evt.newIndex, 0, sortableUniqKeys[evt.oldIndex]);
        sortableUniqKeys.splice(evt.oldIndex + 1, 1);
      }
      document.getElementById('admin-sortable-uniq-keys').value = sortableUniqKeys.join(',');
    }
  });
});

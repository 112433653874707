import * as React from 'react';
import Rails from 'rails-ujs';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

import { StripeSourceCard } from './NewOrderForm';

const stripeWrapperStyle = {
  lineHeight: '1.21428571em',
  padding: '0.67857143em 1em',
  border: '1px solid rgba(34, 36, 38, 0.15)',
  borderRadius: '0.28571429rem',
};

const stripeElementStyle = {
  color: '#1a1a1a',
  '::placeholder': { color: '#ccc' },
  fontFamily: '"Hiragino Sans", "Hiragino Kaku Gothic ProN", "Roboto", "Meiryo", sans-serif',
};

type Props = {
  isUpdate: boolean
  setNewStripeSourceCard: (stripeSourceCard: StripeSourceCard) => void
  setCardUpdateSuccessMessage: (message: string) => void
  closeModal: () => void
}

type CardStatus = 'ok' | 'ng'

const StripeCardForm: React.VFC<Props> = ({ isUpdate, setNewStripeSourceCard, setCardUpdateSuccessMessage, closeModal }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>('')
  const [cardStatuses, setCardStatuses] = React.useState<{
    cardNumber: CardStatus;
    cardExpiry: CardStatus;
    cardCvc: CardStatus;
  }>({
    cardNumber: 'ng',
    cardExpiry: 'ng',
    cardCvc: 'ng'
  })
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    // 再度開いた際にメッセージを空にしておく
    setCardUpdateSuccessMessage('');
  }, [])

  React.useEffect(() =>{
    if (Object.values(cardStatuses).every((cs) => cs === 'ok') && name !== '') {
      setIsValid(true);
      return;
    }

    setIsValid(false);
  }, [cardStatuses, name])

  const handleInputName = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  }, [])

  const handleChange = React.useCallback(
    (e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent) => {

    const clonedCardStatus = { ...cardStatuses };
    if (e.error || !e.complete) {
      clonedCardStatus[e.elementType] = 'ng';
      setCardStatuses(clonedCardStatus);
      return;
    }

    clonedCardStatus[e.elementType] = 'ok';
    setCardStatuses(clonedCardStatus);
  }, [cardStatuses])

  const handleSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsSubmitting(true);

    const cardElement = elements.getElement('cardNumber');

    const result = await stripe.createSource(cardElement, {
      owner: {
        name,
      },
    });

    if (result.error) {
      setError(result.error.message);
      setIsSubmitting(false);
      return;
    } else if (result.source.card.funding=== 'debit') {
      setError('デビットカードはご利用になれません');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.put('/stripe_source', {
        stripe_source_id: result.source.id,
        brand: result.source.card.brand,
        authenticity_token: Rails.csrfToken(),
      });

      setNewStripeSourceCard({
        id: result.source.id,
        last4: result.source.card.last4,
        brand: result.source.card.brand,
        brandImagePath: response.data.brandImagePath,
      });

      setCardUpdateSuccessMessage('カード情報を更新しました');
      closeModal();
    } catch (_error) {
      setError('カード情報の更新に失敗しました');
      setIsSubmitting(false);
    }
  }, [stripe, elements, name, setCardUpdateSuccessMessage, closeModal])

  return (
    <form onSubmit={handleSubmit} className="ui form">
      {error && (
        <div className="alert alert-danger my-3">
          <p className="fw-bold">
            <i className="fas fa-exclamation-triangle" />
            エラーが発生しました
          </p>
          <ul className="list">
            <li>{error}</li>
          </ul>
        </div>
      )}
      <div className="mb-3">
        <label>カード番号<span className="c-form_rq">必須</span></label>
        <div style={stripeWrapperStyle}>
          <CardNumberElement
            options={{
              style: {
                base: stripeElementStyle
              }
            }}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>カード名義<span className="c-form_rq">必須</span></label>
        <div>
          <input type="text" className="form-control" placeholder="TARO YAMADA" onInput={handleInputName} />
        </div>
      </div>
      <div className="mb-3">
        <label>有効期限<span className="c-form_rq">必須</span></label>
        <div style={stripeWrapperStyle}>
          <CardExpiryElement
            options={{
              style: {
                base: stripeElementStyle
              }
            }}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-4">
        <label>セキュリティコード(CVC)<span className="c-form_rq">必須</span></label>
        <div style={stripeWrapperStyle}>
          <CardCvcElement
            options={{
              style: {
                base: stripeElementStyle
              }
            }}
            onChange={handleChange}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={!isValid || isSubmitting}>
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm mx-3" />
        ) : (
          <span className="fw-bold">{isUpdate ? 'クレジットカードを更新' : 'クレジットカードを登録'}</span>
        )}
      </button>
    </form>
  )
};

export default StripeCardForm;
